import * as React from "react";
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";
import { Link, useNavigate } from "react-router-dom";
import { NAVIGATION } from "../../lib/navigation";
import { register, setLoginData } from "../../store/slices/api/auth/loginSlice";
import useApiRequest from "../../store/slices/hook/useApiRequest";
import { MdClose } from "react-icons/md";

export default function SignUpPage() {
  const navigate = useNavigate();

  const afterAPICall = (response) => {
    navigate(`${NAVIGATION.AUTH_NAVIGATION.VERIFY_EMAIL}?email=${response?.email}`)
  }

  const { isLoading, control, handleSubmit } = useApiRequest({
    apiAction: register,
    setValueAction: setLoginData,
    afterAPICall,
  });

  return (
    <div className="w-full pt-32">
      <div className='w-full max-w-md mb-16 mx-auto'>
        <div className='w-full px-6'>
          <div className="flex items-center justify-between">
            <Link to={NAVIGATION.HOME}>
              <img src="/assets/logo.svg" className="h-9" alt="Roof Reveal Logo" />
            </Link>

            <Link to={NAVIGATION.HOME}>
              <div className="h-10 w-10 flex items-center justify-center border rounded">
              <MdClose className={`w-4 h-4`} />
              </div>
            </Link>
          </div>
          <p className="mt-6 text-lg font-semibold">Create a new account</p>
          <form onSubmit={handleSubmit}>
            <div className='flex gap-6 flex-col md:flex-row'>
              <Input
                control={control}
                name="firstName"
                rules={{
                  required: "this field is required"
                }}
                label="First Name"
              />
              <Input
                control={control}
                name="lastName"
                rules={{
                  required: "this field is required"
                }}
                label="Last Name"
              />
            </div>
            <Input
              control={control}
              name="email"
              rules={{
                required: "this field is required"
              }}
              type="email"
              label="Email"
            />
            <Input
              control={control}
              name="password"
              rules={{
                required: "This field is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters long"
                },
                pattern: {
                  value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  message: "Password must include at least one letter, one number, and one special character"
                }
              }}
              type="password"
              label="Password"
            />
            <div className="my-6">
              <Button isLoading={isLoading} className="w-full">
                Get started
              </Button>
            </div>
            <p className='text-center block'>Have an account?</p>
            <Link to={NAVIGATION.AUTH_NAVIGATION.LOGIN} className='text-primary text-center block'>Sign in</Link>

          </form>
        </div>
      </div>
    </div>
  );
}

