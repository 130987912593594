import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null,
}

export const selectedPlanSlice = createSlice({
  name: 'selectedPlan',
  initialState,
  reducers: {
    setSelectedPlan: (state, action) => {
      state.value = action.payload
    },
    clearSelectedPlan: (state) => {
      state.value = null
    },
  },
})

export const { setSelectedPlan, clearSelectedPlan } = selectedPlanSlice.actions

export default selectedPlanSlice.reducer