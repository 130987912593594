import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
  data: null,
  place: null,
  solar: [],
  mapLoading: false,
  mapSeg: null,
};

export const autoComplete = createAsyncThunk(
  'user/autoComplete',
  async (formData) => {
    try {
      const response = await myAxios.post(`user/autocomplete`, formData);
      return { success: true, message: "Location fetched successfully!", data: response?.data?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong!' };
      }
    }
  },
);

export const fetchPlaceData = createAsyncThunk(
  'user/place-details',
  async (formData) => {
    try {
      const response = await myAxios.post(`user/place-details`, formData);
      return { success: true, message: "Place data fetched successfully!", data: response?.data?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong!' };
      }
    }
  },
);

export const fetchMapSeg = createAsyncThunk(
  'user/map-seg',
  async (formData) => {
    try {
      const response = await myAxios.post(`user/mapSeg`, formData);
      return { success: true, message: "Place data fetched successfully!", data: response?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong!' };
      }
    }
  },
);


export const fetchSolarData = createAsyncThunk(
  'user/solar-data',
  async (formData) => {
    try {
      const response = await myAxios.post(`user/solarapi`, formData);
      return { success: true, message: "Solar data fetched successfully!", data: response?.data?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong!' };
      }
    }
  },
);


const autoCompleteSlice = createSlice({
  name: 'autoComplete',
  initialState,
  reducers: {
    clearAutoCompleteData: state => {
      state.data = null;
    },
    setAutoCompleteData: (state, action) => {
      state.data = action.payload;
    },
    clearPlaceData: state => {
      state.place = null;
    },
    setPlaceData: (state, action) => {
      state.place = action.payload;
    },

    clearMapSeg: state => {
      state.mapSeg = null;
    },
    setMapSeg: (state, action) => {
      state.mapSeg = action.payload;
    },
    clearSolarData: state => {
      state.solar = [];
    },
    setSolarData: (state, action) => {
      state.solar = action.payload;
    },

    setMapLoading: (state, action) => {
      state.mapLoading = action.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchSolarData.pending, (state) => {
        state.mapLoading = true;
      })
      .addCase(fetchSolarData.fulfilled, (state, action) => {
        if (!action?.payload?.data) {
          state.mapLoading = false;
        } else if (!action?.payload?.data?.[0]?.solarData?.maskUrl) {
          state.mapLoading = false;
        }
      })
      .addCase(fetchSolarData.rejected, (state, action) => {
        state.mapLoading = false;
      })

      
      .addCase(fetchMapSeg.rejected, (state, action) => {
        state.mapSeg = null;
      });
  },
});

export const autoCompleteReducer = autoCompleteSlice.reducer;
export const { clearAutoCompleteData, setAutoCompleteData, clearPlaceData, setPlaceData, setSolarData, clearSolarData, setMapLoading, clearMapSeg, setMapSeg } = autoCompleteSlice.actions;

export const selectAutoCompletedData = (state) => state.autoComplete.data;
export const selectPlaceData = (state) => state.autoComplete.place;
export const selectSolarData = (state) => state.autoComplete.solar;
export const selectMapSegData = (state) => state.autoComplete.mapSeg;
