import React from 'react'
import { useSelector } from 'react-redux'
import LoginForm from '../../auth/LoginForm'
import ModalHOC from '../../inner/hoc/ModalHOC'

const LoginModal = ({ toggleModal }) => {
    const loginModal = useSelector(state => state.toggle.loginModal)

    return (
        <ModalHOC open={loginModal} toggleModal={toggleModal} maxWidth='max-w-sm'>
            <LoginForm />
        </ModalHOC>
    )
}

export default LoginModal