import React from 'react'
import ProfileHOC from '../../../components/pages/inner/hoc/ProfileHOC'
import Input from '../../../components/common/Input'
import Button from '../../../components/common/Button'
import useApiRequest from '../../../store/slices/hook/useApiRequest'
import { changePassword, clearLoginData } from '../../../store/slices/api/auth/loginSlice'
import { useNavigate } from 'react-router-dom'
import { NAVIGATION } from '../../../lib/navigation'

const ChangePassword = () => {
    const navigate = useNavigate();

    const afterAPICall = (response) => {
        navigate(NAVIGATION.AUTH_NAVIGATION.LOGIN)
    }

    const { isLoading, control, handleSubmit, watch } = useApiRequest({
        apiAction: changePassword,
        setValueAction: clearLoginData,
        afterAPICall,
    });


    return (
        <ProfileHOC title="Change Password">
            <div className="w-full md:w-4/5">
                <form onSubmit={handleSubmit}>
                    <Input
                        control={control}
                        name="oldPassword"
                        rules={{
                            required: "this field is required"
                        }}
                        type="password"
                        label="Current Password"
                    />
                    <Input
                        control={control}
                        name="password"
                        rules={{
                            required: "This field is required",
                            minLength: {
                                value: 8,
                                message: "Password must be at least 8 characters long"
                            },
                            pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                message: "Password must include at least one letter, one number, and one special character"
                            }
                        }}
                        type="password"
                        label="New Password"
                    />
                    <Input
                        control={control}
                        name="confirmPassword"
                        rules={{
                            required: "This field is required",
                            validate: (value) => {
                                if (watch('password') !== value) {
                                    return "Passwords do not match"
                                }
                            },
                        }}
                        type="password"
                        label="Confirm Password"
                    />

                    <div className="flex justify-end my-6">
                        <Button isLoading={isLoading}>
                            Change Password
                        </Button>
                    </div>
                </form>
            </div>
        </ProfileHOC>
    )
}

export default ChangePassword