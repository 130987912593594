import * as React from "react";

const StatItem = ({ title, value, description }) => (
    <div className="flex flex-col w-1/3 max-md:ml-0 max-md:w-full">
        <div className="font-semibold text-white max-md:mt-10">
            <div className="text-base uppercase">{title}</div>
            <div className="shrink-0 mt-3 h-px bg-white" />
            <div className="mt-12 text-5xl leading-[50px] max-md:mt-10">{value}</div>
            <div className="mt-10 uppercase">{description}</div>
        </div>
    </div>
);

const stats = [
    {
        title: "STAT. 1",
        value: "100+",
        description: "Customer Served"
    },
    {
        title: "STAT. 2",
        value: "150+",
        description: "Product launched"
    },
    {
        title: "STAT. 3",
        value: "50+",
        description: "Regulatory Submissions"
    }
];

export default function StatItemSection() {
    return (<section className="pt-24">
        <img
            loading="lazy"
            src="/assets/bg/section-img-1.png"
            className="w-full aspect-[2.13]"
            alt="Counter Image"
        />
        <div className="flex justify-center items-center md:px-6  py-12 bg-primary px-5">
            <div className="justify-between mt-10 w-full max-w-[1600px] max-md:max-w-full">
                <div className="flex gap-20 max-md:flex-col max-md:gap-0">
                    {stats.map((stat, index) => (
                        <StatItem
                            key={index}
                            title={stat.title}
                            value={stat.value}
                            description={stat.description}
                        />
                    ))}
                </div>
            </div>
        </div>
    </section>

    );
}