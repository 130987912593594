import React from 'react'
import ProfileHOC from '../../../components/pages/inner/hoc/ProfileHOC'
import Input from '../../../components/common/Input';
import Button from '../../../components/common/Button';
import { addBillingInfo, selectLoggedInData, setLoginData } from '../../../store/slices/api/auth/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import useApiRequest from '../../../store/slices/hook/useApiRequest';

const BillingInformation = () => {
    
    const loggedInUser = useSelector(selectLoggedInData);
    const dispatch = useDispatch();

    const defaultValues = {
        email: loggedInUser?.billingInfo?.email ?? "",
        phone: loggedInUser?.billingInfo?.phone ?? "",
        fullName: loggedInUser?.billingInfo?.fullName ?? "",
        firstName: loggedInUser?.billingInfo?.firstName ?? "",
        lastName: loggedInUser?.billingInfo?.lastName ?? "",
        userName: loggedInUser?.billingInfo?.userName ?? "",
        countryCode: loggedInUser?.billingInfo?.countryCode ?? "",
        address: loggedInUser?.billingInfo?.address ?? "",
        zipCode: loggedInUser?.billingInfo?.zipCode ?? "",
        city: loggedInUser?.billingInfo?.city ?? "",
        state: loggedInUser?.billingInfo?.state ?? "",
        country: loggedInUser?.billingInfo?.country ?? "",
    }

    const afterAPICall = (response) => {
        dispatch(setLoginData({
            ...loggedInUser,
            billingInfo: {
                ...response
            }
        }))
    }

    const { isLoading, control, handleSubmit } = useApiRequest({
        apiAction: addBillingInfo,
        defaultValues,
        clearForm: false,
        afterAPICall,
    });

    return (
        <ProfileHOC title="Billing Information">
              <div className="w-full md:w-4/5">
                <form onSubmit={handleSubmit}>
                    <div className='flex gap-0 md:gap-6 flex-col md:flex-row'>
                        <Input
                            control={control}
                            name="firstName"
                            rules={{
                                required: "this field is required"
                            }}
                            label="First Name"
                        />
                        <Input
                            control={control}
                            name="lastName"
                            rules={{
                                required: "this field is required"
                            }}
                            label="Last Name"
                        />
                    </div>
                    <Input
                        control={control}
                        name="email"
                        rules={{
                            required: "this field is required"
                        }}
                        label="Email"
                    />
                    <Input
                        control={control}
                        name="phone"
                        rules={{
                            required: "this field is required"
                        }}
                        label="Phone"
                    />
                    <Input
                        control={control}
                        name="address"
                        rules={{
                            required: "this field is required"
                        }}
                        label="Address"
                    />
                    <div className='flex gap-0 md:gap-6 flex-col md:flex-row'>
                        <Input
                            control={control}
                            name="city"
                            rules={{
                                required: "this field is required"
                            }}
                            label="City"
                        />
                        <Input
                            control={control}
                            name="state"
                            rules={{
                                required: "this field is required"
                            }}
                            label="State"
                        />
                    </div>

                    <div className='flex gap-0 md:gap-6 flex-col md:flex-row'>
                        <Input
                            control={control}
                            name="zipCode"
                            rules={{
                                required: "this field is required"
                            }}
                            label="Zip Code"
                        />
                        <Input
                            control={control}
                            name="country"
                            rules={{
                                required: "this field is required"
                            }}
                            label="Country"
                        />
                    </div>

                    <div className="flex justify-end my-6">
                        <Button isLoading={isLoading}>
                            Save changes
                        </Button>
                    </div>
                </form>
            </div>
        </ProfileHOC>
    )
}

export default BillingInformation