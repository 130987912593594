import React from 'react'
import { MdOutlineChevronLeft } from "react-icons/md";

const SlideOverHOC = ({
    title,
    toggleSlideOver,
    open,
    children
}) => {
    return (
        <div className={`relative ease-in-out ${open ? 'z-[9999]' : 'z-0 duration-[1500ms]'}`} role="dialog" aria-modal="true">
            
            <div className={`fixed inset-0 bg-black bg-opacity-60 transition-opacity ease-in-out duration-500 ${open ? 'opacity-100' : 'opacity-0'}`} onClick={toggleSlideOver}></div>

            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className={`pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10`}>

                        <div className={`pointer-events-auto relative w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700   ${open ? 'translate-x-0' : '-translate-x-full'}`}>

                            <div className={`absolute right-0 bottom-12 -mr-8 flex pr-2 pt-4 sm:-mr-10 sm:pr-4 ease-in-out duration-500  ${open ? "opacity-100" : "opacity-0"}`}>
                                <button type="button" className="h-12 w-12 z-30 rounded-full bg-primary text-white text-xl flex items-center justify-center" onClick={toggleSlideOver}>
                                    <span className="absolute -inset-2.5"></span>
                                    <span className="sr-only">Close panel</span>
                                    <MdOutlineChevronLeft />
                                </button>
                            </div>

                            <div className="flex h-full flex-col bg-white py-6 shadow-xl">
                                {title && <div className="px-4 sm:px-6">
                                    <h2 className="leading-6 text-xl" dangerouslySetInnerHTML={{ __html: title }} />
                                </div>}
                                <div className="relative mt-6 flex-1 px-4 sm:px-6 overflow-y-scroll">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideOverHOC
