import React, { useRef, useState } from "react";
import MainHOC from "../../components/pages/public/hoc/MainHOC";
import DescSection from "../../components/common/public/DescSection";
import StatItemSection from "../../components/pages/public/StatItemSection";


export default function AboutPage() {
  const [activeTab, setActiveTab] = useState("overview");
  const overviewRef = useRef(null);
  const historyRef = useRef(null);
  const missionRef = useRef(null);
  const visionRef = useRef(null);
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleTabClick = (tabName, ref) => {
    setActiveTab(tabName);
    scrollToRef(ref);
  };
  return (
    <MainHOC>
      <div className="h-screen px-6 md:px-20  w-full bg-cover bg-center flex flex-col justify-center" style={{
        backgroundImage: "url(/assets/bg/about.png)",
      }}>
        <h1 className="text-white font-semibold text-5xl">About Us</h1>
      </div>

      <div className="flex justify-center items-center py-10 w-full text-sm font-semibold uppercase bg-white border-b border-solid border-zinc-200 text-gray-600 max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col md:flex-row gap-5 justify-center items-center">
          <div className={`cursor-pointer ${activeTab === "overview" ? "text-primary" : ""}`}
            onClick={() => handleTabClick("overview", overviewRef)}>01. Overview</div>
          <div className={`cursor-pointer ${activeTab === "history" ? "text-primary" : ""}`}
            onClick={() => handleTabClick("history", historyRef)}>02. History</div>
          <div className={`cursor-pointer ${activeTab === "mission" ? "text-primary" : ""}`}
            onClick={() => handleTabClick("mission", missionRef)}>03. Mission</div>
          <div className={`cursor-pointer ${activeTab === "vision" ? "text-primary" : ""}`}
            onClick={() => handleTabClick("vision", visionRef)}
          >04. Vission</div>
        </div>
      </div>

      <section ref={overviewRef}>
        <DescSection title="Overview" desc="What is Roof Reveal?" >
          <p>
            Roof Reveal is a cutting-edge SaaS platform that transforms the roofing industry by providing a comprehensive set of tools tailored to the needs of roofing professionals. Powered by advanced AI technology, Roof Reveal offers precise roof measurements, automated estimates, streamlined material ordering, and detailed Xactimate reports.
          </p><br />
          <p>With Roof Reveal, roofing professionals can streamline their operations, enhance accuracy, and achieve unparalleled success in every project.</p>
        </DescSection>
      </section>
      <StatItemSection />
      <section ref={historyRef}>
        <DescSection title="History" desc="Our Story"  paddingBottom="pb-24">
          <p>
            Roof Reveal was born from our deep-rooted passion for the roofing industry and a desire to address the challenges faced by professionals in the field. Drawing upon years of hands-on experience and a comprehensive understanding of the intricacies of roofing projects, we recognized the need for a transformative solution that would streamline operations, enhance accuracy, and empower success. Our journey began with a commitment to innovation and a vision to revolutionize the way roofing professionals work, empowering them with the tools they need to thrive in today's competitive landscape.
          </p><br />
          <p>At Roof Reveal, we built our platform with one goal in mind: to empower roofing professionals to achieve their full potential. We understand the demands of the industry and the importance of efficiency, accuracy, and transparency in every project. With Roof Reveal, we set out to create a comprehensive solution that would not only streamline operations but also elevate the standard of excellence in the roofing industry. From precise measurements to automated estimates and seamless material ordering, Roof Reveal is designed to revolutionize every aspect of the roofing process, empowering professionals to deliver exceptional results with confidence and ease.</p>
        </DescSection>
      </section>

      <section ref={missionRef}>
        <DescSection title="Mission" desc="Building a Better Future" gray={true} paddingBottom="pb-24">
          <p>
            At Roof Reveal, our mission is twofold: to revolutionize the roofing experience and to extend our problem-solving capabilities to industries beyond. We are dedicated to providing tailored solutions that streamline processes and foster efficiency for professionals in roofing and beyond.
          </p><br />
          <p>
            By leveraging cutting-edge technology and a community-driven approach, Roof Reveal seeks to empower individuals across different domains, offering tools that transcend boundaries and redefine how problems are approached and solved. Join us as we pave the way for innovation, collaboration, and problem-solving across industries, one reveal at a time.
          </p>
        </DescSection>
      </section>

      <section ref={visionRef}>
        <DescSection title="Vision" desc="Our Vision: Roadmap to Success" >
          <p>
            Our vision at Roof Reveal is to redefine the roofing industry through innovation, empowerment, and excellence. We envision a future where roofing professionals have access to cutting-edge technology that streamlines operations, enhances accuracy, and drives success in every project. By leveraging advanced AI technology and industry expertise, we aim to empower professionals to achieve new heights of efficiency, transparency, and profitability. Our vision is to create a community where collaboration thrives, knowledge is shared, and success is celebrated, setting a new standard of excellence for the roofing industry. With Roof Reveal, our vision is to transform challenges into opportunities, obstacles into milestones, and dreams into reality for roofing professionals worldwide.
          </p>
        </DescSection>
      </section>
    </MainHOC>
  );
}

