import React from "react";
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import useApiRequest from "../../store/slices/hook/useApiRequest";
import { resetPassword } from "../../store/slices/api/auth/passwordSlice";
import { NAVIGATION } from "../../lib/navigation";
import { MdClose } from "react-icons/md";

export default function ResetPassword() {
    const params = useParams();
    const navigate = useNavigate();

    const beforeAPICall = (data) => {
        return {
            success: true,
            refactoredData: {
                userId: params.userId,
                password: data.password
            }
        }
    }

    const afterAPICall = (response) => {
        navigate(NAVIGATION.AUTH_NAVIGATION.LOGIN)
    }

    const { isLoading, control, watch, handleSubmit } = useApiRequest({
        apiAction: resetPassword,
        beforeAPICall,
        afterAPICall
    });

    return (
        <div className="w-full pt-32">
            <div className='w-full max-w-md mb-16 mx-auto'>
                <div className='w-full px-6'>
                    <div className="flex items-center justify-between">
                        <Link to={NAVIGATION.HOME}>
                            <img src="/assets/logo.svg" className="h-9" alt="Roof Reveal Logo" />
                        </Link>

                        <Link to={NAVIGATION.HOME}>
                            <div className="h-10 w-10 flex items-center justify-center border rounded">
                                <MdClose className={`w-4 h-4`} />
                            </div>
                        </Link>
                    </div>
                    <p className="mt-6 text-lg font-semibold">Reset your password</p>
                    <p className="text-sm text-gray-400 mt-3">
                        please choose a new password. Make sure it's at least 8 characters long and includes a combination of letters, numbers, and special characters.
                    </p>
                    <form onSubmit={handleSubmit}>

                        <Input
                            control={control}
                            name="password"
                            rules={{
                                required: "This field is required",
                                minLength: {
                                    value: 8,
                                    message: "Password must be at least 8 characters long"
                                },
                                pattern: {
                                    value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                    message: "Password must include at least one letter, one number, and one special character"
                                }
                            }}
                            type="password"
                            label="New Password"
                        />
                        <Input
                            control={control}
                            name="confirmPassword"
                            rules={{
                                required: "This field is required",
                                validate: (value) => {
                                    if (watch('password') !== value) {
                                        return "Passwords do not match"
                                    }
                                },
                            }}
                            type="password"
                            label="Confirm Password"
                        />

                        <div className="my-6">
                            <Button isLoading={isLoading} className="w-full">
                                Continue
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
