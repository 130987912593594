import React from 'react'
import ProfileHOC from '../../../components/pages/inner/hoc/ProfileHOC'
import Input from '../../../components/common/Input'
import Button from '../../../components/common/Button'
import { useSelector } from 'react-redux'
import { profileUpdate, selectLoggedInData, setLoginData, } from '../../../store/slices/api/auth/loginSlice'
import useApiRequest from '../../../store/slices/hook/useApiRequest'

const EditProfile = () => {
    const loggedInUser = useSelector(selectLoggedInData);
    
    const defaultValues = {
        firstName: loggedInUser?.firstName,
        lastName: loggedInUser?.lastName,
        email: loggedInUser?.email,
    }

    const { isLoading, control, handleSubmit } = useApiRequest({
        apiAction: profileUpdate,
        setValueAction: setLoginData,
        defaultValues,
        clearForm: false,
    });

    return (
        <ProfileHOC title="Edit Profile">
            <div className="w-full md:w-4/5">
                <form onSubmit={handleSubmit}>
                    <div className='flex gap-0 md:gap-6 flex-col md:flex-row'>
                        <Input
                            control={control}
                            name="firstName"
                            rules={{
                                required: "this field is required"
                            }}
                            label="First Name"
                        />
                        <Input
                            control={control}
                            name="lastName"
                            rules={{
                                required: "this field is required"
                            }}
                            label="Last Name"
                        />
                    </div>
                    <Input
                        control={control}
                        name="email"
                        rules={{
                            required: "this field is required"
                        }}
                        label="Email"
                    />
                    <div className="flex justify-end my-6">
                        <Button isLoading={isLoading}>
                            Save changes
                        </Button>
                    </div>
                </form>
            </div>
        </ProfileHOC>
    )
}

export default EditProfile