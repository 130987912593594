import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useState } from 'react';

const useApiRequest = ({
  apiAction,
  setValueAction,
  clearForm = true,
  defaultValues,
  afterAPICall,
  beforeAPICall,
  isForm = true,
  setCustomLoadingOnError,
  successMessage = true,
  errorMessage = true,
  nullOnError = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { control, handleSubmit, reset, watch, setValue } = useForm({ defaultValues: defaultValues || {} });
  const dispatch = useDispatch();

  const submitHandler = async (data) => {
    setIsLoading(true);
    try {
      const before = beforeAPICall ? beforeAPICall(data) : { success: true };
      if (before.success) {
        const result = await dispatch(apiAction(before.refactoredData ? before.refactoredData : data ? data : null));
        if (result?.payload?.success) {
          successMessage && toast.success(result?.payload?.message);
          setValueAction && (await dispatch(setValueAction(result.payload.data)));
          if(clearForm) reset()
          if(afterAPICall) afterAPICall(result.payload.data);
        } else {
          setCustomLoadingOnError && dispatch(setCustomLoadingOnError(false))
          errorMessage && toast.error(result?.payload?.message);
          setError(result?.payload?.message)
          nullOnError && await dispatch(setValueAction(null))
        }
      } else {
        setCustomLoadingOnError && dispatch(setCustomLoadingOnError(false))
        errorMessage && toast.error(before.message);
        setError(before.message)
        nullOnError && await dispatch(setValueAction(null))
      }
    } catch (error) {
      setCustomLoadingOnError && dispatch(setCustomLoadingOnError(false))
      nullOnError && await dispatch(setValueAction(null))
      console.error('API Request Error:', error);
      toast.error('An error occurred while making the API request.');
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, control, handleSubmit: isForm ? handleSubmit(submitHandler) : submitHandler, watch, error, setValue };
};

export default useApiRequest;
