import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { NAVIGATION } from '../../../lib/navigation';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { login, setLoginData } from '../../../store/slices/api/auth/loginSlice';
import Input from '../../common/Input';
import Button from '../../common/Button';
import { MdClose } from 'react-icons/md';

const LoginForm = () => {
    const navigate = useNavigate();

    const afterAPICall = (response) => {
        navigate(NAVIGATION.INNER_NAVIGATION.DASHBOARD)
    }

    const { isLoading, control, handleSubmit } = useApiRequest({
        apiAction: login,
        setValueAction: setLoginData,
        afterAPICall,
    });
    return (
        <div className='w-full'>
            <div className='flex items-center justify-between'>
                <Link to={NAVIGATION.HOME}>
                    <img src="/assets/logo.svg" className="h-9" alt="Roof Reveal Logo" />
                </Link>

                <Link to={NAVIGATION.HOME}>
                    <div className="h-10 w-10 flex items-center justify-center border rounded">
                        <MdClose className={`w-4 h-4`} />
                    </div>
                </Link>
            </div>
            <p className="mt-6 text-lg font-semibold">Sign in to your account</p>
            <form onSubmit={handleSubmit}>
                <Input
                    control={control}
                    name="email"
                    rules={{
                        required: "this field is required"
                    }}
                    type="email"
                    label="Email"
                />
                <Input
                    control={control}
                    name="password"
                    rules={{
                        required: "this field is required"
                    }}
                    type="password"
                    label="Password"
                />
                <Link to={NAVIGATION.AUTH_NAVIGATION.FORGET_PASSWORD} className='text-primary mt-6 block'>Forget your password?</Link>
                <Button isLoading={isLoading} className="w-full my-6">
                    Sign in
                </Button>
                <p className='text-center block'>Don’t have an account?</p>
                <Link to={NAVIGATION.AUTH_NAVIGATION.SIGNUP} className='text-primary text-center block'>Sign up</Link>
            </form>
        </div>
    )
}

export default LoginForm