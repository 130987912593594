import React from 'react'
import Button from '../Button'
import { NAVIGATION } from '../../../lib/navigation'
import { MdOutlineEmail } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const SupportBanner = () => {
    const navigate = useNavigate();

    return (
        <div className='my-10 w-full bg-[#1A1A1B] p-10 hidden md:flex md:items-center md:justify-between rounded-2xl'>
            <div>
                <h1 className='text-white text-3xl font-semibold'>Support center</h1>
                <p className='text-gray-300 mt-3'>Can't find the answer? We are here to help you all the time.</p>

                <div className='mt-8 flex items-center gap-6'>
                    <Button type='button' onClick={() => navigate(NAVIGATION.INNER_NAVIGATION.HELP_CENTER.REQUEST)}>
                        Contact Us
                    </Button>
                    <p className='text-white flex items-center gap-1'>
                        <MdOutlineEmail />
                        help@roofrevel.com
                    </p>
                </div>
            </div>
            <img src='/assets/support.svg' className='h-32 w-32' />
        </div>
    )
}

export default SupportBanner