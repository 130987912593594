import React from 'react'
import HeaderSection from '../../../common/public/HeaderSection'
import FooterSection from '../../../common/public/FooterSection'

const MainHOC = ({
    home = false,
    footerClasses,
    children
}) => {
    return (
        <main>
            <HeaderSection home={home} />
            <div className={`${!home && 'md:pt-26 pt-14'}`}>
                {children}
            </div>
            <FooterSection footerClasses={footerClasses} />
        </main>
    )
}

export default MainHOC