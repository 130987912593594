const generateWasteTable = (totalAreaInFeet) => {
    const percentages = [0, 5, 8, 10, 12, 15, 20, 25, 30];
    
    const areas = percentages.map(perc => ((totalAreaInFeet / 100 * perc) + totalAreaInFeet).toFixed(2));
    
    const squares = areas.map(area => (area / 100).toFixed(2));
    
    return {
        percentages,
        areas,
        squares
    };
};

export default generateWasteTable