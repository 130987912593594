import React from 'react'
import Button from '../Button'
import { MdOutlineArrowBackIos, MdOutlineFileDownload, MdOutlineFileUpload } from 'react-icons/md'
import { IoSaveOutline } from 'react-icons/io5'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectSolarData } from '../../../store/slices/api/misc/autoCompleteSlice'
import { toggleWhiteStrap } from '../../../store/slices/common/toggleSlice'

const MapTopBar = (
  {
    title,
    toggleModal,
    showUpgrade = true,
    handleDownload,
    handlePrint,
    head,
    designLink,
    reportLink,
    handleSave,
    isLoading = false,
    isDownloadLoading = false,
  }
) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const selectedAreaBuildings = useSelector(selectSolarData);
  const whiteStrap = useSelector(state => state.toggle.whiteStrap);
  const dispatch = useDispatch();

  const handleDesignNavigate = () => {
    dispatch(toggleWhiteStrap(false))
    navigate(designLink)
  }
  const handleReportNavigate = () => {
    dispatch(toggleWhiteStrap(true))
    navigate(reportLink, { state: { items: selectedAreaBuildings, reportLink, designLink }, })
  }

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <div className='relative min-h-12 z-[999]'>
      <div className='bg-zinc-900 text-white'>
        <div className="mx-auto max-w-screen-xl h-12 px-3 flex items-center justify-between">
          <div className="flex items-center justify-center">
            <button className='p-4' onClick={handleBack}>
              <MdOutlineArrowBackIos className="text-lg text-white" />
            </button>
            <span onClick={handleBack} className="cursor-pointer uppercase text-sm">{head ?? ''}</span>
            {/* <span onClick={handleDesignNavigate} className="cursor-pointer uppercase text-sm">{head ?? ''}</span> */}
          </div>
          {/* <div className='flex gap-2'>
            <div onClick={handleDesignNavigate} className={`flex gap-1 items-center cursor-pointer py-3 px-4 uppercase font-semibold ${pathname === designLink && ' border-b-2 border-b-primary'}`}><MdOutlineColorLens className="text-white text-lg" /> <span className="text-white text-sm">Design</span></div>
            <div onClick={handleReportNavigate} className={`flex gap-1 items-center cursor-pointer py-3 px-4 uppercase font-semibold ${pathname === reportLink && ' border-b-2 border-b-primary'}`}><MdOutlineArticle className="text-white text-lg" /> <span className="text-white text-sm">Report</span></div>
          </div> */}
          <div className='flex items-center gap-3'>

            {handleSave && <Button isLoading={isLoading} size="small" onClick={handleSave} type='button'>
              <IoSaveOutline className="text-lg" />
              <span>Save</span>
            </Button>}
            {handleDownload && <Button onClick={handleDownload ? handleDownload : () => { }} variant="outlineLight" isLoading={isDownloadLoading} type="button" size="small">
              <MdOutlineFileDownload className="text-lg" />
              <span>Download Report</span>
            </Button>}
          </div>
        </div>
      </div>

      {whiteStrap && <div className='min-h-12 bg-white py-4'>
        <div className="mx-auto max-w-screen-xl px-3 flex items-center justify-between">
          <span className="uppercase text-sm">{title}</span>
          <div className="flex items-center gap-3">
            {showUpgrade && <p className="uppercase text-sm">Up Grade Canvasing to Explorer Report <span className='text-green-600'>20 credits</span></p>}
            {showUpgrade && <Button variant="outlinePrimary" type="button" size="small" onClick={toggleModal}>
              <MdOutlineFileUpload className="text-lg" />
              <span>Up Grade Now</span>
            </Button>}
            {/* <Button onClick={handleDownload ? handleDownload : () => { }} variant="outline" type="button" size="small">
              <MdOutlineFileDownload className="text-lg" />
              <span>Download Report</span>
            </Button> */}
            {/* <Button onClick={handlePrint ? handlePrint : () => { }} variant="outline" type="button" size="small">
              <IoPrintOutline className="text-lg" />
              <span>Print Report</span>
            </Button> */}
          </div>
        </div>
      </div>}
    </div>
  )
}

export default MapTopBar