import React from 'react'

const Section = ({
    children,
    gray = false,
    paddingBottom = "pb-0"
}) => {
  return (
    <section className={`${gray ? 'bg-secondary' : 'bg-white'} ${paddingBottom} flex flex-col md:flex-row w-full pt-24 px-6 md:px-20 `}>
            {children}
    </section>
  )
}

export default Section