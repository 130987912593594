import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
  data: null,

};

export const login = createAsyncThunk(
  'user/login',
  async (formData) => {
    try {
      const response = await myAxios.post(`user/login`, formData);

      const authToken = await response?.data?.data?.token;
      const isEmailVerify = await response?.data?.data?.isEmailVerify;
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('isEmailVerify', isEmailVerify);
      return { success: true, message: "logged in successfully!", data: response?.data?.data };
    } catch (error) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('isEmailVerify');
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong!' };
      }
    }
  },
);

export const register = createAsyncThunk(
  'user/register',
  async (formData) => {
    try {
      const response = await myAxios.post('user/register', formData);
      const authToken = await response?.data?.data?.token;
      const isEmailVerify = await response?.data?.data?.isEmailVerify;
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('isEmailVerify', isEmailVerify);
      return { success: true, message: "Registered successfully!", data: response?.data?.data };
    } catch (error) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('isEmailVerify');
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong!' };
      }
    }
  },
);

export const profile = createAsyncThunk(
  'user/profile',
  async () => {
    try {
      const response = await myAxios.get(`user/profile`);
      return { success: true, message: "Profile data fetched successfully!", data: response?.data?.data };
    } catch (error) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('isEmailVerify');
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong during getting profile data!' };
      }
    }
  },
);

export const profileUpdate = createAsyncThunk(
  'user/updateProfile',
  async (formData) => {
    try {
      const response = await myAxios.post("user/profile", {
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        email: formData?.email.toLowerCase(),
      });
      return { success: true, message: "Profile data updated successfully!", data: response?.data?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong during update profile data!' };
      }
    }
  },
);

export const addContactInfo = createAsyncThunk(
  'user/contactInfo',
  async (formData) => {
    try {
      const response = await myAxios.post("user/contactInfo", {
        email: formData?.email ? formData?.email.toLowerCase() : "",
        phone: formData?.phone ? formData?.phone : "",
        fullName: formData?.lastName ? `${formData?.fullName} ${formData?.lastName}` : " ",
        firstName: formData?.firstName ? formData?.firstName : "",
        lastName: formData?.lastName ? formData?.lastName : "",
        userName: formData?.email ? formData?.email : "",
        countryCode: formData?.countryCode ? formData?.countryCode : "+91",
        address: formData?.address ? formData?.address : "",
        zipCode: formData?.zipCode ? formData?.zipCode : "",
        city: formData?.city ? formData?.city : "",
        company: formData?.company ? formData?.company : "",
        state: formData?.state ? formData?.state : "",
        country: formData?.country ? formData?.country : ""
      });
      return { success: true, message: "Contact information updated successfully!", data: response?.data?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong during update profile data!' };
      }
    }
  },
);

export const addBillingInfo = createAsyncThunk(
  'user/billingInfo',
  async (formData) => {
    try {
      const response = await myAxios.post("user/billingInfo", {
        email: formData?.email ? formData?.email.toLowerCase() : "",
        phone: formData?.phone ? formData?.phone : "",
        fullName: formData?.lastName ? `${formData?.fullName} ${formData?.lastName}` : " ",
        firstName: formData?.firstName ? formData?.firstName : "",
        lastName: formData?.lastName ? formData?.lastName : "",
        userName: formData?.email ? formData?.email : "",
        countryCode: formData?.countryCode ? formData?.countryCode : "+91",
        address: formData?.address ? formData?.address : "",
        zipCode: formData?.zipCode ? formData?.zipCode : "",
        city: formData?.city ? formData?.city : "",
        state: formData?.state ? formData?.state : "",
        country: formData?.country ? formData?.country : ""
      });
      return { success: true, message: "Billing information updated successfully!", data: response?.data?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong during update profile data!' };
      }
    }
  },
);

export const resendEmail = createAsyncThunk(
  'user/resend',
  async (formData) => {
    try {
      const response = await myAxios.post("user/resend", {
        email: formData?.email ? formData?.email.toLowerCase() : "",
      });
      return { success: true, message: "Email send successfully!", data: response?.data?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong during sending email!' };
      }
    }
  },
);

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (formData) => {
    try {
      const response = await myAxios.post("user/changepassword", {
        password: formData.password,
        oldPassword: formData.oldPassword,
      });

      localStorage.removeItem('authToken');
      localStorage.removeItem('isEmailVerify');
      return { success: true, message: "Password changed successfully!", data: response?.data?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong during change password!' };
      }
    }
  },
);

export const logout = createAsyncThunk(
  'user/logout',
  async (formData) => {
    try {
      const response = await myAxios.post("user/logout");
      return { success: true, message: "Logout successfully!", data: response?.data?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong during logging out!' };
      }
    } finally {
      localStorage.removeItem('authToken');
      localStorage.removeItem('isEmailVerify');
    }
  },
);

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    clearLoginData: state => {
      state.data = null;
    },
    setLoginData: (state, action) => {
      state.data = action.payload;
    },
    dummyMethod: (state, action) => {
      console.log("method called.");
    }
  },
});

export const loginReducer = loginSlice.reducer;
export const { clearLoginData, setLoginData, dummyMethod } = loginSlice.actions;

export const selectLoggedInData = (state) => state.loginUser.data;
