import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: [],
};

export const fetchReportList = createAsyncThunk(
    'user/reportList',
    async (query) => {
        try {
            const response = await myAxios.get(`user/getReport`, {
                params: {
                    ...query
                }
            });
            return { success: true, message: "Report types fetched successfully!", data: response?.data?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);


const reportListSlice = createSlice({
    name: 'reportList',
    initialState,
    reducers: {
        clearReportListData: state => {
            state.data = [];
        },
        setReportListData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const reportListReducer = reportListSlice.reducer;
export const { clearReportListData, setReportListData } = reportListSlice.actions;

export const selectReportListData = (state) => state.reportList.data;





