import React from 'react'

const EmptyList = () => {
    return (
        <div className="w-full flex h-full items-center flex-wrap justify-center gap-10">
            <div className="grid gap-4 w-60">
                <img
                src='/assets/empty.svg'
                className='w-32 mx-auto'
                />
                <div>
                    <h2 className="text-center text-black text-base font-semibold leading-relaxed pb-1">There’s no data found</h2>
                    <p className="text-center text-black text-sm font-normal leading-snug pb-4">Try again to see results </p>
                </div>
            </div>
        </div>
    )
}

export default EmptyList
