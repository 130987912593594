import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { NAVIGATION } from '../lib/navigation'
import { useSelector } from 'react-redux'
import { selectLoggedInData } from '../store/slices/api/auth/loginSlice'

const PrivateRoutes = () => {
    const authToken = localStorage.getItem("authToken")
    const loggedInData = useSelector(selectLoggedInData);
    // todo: const isEmailVerify = localStorage.getItem("isEmailVerify");

    return (
        ((!authToken || authToken === null || authToken === undefined)) ? <Navigate to={NAVIGATION.AUTH_NAVIGATION.LOGIN} /> : <Outlet />
    )
}

export default PrivateRoutes;