import React from 'react'
import { useSelector } from 'react-redux';
import { selectReportImageData } from '../../../../store/slices/api/inner/reportSlice';

export const ReportTitle = ({
    title
}) => title ? <div className="px-4 py-3 font-semibold text-primary uppercase bg-primary bg-opacity-10">
    {title}
</div> : null;

const ReportHOC = ({pageNumber,title,children, head, isLastPage=false}) => {
    const currentDate = new Date().toLocaleDateString('en-US', {
        month: 'numeric', 
        day: 'numeric',
        year: 'numeric'
    })

    const reportImage = useSelector(selectReportImageData)

    return (
        <div className={`page flex bg-white flex-col text-sm`}>
            <div className='flex-1 flex bg-white h-[256mm] p-[10mm] flex-col items-between relative'>
                <div>
                    <div className="flex gap-5 justify-between w-full uppercase max-md:flex-wrap max-md:max-w-full">
                        <img src="/assets/logo.svg" className="h-10" alt="Roof Reveal Logo" />

                        <div className="flex flex-col items-end font-semibold leading-5">
                            <p>{head}</p>
                            <p className="text-neutral-400">{currentDate}</p>
                        </div>
                    </div>
                    <div className="flex gap-5 justify-between px-4 py-3 mt-6 font-semibold leading-5 text-white uppercase bg-zinc-900 max-md:flex-wrap max-md:max-w-full">
                        <div>{reportImage?.buildingName}</div>
                        <div>Report: #{reportImage?.orderNo}</div>
                    </div>
                    <ReportTitle title={title} />
                </div>

                <div className='flex-1 relative'>{children}</div>

                <div className="flex border-t-4 border-t-zinc-900 justify-between pt-3 text-xs text-zinc-900">
                    <div>© 2008-2024 Roof Revel. All Rights Reserved.</div>
                    <div className="font-medium">Page: {pageNumber}</div>
                </div>
            </div>
        </div>
    )
}

export default ReportHOC