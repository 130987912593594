import React from 'react'
import { NAVIGATION } from '../../../lib/navigation'
import { useNavigate } from 'react-router-dom'

const formatTitle = (title) => {
    if (!title) return "";
    return title?.length > 30 ? title.substring(0, 30) + '...' : title
}


const Breadcrumb = ({ crumbs, className }) => {
    const navigate = useNavigate();
    const handleNavigate = (href) => {
        if (href) {
            navigate(href);
        }else return;
    }
    return (
        <nav className={`hidden md:flex px-6 items-center h-12 relative z-[999] bg-white ${className}`} aria-label="Breadcrumb">
            <ol className="inline-flex items-start space-x-1 md:space-x-2 rtl:space-x-reverse w-full bg- max-w-screen-xl mx-auto">
                <li className="inline-flex items-center text-gray-700">
                    <div onClick={() => handleNavigate(NAVIGATION.INNER_NAVIGATION.DASHBOARD)} className="cursor-pointer inline-flex items-center uppercase text-sm text-gray-700 hover:text-primary">
                        Home
                    </div>
                </li>
                {crumbs && crumbs.map((crumb, idx) => <li key={idx}>
                    <div className="flex items-center">
                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                        </svg>
                        <div onClick={() => handleNavigate(crumb.href ? crumb.href : null)} className="cursor-pointer ms-1 text-sm text-gray-400 uppercase hover:text-primary md:ms-2">{formatTitle(crumb?.title)}</div>
                    </div>
                </li>)}
            </ol>
        </nav>
    )
}

export default Breadcrumb