import React, { useState } from 'react'
import DashboardHOC from '../../../components/pages/inner/hoc/DashboardHOC'
import MapTopBar from '../../../components/common/inner/MapTopBar'
import InfoForm from '../../../components/pages/inner/explorer/InfoForm'
import useApiRequest from '../../../store/slices/hook/useApiRequest'
import { selectLoggedInData, setLoginData } from '../../../store/slices/api/auth/loginSlice'
import { useDispatch, useSelector } from 'react-redux'
import { addReport, setReportData } from '../../../store/slices/api/inner/reportSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { NAVIGATION } from '../../../lib/navigation'
import LowCreditWarning from '../../../components/common/inner/LowCreditWarning'

const XactimateInfo = () => {
    const [activeIndices, setActiveIndices] = useState(["CustomerContacts", "Address", "ReportSummary"]);
    const loggedInUser = useSelector(selectLoggedInData);
    const selectedReportType = useSelector((state) => state?.selectedReportType?.value)
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const toggleAccordion = (index) => {
        if (activeIndices.includes(index)) {
            setActiveIndices(activeIndices.filter(item => item !== index));
        } else {
            setActiveIndices([...activeIndices, index]);
        }
    };

    const defaultValues = {
        email: loggedInUser?.contactInfo?.email ?? "",
        phone: loggedInUser?.contactInfo?.phone ?? "",
        fullName: loggedInUser?.contactInfo?.fullName ?? "",
        firstName: loggedInUser?.contactInfo?.firstName ?? "",
        lastName: loggedInUser?.contactInfo?.lastName ?? "",
        userName: loggedInUser?.contactInfo?.userName ?? "",
        countryCode: loggedInUser?.contactInfo?.countryCode ?? "",
        address: loggedInUser?.contactInfo?.address ?? "",
        zipCode: loggedInUser?.contactInfo?.zipCode ?? "",
        city: loggedInUser?.contactInfo?.city ?? "",
        company: loggedInUser?.contactInfo?.company ?? "",
        state: loggedInUser?.contactInfo?.state ?? "",
        country: loggedInUser?.contactInfo?.country ?? "",
    }

    // handling save button
    const beforeAPICall = (data) => {
        return {
            success: true,
            refactoredData: {
                reportId: params?.reportId,
                email: data?.email ?? "",
                phone: data?.phone ?? "",
                fullName: data?.fullName ?? "",
                firstName: data?.firstName ?? "",
                lastName: data?.lastName ?? "",
                userName: data?.userName ?? "",
                countryCode: data?.countryCode ?? "",
                address: data?.address ?? "",
                zipCode: data?.zipCode ?? "",
                city: data?.city ?? "",
                company: data?.company ?? "",
                state: data?.state ?? "",
                country: data?.country ?? "",
                summary: data?.summary ?? "",
                notes: data?.notes ?? "",
                latitude: data?.lat ?? "",
                longitude: data?.lng ?? "",
                Thumbnail: "",
                roofType: "",
                approxAge: "",
                buildingName: "",
                coordinates: [],
            }
        }
    }

    const afterAPICall = (response) => {
        dispatch(setLoginData({
            ...loggedInUser,
            subscriptionCredits: loggedInUser?.subscriptionCredits - response?.decrementValue,
          }))
        navigate(NAVIGATION.INNER_NAVIGATION.DASHBOARD);
    }

    const { control, isLoading, handleSubmit } = useApiRequest({
        apiAction: addReport,
        setValueAction: setReportData,
        beforeAPICall,
        afterAPICall,
        defaultValues,
    });

    return (
        <DashboardHOC>
            <form onSubmit={handleSubmit}>
                <MapTopBar isLoading={isLoading} head="Xactimate Report" title="Xactimate Report" showUpgrade={false} />
                <div className='w-full max-w-2xl px-5 mb-16 mx-auto mt-20'>
                {loggedInUser?.subscriptionCredits < selectedReportType?.credits && <LowCreditWarning />}
                    <InfoForm control={control} activeIndices={activeIndices} toggleAccordion={toggleAccordion} />
                </div>
            </form>
        </DashboardHOC>
    )
}

export default XactimateInfo