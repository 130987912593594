import React, { useState } from 'react';
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import FilterPill from './FilterPill';

const categories = [
    {
        id: 1, title: "Roof type", items: [
            { id: 1, text: "A-Frame Roof", active: true },
            { id: 2, text: "Butterfly Roof", active: false },
            { id: 3, text: "Combination Roof", active: false },
            { id: 4, text: "Dome Roof", active: false },
            { id: 5, text: "Dutch Gable Roof", active: true },
            { id: 6, text: "Flat Roof", active: false },
        ]
    }, {
        id: 2, title: "Structure type", items: [
            { id: 7, text: "A-Frame Roof", active: true },
            { id: 8, text: "Butterfly Roof", active: false },
            { id: 9, text: "Combination Roof", active: false },
            { id: 10, text: "Dome Roof", active: false },
            { id: 11, text: "Dutch Gable Roof", active: true },
            { id: 12, text: "Flat Roof", active: false },
        ]
    }, {
        id: 3, title: "Demographics", items: [
            { id: 13, text: "A-Frame Roof", active: true },
            { id: 14, text: "Butterfly Roof", active: false },
            { id: 15, text: "Combination Roof", active: false },
            { id: 16, text: "Dome Roof", active: false },
            { id: 17, text: "Dutch Gable Roof", active: true },
            { id: 18, text: "Flat Roof", active: false },
        ]
    },
]

const FilterCategories = () => {
    const [activeIndices, setActiveIndices] = useState([]);

    const toggleAccordion = (index) => {
        if (activeIndices.includes(index)) {
            setActiveIndices(activeIndices.filter(item => item !== index));
        } else {
            setActiveIndices([...activeIndices, index]);
        }
    };

    return (
        <div>
            {categories.map((category, index) => (
                <div key={category.id} className='w-full pb-6 mb-6 border-b border-b-gray-200'>
                    <button className='flex w-full outline-none items-center justify-between' onClick={() => toggleAccordion(index)}>
                        <span className='text-sm'>{category.title}</span>
                        <span
                            className='text-gray-400 text-sm flex gap-3 items-center rounded-md'
                        >
                            {activeIndices.includes(index) ? <FaChevronUp /> : <FaChevronDown />}
                        </span>
                    </button>
                    <div className={`py-2 ${activeIndices.includes(index) ? '' : 'hidden'}`}>
                        <div className='flex flex-wrap gap-2 mt-6'>
                            {category.items.map(item => (
                                <FilterPill key={item.id} text={item.text} variant={item.active ? "active" : "default"} />
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default FilterCategories;
