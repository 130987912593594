import React from 'react'

const Headline = ({title, desc}) => {
    return (
        <div className="w-full flex flex-col items-center justify-center">
            {(title || desc) && <div className='w-full px-0 max-w-2xl'>
                {title && <h1 className='text-center text-4xl font-semibold' dangerouslySetInnerHTML={{__html: title}}/>}
                {desc && <p className='text-center mt-6' dangerouslySetInnerHTML={{__html: desc}} />}
            </div>}
        </div>
    )
}

export default Headline