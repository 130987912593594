import React from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { MdOutlineAssignment } from 'react-icons/md'
import Input from '../../../common/Input'
import TextArea from '../../../common/TextArea'

const InfoReportSummery = ({
    control,
    activeIndices,
    toggleAccordion,
}) => {
    return (
        <div className='bg-neutral-50 w-full border border-solid shadow-lg mb-6 border-stone-300  p-8 rounded-lg'>
            <button type="button" className='flex w-full outline-none items-center justify-between' onClick={() => toggleAccordion("ReportSummary")}>
                <div className='text-xl flex items-center gap-2'><MdOutlineAssignment /> <span>Report Summary</span></div>
                <span
                    className='text-xl flex gap-3 items-center rounded-md'
                >
                    {activeIndices.includes("ReportSummary") ? <FaChevronUp /> : <FaChevronDown />}
                </span>
            </button>
            <div className={`py-2 ${activeIndices.includes("ReportSummary") ? '' : 'hidden'}`}>
                <TextArea
                    control={control}
                    name="summary"
                    rules={{
                        required: "this field is required"
                    }}
                    label="Summary"
                />
                <TextArea
                    control={control}
                    name="notes"
                    rules={{
                        required: "this field is required"
                    }}
                    label="Notes"
                />

                <div className='flex gap-6 flex-col md:flex-row'>
                    <Input
                        control={control}
                        name="latitude"
                        rules={{
                            required: "this field is required"
                        }}
                        label="Latitude"
                    />
                    <Input
                        control={control}
                        name="longitude"
                        rules={{
                            required: "this field is required"
                        }}
                        label="Longitude"
                    />
                </div>
            </div>
        </div>
    )
}

export default InfoReportSummery