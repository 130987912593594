import React from 'react'
import HelpHOC from '../../../components/pages/inner/hoc/HelpHOC'
import { MdArrowOutward } from "react-icons/md";
import { Link } from 'react-router-dom';
import { NAVIGATION } from '../../../lib/navigation';

const helpData = [
    { title: "Interactive Dashboard", id: 1 },
    { title: "Customizable Reports", id: 2 },
    { title: "Detailed Measurements", id: 3 },
    { title: "Accessible Anytime, Anywhere", id: 4 },
    { title: "Secure Data Storage", id: 5 },
    { title: "Real-time Updates", id: 6 },
    { title: "Easy Sharing Options", id: 7 },
    { title: "Responsive Support Team", id: 8 },
]

const HelpCenter = () => {
    return (
        <HelpHOC
            crumbs={[{ title: "Help" }]}
            title="Help Center"
            desc="Explore our Help Center, your go-to destination for quick solutions, detailed guides, and expert support."
        >
            <div className='grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6'>

                {helpData.map((item, idx) => <div key={idx} className='w-full bg-gray-100 shadow-lg px-8 py-7 flex items-center justify-between'>
                    <p>{item.title}</p>
                    <Link to={`${NAVIGATION.INNER_NAVIGATION.HELP_CENTER.MAIN}/${item.id}`} className='h-6 w-6 rounded-sm bg-gray-300 flex items-center justify-center'>
                        <MdArrowOutward />
                    </Link>
                </div>)}

            </div>
        </HelpHOC>
    )
}

export default HelpCenter