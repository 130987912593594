import React from 'react'
import DashboardHOC from '../../../components/pages/inner/hoc/DashboardHOC'
import Breadcrumb from '../../../components/common/inner/Breadcrumb'
import Button from '../../../components/common/Button'
import { useSelector } from 'react-redux'
import { selectSubscribeInitData } from '../../../store/slices/api/inner/planSlice'

const Checkout = () => {
    const selectedPlan = useSelector(state => state.selectedPlan.value);
    const selectSubscribeData = useSelector(selectSubscribeInitData)

    return (
        <DashboardHOC>

            <Breadcrumb crumbs={[{ title: "Checkout", href: "" }]} />
            <div className='px-4 py-20 w-full max-w-5xl mx-auto'>
                {selectedPlan && <div className="w-full flex flex-col md:flex-row justify-center">

                    <div className='w-full max-w-md bg-natural-100 rounded-xl p-6 shadow-xl'>
                        <p className='font-semibold mb-6'>Monthly Commitment</p>
                        <h1 className='text-4xl font-semibold mb-4'>{selectedPlan?.credits} Credits</h1>
                        <p className='text-xs text-gray-400 font-semibold mb-10'>For most businesses</p>

                        <div className='flex items-center justify-between mb-4'>
                            <p className='font-semibold'>Total</p>
                            <h1 className='text-4xl font-semibold'>${selectedPlan?.subscriptionPrice}</h1>
                        </div>
                        {selectSubscribeData && <Button type='button' onClick={() => window.open(selectSubscribeData, "_self")} className="mb-6 w-full">
                            Process Order
                        </Button>}
                        <p className='text-xs text-gray-400 font-semibold mb-10 px-4 text-center'>
                            Secure checkout. For your convenience, we will save your encrypted payment information for future orders.
                        </p>
                    </div>
                </div>}
            </div>

        </DashboardHOC>
    )
}

export default Checkout