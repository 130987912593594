import React from 'react'

const ModalHOC = ({
    title,
    open,
    toggleModal,
    children,
    maxWidth = "max-w-sm",
}) => {
    return (
        <div
            className={`${open ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[9999] justify-center items-center w-full md:inset-0 h-full max-h-full`}
        >
            <div className='absolute z-10 backdrop-blur-sm bg-black/75 h-full w-full max-h-full' onClick={toggleModal} />
            <div className="relative flex items-center justify-center p-4 z-1 w-full h-full mx-auto max-h-full">
                <div className={`relative z-20 bg-white rounded-lg shadow w-full ${maxWidth}`}>
                    {title && <h3 className="text-lg px-2 my-6 text-center font-semibold text-gray-900">
                        {title}
                    </h3>}

                    <div className="p-4 md:p-5">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalHOC