import React, { useState } from 'react'
import { MdClose, MdOutlineFilterList } from "react-icons/md";
import AppliedFilters from './AppliedFilters';
import FilterCategories from './FilterCategories';

const FiltersList = ({
    toggleFilters
}) => {

    const [filterData, setFilterData] = useState([
        { id: 1, text: "A-Frame Roof", active: true },
        { id: 2, text: "Butterfly Roof", active: false },
        { id: 3, text: "Combination Roof", active: false },
        { id: 4, text: "Dome Roof", active: false },
        { id: 5, text: "Dutch Gable Roof", active: true },
        { id: 6, text: "Flat Roof", active: false },
    ])

    return (
        <div>
            <div className='flex items-center justify-between mb-6'>
                <div className='flex items-center gap-2'>
                    <MdOutlineFilterList className="text-xl" />
                    <span>Filters</span>
                </div>
                <button onClick={toggleFilters} className='p-2 outline-none bg-gray-200 rounded-md'>
                    <MdClose className='text-lg' />
                </button>
            </div>

            <AppliedFilters data={filterData} />
            <FilterCategories/>
        </div>
    )
}

export default FiltersList