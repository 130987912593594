import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
}

export const selectedAreaSlice = createSlice({
  name: 'selectedArea',
  initialState,
  reducers: {
    setSelectedArea: (state, action) => {
      state.value = action.payload
    },
    clearSelectedArea: (state) => {
      state.value = []
    },
  },
})

export const { setSelectedArea, clearSelectedArea } = selectedAreaSlice.actions

export default selectedAreaSlice.reducer