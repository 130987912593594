import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {
        lat: null,
        lng: null
    }
    ,
}

export const selectedCoordinatesSlice = createSlice({
    name: 'selectedCoordinates',
    initialState,
    reducers: {
        setSelectedCoordinates: (state, action) => {
            state.value = action.payload
        },
        clearSelectedCoordinates: (state) => {
            state.value = {
                lat: null,
                lng: null
            }
        },
    },
})

export const { setSelectedCoordinates, clearSelectedCoordinates } = selectedCoordinatesSlice.actions

export default selectedCoordinatesSlice.reducer