import React, { useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, DrawingManager, Marker } from '@react-google-maps/api';
import axios from 'axios';

const apiKey = 'AIzaSyDT_BK45Q2Vl7lPDpyPIjylGbFXzQY6Z1g';
const libraries = ['drawing', 'places', 'geometry'];

const mapContainerStyle = {
  width: '100vw',
  height: '100vh',
};

const center = {
  lat: 39.3594,
  lng: -77.4292,
};

const CanvasDraw = () => {
  const [map, setMap] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const [rectangle, setRectangle] = useState(null);
  const [circle, setCircle] = useState(null);
  const [markers, setMarkers] = useState([]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const clearMarkers = useCallback(() => {
    markers.forEach((marker) => marker.setMap(null));
    setMarkers([]);
  }, [markers]);

  const onPolygonComplete = useCallback((polygon) => {
    setPolygon(polygon);
    clearMarkers();
  }, [clearMarkers]);

  const onRectangleComplete = useCallback((rectangle) => {
    setRectangle(rectangle);
    clearMarkers();
  }, [clearMarkers]);

  const onCircleComplete = useCallback((circle) => {
    setCircle(circle);
    clearMarkers();
  }, [clearMarkers]);

  const clearDrawing = () => {
    if (polygon) {
      polygon.setMap(null);
      setPolygon(null);
    }
    if (rectangle) {
      rectangle.setMap(null);
      setRectangle(null);
    }
    if (circle) {
      circle.setMap(null);
      setCircle(null);
    }
    clearMarkers();
  };

  const fetchHomeAddresses = async () => {
    if (!polygon && !rectangle && !circle) return;

    clearMarkers();

    try {
      if (polygon) {
        const path = polygon.getPath().getArray().map((latLng) => ({
          lat: latLng.lat(),
          lng: latLng.lng(),
        }));
        await fetchAddressesInPolygon(path);
      } else if (rectangle) {
        const bounds = rectangle.getBounds();
        await fetchAddressesInBounds(bounds);
      } else if (circle) {
        const center = circle.getCenter();
        const radius = circle.getRadius();
        await fetchAddressesInRadius(center, radius);
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  const fetchAddressesInPolygon = async (path) => {
    const bounds = new window.google.maps.LatLngBounds();
    path.forEach(point => bounds.extend(point));

    const response = await fetchAddresses(bounds);
    const filteredResults = response.filter(result => {
      const point = new window.google.maps.LatLng(result.lat, result.lng);
      return window.google.maps.geometry.poly.containsLocation(point, polygon);
    });

    addMarkers(filteredResults);
  };

  const fetchAddressesInBounds = async (bounds) => {
    const response = await fetchAddresses(bounds);
    const filteredResults = response.filter(result => {
      const point = new window.google.maps.LatLng(result.lat, result.lng);
      return bounds.contains(point);
    });

    addMarkers(filteredResults);
  };

  const fetchAddressesInRadius = async (center, radius) => {
    const bounds = new window.google.maps.Circle({ center, radius }).getBounds();
    const response = await fetchAddresses(bounds);
    const filteredResults = response.filter(result => {
      const point = new window.google.maps.LatLng(result.lat, result.lng);
      return window.google.maps.geometry.spherical.computeDistanceBetween(center, point) <= radius;
    });

    addMarkers(filteredResults);
  };

  const fetchAddresses = async (bounds) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latSteps = (ne.lat() - sw.lat()) / 10;
    const lngSteps = (ne.lng() - sw.lng()) / 10;

    const addresses = [];

    for (let lat = sw.lat(); lat <= ne.lat(); lat += latSteps) {
      for (let lng = sw.lng(); lng <= ne.lng(); lng += lngSteps) {
        const geoUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
        const response = await axios.get(geoUrl);

        if (response.data.results) {
          response.data.results.forEach(result => {
            const address = result.formatted_address;
            const location = result.geometry.location;
            if (result.types.includes('street_address') || result.types.includes('premise') || result.types.includes('subpremise')) {
              addresses.push({ lat: location.lat, lng: location.lng, address });
            }
          });
        }
      }
    }

    return addresses;
  };

  const addMarkers = (results) => {
    results.forEach(result => {
      const marker = new window.google.maps.Marker({
        position: { lat: result.lat, lng: result.lng },
        map: map,
        title: result.address,
      });
      markers.push(marker);
    });
    setMarkers([...markers]);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={16}
        center={center}
        onLoad={onLoad}
      >
        <DrawingManager
          onPolygonComplete={onPolygonComplete}
          onRectangleComplete={onRectangleComplete}
          onCircleComplete={onCircleComplete}
          options={{
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: ['polygon', 'rectangle', 'circle'],
            },
            polygonOptions: {
              editable: true,
              draggable: true,
            },
            rectangleOptions: {
              editable: true,
              draggable: true,
            },
            circleOptions: {
              editable: true,
              draggable: true,
            },
          }}
        />
        {markers.map((marker, index) => (
          <Marker key={index} position={marker.position} title={marker.title} />
        ))}
      </GoogleMap>
      <div style={{ position: 'absolute', bottom: '2rem', right: '25%' }}>
        <button onClick={fetchHomeAddresses} style={{ backgroundColor: 'orange', color: 'white', marginRight: '1rem' }}>
          Fetch Home Addresses
        </button>
        <button className=" bg-orange-500 rounded-full" onClick={clearDrawing} style={{ backgroundColor: 'red', color: 'white' }}>
          Clear Drawing
        </button>
      </div>
    </div>
  );
};

export default CanvasDraw;