import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import MapTopBar from "../../components/common/inner/MapTopBar";
import CanvasCreditsModal from "../../components/pages/inner/canvas/CanvasCreditsModal";
import ReportHOC, { ReportTitle } from "../../components/pages/inner/hoc/ReportHOC";
import ResultList from "../../components/pages/inner/canvas/ResultList";
import { clearSelectedArea } from "../../store/slices/common/selectedAreaSlice";
import { clearPlaceData, clearSolarData } from "../../store/slices/api/misc/autoCompleteSlice";
import { toggleCanvasModal } from "../../store/slices/common/toggleSlice";
import AddressLink from "./AddressLink";
import ClientDetails from "./ClientDetails";
import { selectLoggedInData } from "../../store/slices/api/auth/loginSlice";
import { selectReportImageData } from "../../store/slices/api/inner/reportSlice";
import html2pdf from 'html2pdf.js';
import { toast } from "react-toastify";

export default function CanvasReport() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const loggedInData = useSelector(selectLoggedInData);
  const reportData = useSelector(selectReportImageData);
  const [isDownloadLoading, setDownloadLoading]  = useState(false);
  const params = useParams();

  const toggleModal = async () => await dispatch(toggleCanvasModal());

  const pdfRef = useRef(null);

  const handleGeneratePDF = async () => {
    const element = pdfRef.current;
    setDownloadLoading(true)
    await html2pdf()
      .from(element)
      .set({
        margin: [0, 0],
        filename: `${reportData?.buildingName}-canvasing.pdf`,
        html2canvas: { scale: 2, useCORS: true, allowTaint: true },
        jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4' },
        compressPDF: true,
      })
      .save()
      .then(() => {
        dispatch(clearSelectedArea());
        dispatch(clearSolarData());
        dispatch(clearPlaceData());
        toast.success("report downloaded successfully!");
        setDownloadLoading(false)
      })
      .catch((error) => {
        console.error("Error during PDF generation:", error);
        toast.error("Sorry, something went wrong!");
        setDownloadLoading(false)
      });
  };


  const paginateItems = (items, itemsPerPage) => {
    const paginatedItems = [];
    for (let i = 0; i < items?.length; i += itemsPerPage) {
      paginatedItems.push(items.slice(i, i + itemsPerPage));
    }
    return paginatedItems;
  };

  const paginatedItems = paginateItems(reportData?.canvasBuildingList, 10);

  return (
    <section>
      <MapTopBar
        head="Canvasing Report"
        designLink={state?.designLink}
        reportLink={state?.reportLink}
        title="Canvasing Report"
        toggleModal={toggleModal}
        handleDownload={handleGeneratePDF}
        isDownloadLoading={isDownloadLoading}
      // Remove print handler if not needed
      // handlePrint={handlePrintPDF}
      />

      <div className="py-20 bg-neutral-50">
        <div ref={pdfRef} className="flex flex-col justify-center items-center max-md:px-5">
          <ReportHOC pageNumber={1} head="Canvasing Report">
            <div className="w-full mx-auto mt-6 h-[500px] overflow-hidden relative">
              <img
                loading="lazy"
                src={reportData?.file ?? "/assets/placeholder.jpg"}
                className="w-full h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover"
                alt="Description"
              />
            </div>
            <div className="flex justify-between gap-6 mt-6">
              <div className="w-1/2">
                <ReportTitle title="Prepared for" />
                <ClientDetails loggedInData={loggedInData} />
              </div>
              <div className="w-1/2">
                <ReportTitle title="Table of Contents" />
                <div className="mt-5">
                  <ul className="leaders">
                    <li>
                      <span>Roof listing</span>{" "}
                      <span>
                        2 {paginatedItems?.length + 1 === 2 ? "" : "- " + (paginatedItems?.length + 1)}
                      </span>
                    </li>
                    <li>
                      <span>Online maps</span> <span>{paginatedItems?.length + 2}</span>
                    </li>
                    <li>
                      <span>Important legal notice & disclaimer</span>{" "}
                      <span>{paginatedItems?.length + 3}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ReportHOC>

          {paginatedItems?.map((itemPage, pageIndex) => {
            const start = pageIndex * 10 + 1;
            const end = start + itemPage.length - 1;
            return (
              <ReportHOC head="Canvasing Report" key={pageIndex} pageNumber={pageIndex + 2} title="Roof Listing">
                <div className="my-3 text-sm font-semibold">
                  Showing roofs {start} - {end} from {reportData?.length}
                </div>
                <div className="mt-3 grid grid-cols-1 gap-4">
                  <ResultList data={itemPage} slideOver={false} />
                </div>
              </ReportHOC>
            );
          })}

          <ReportHOC head="Canvasing Report" pageNumber={paginatedItems?.length + 2} title="Online Maps">
            <AddressLink
              from={"Premier Choice Exteriors, 4580 Mack Ave Suite G, Frederick, MD 21703, United States"}
              to={reportData?.buildingName}
            />
          </ReportHOC>

          <ReportHOC isLastPage={true} head="Canvasing Report" pageNumber={paginatedItems?.length + 3} title="IMPORTANT LEGAL NOTICE & DISCLAIMER">
            <div className="mt-6 text-xs font-semibold leading-5 text-zinc-900 max-md:max-w-full">
              Notice and Disclaimer
            </div>
            <div className="mt-3 text-xs leading-5 text-zinc-900 max-md:max-w-full">
              No Warranty: The Copyrighted Materials are provided to you "as is," and you agree to use
              it at your own risk.
            </div>
            <div className="mt-3 text-xs leading-5 text-zinc-900 max-md:max-w-full">
              RoofRavel Technologies makes no guarantees, representations or warranties of any kind,
              express or implied, arising by law or otherwise, including but not limited to, content,
              quality, accuracy, completeness, effectiveness, reliability, fitness for a particular
              purpose, usefulness, use or results to be obtained from the Copyrighted Materials.
            </div>
            <div className="mt-3 text-xs leading-5 text-zinc-900 max-md:max-w-full">
              Contractors agree to always conduct a preliminary site survey to verify Roof Report
              ordered. In the event of an error in a Report, your sole remedy will be a refund of the
              fees paid by you to obtain this Report.
            </div>
          </ReportHOC>
        </div>
      </div>

      <CanvasCreditsModal toggleModal={toggleModal} />
    </section>
  );
}
