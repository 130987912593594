import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../common/Button'
import ModalHOC from '../hoc/ModalHOC'
import { useNavigate } from 'react-router-dom'
import { NAVIGATION } from '../../../../lib/navigation'
import useApiRequest from '../../../../store/slices/hook/useApiRequest'
import { cancelPlan } from '../../../../store/slices/api/inner/planSlice'

const CancelModal = ({ toggleModal }) => {
    const cancelModal = useSelector(state => state.toggle.cancelModal)
    
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const afterAPICall = (response) => {
        navigate(NAVIGATION.INNER_NAVIGATION.DASHBOARD)
    }

    const { isLoading, handleSubmit } = useApiRequest({
        apiAction: cancelPlan,
        afterAPICall,
    });


    const handleCancelPlan = () => {
        handleSubmit();
    };
    return (
        <ModalHOC open={cancelModal} toggleModal={toggleModal} title="Are you sure you want to cancel the subscription?" maxWidth='max-w-xs'>
            <div className='flex items-center justify-between gap-2'>
            <Button className="w-full" variant="outline" onClick={toggleModal}>
                No
            </Button>
            <Button className="w-full" isLoading={isLoading} onClick={handleCancelPlan}>
                yes
            </Button>
            </div>
        </ModalHOC>
    )
}

export default CancelModal