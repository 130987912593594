import { Link, useLocation, useNavigate } from "react-router-dom";
import { NAVIGATION } from "../../../lib/navigation";
import Button from "../Button";
import { useState } from "react";
import { FaBars } from "react-icons/fa6";
import { MdClose } from "react-icons/md";

const links = [
  { label: "Home", href: NAVIGATION.HOME },
  // { label: "Find Roof", href: NAVIGATION.FIND_ROOF },
  { label: "About", href: NAVIGATION.ABOUT },
  { label: "Features", href: NAVIGATION.FEATURES },
  { label: "Contact", href: NAVIGATION.CONTACT },
];

export default function HeaderSection({
  home = false
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const authToken = localStorage.getItem("authToken");
  const [open, setOpen] = useState(false);
  const toggleMenu = () => setOpen(prevStatus => !prevStatus)
  //todo: const isEmailVerify = localStorage.getItem("isEmailVerify")

  return (
    <header>
      <nav className={`w-full px-4 lg:px-6 py-2.5 md:py-8 uppercase absolute top-0 z-50 ${home ? 'bg-transparent text-zinc-700 md:text-white' : 'bg-white border-b border-solid border-zinc-200 text-gray-700'}`}>
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <Link to="/" className="flex items-center">
            <img src={home ? "/assets/logo-white.svg" : "/assets/logo.svg"} className="h-6 sm:h-9" alt="Roof Reveal Logo" />
          </Link>

          <div className="flex items-center lg:order-2">
            <Button type="button" variant={home ? 'default' : 'dark'} onClick={() => navigate(authToken ? NAVIGATION.INNER_NAVIGATION.DASHBOARD : NAVIGATION.AUTH_NAVIGATION.LOGIN)}>
              Sign In
            </Button>
            <button data-collapse-toggle="mobile-menu-2" onClick={toggleMenu} type="button" className="inline-flex items-center p-2 ml-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="mobile-menu-2" aria-expanded="false">
              <span className="sr-only">Open main menu</span>

              <FaBars className={`${open && "hidden"} w-4 h-4`}  />
              <MdClose className={`${!open && "hidden"} w-4 h-4`} />

            </button>
          </div>
          <div className={`${!open && "hidden"} pb-4 md:pb-0 shadow-xl md:shadow-none rounded-2xl bg-white md:bg-transparent justify-between items-center w-full lg:flex lg:w-auto lg:order-1`} id="mobile-menu-2">
            <ul className="flex flex-col mt-4 md:gap-0 gap-3 font-medium items-center lg:flex-row lg:space-x-8 lg:mt-0">
              {links.map(({ label, href }) => (
                <li>
                  <Link
                    key={href}
                    to={href}
                    className={`${location.pathname === href ? "text-primary" : ""}`}
                  >
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}