import React from 'react'
import HelpHOC from '../../../components/pages/inner/hoc/HelpHOC'
import { NAVIGATION } from '../../../lib/navigation'
import Input from '../../../components/common/Input'
import TextArea from '../../../components/common/TextArea';
import Button from '../../../components/common/Button';
import Headline from '../../../components/common/inner/Headline';
import { contact, setContactData } from '../../../store/slices/api/misc/contactSlice';
import useApiRequest from '../../../store/slices/hook/useApiRequest';

const HelpRequest = () => {

    const afterAPICall = () => {
        setValue("username", "")
        setValue("email", "")
        setValue("message", "")
    }

    const { isLoading, control, handleSubmit, setValue } = useApiRequest({
        apiAction: contact,
        setValueAction: setContactData,
        afterAPICall
    });

    return (
        <HelpHOC
            banner={false}
            crumbs={[{ title: "Help", href: NAVIGATION.INNER_NAVIGATION.HELP_CENTER.MAIN }, { title: "Help Request" }]}
        >
            <div className='w-full max-w-2xl px-5 mb-16 mx-auto'>
                <div className='bg-neutral-50 w-full border border-solid shadow-sm border-stone-300  p-8 rounded-lg'>
                    <Headline title="Submit a request" desc="Have a question or need assistance? Submit a request and our team will be happy to help." />
                    
                    <form onSubmit={handleSubmit}>
                        <Input
                            control={control}
                            name="username"
                            rules={{
                                required: "this field is required"
                            }}
                            label="Name"
                        />
                        <Input
                            control={control}
                            name="email"
                            rules={{
                                required: "this field is required"
                            }}
                            type="email"
                            label="Email"
                        />
                        <TextArea
                            control={control}
                            name="message"
                            rules={{
                                required: "this field is required"
                            }}
                            label="Message"
                        />
                        <p className='text-gray-400 text-xs mt-6'>Please enter the details of your request. A member of our support staff will respond as soon as possible.</p>
                        <div className="flex justify-end my-6">
                            <Button isLoading={isLoading}>
                                submit
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </HelpHOC>
    )
}

export default HelpRequest