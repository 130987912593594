import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../common/Button'
import ModalHOC from '../hoc/ModalHOC'
import ResultItem from './ResultItem'
import { toggleMapBreadcrumb, toggleMapSlide, toggleSingleBuildingModalModal } from '../../../../store/slices/common/toggleSlice'
import { selectLoggedInData, setLoginData } from '../../../../store/slices/api/auth/loginSlice'
import { clearSolarData } from '../../../../store/slices/api/misc/autoCompleteSlice'
import { clearSelectedArea } from '../../../../store/slices/common/selectedAreaSlice'
import useApiRequest from '../../../../store/slices/hook/useApiRequest'
import { addReport, setReportData } from '../../../../store/slices/api/inner/reportSlice'
import { useNavigate } from 'react-router-dom'
import { NAVIGATION } from '../../../../lib/navigation'
import { clearSelectedReportType } from '../../../../store/slices/common/selectedReportTypeSlice'

const SingleBuildingRequestModal = ({ reportId, coordinates, address }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toggleModal = () => {
        dispatch(toggleSingleBuildingModalModal());
    }
    const singleBuildingModal = useSelector(state => state.toggle.singleBuildingModal)
    const selectedBuilding = useSelector(state => state.selectedBuilding.value)
    const loggedInUser = useSelector(selectLoggedInData);
    // handling save button
    const beforeAPICall = (data) => {

        return {
            success: true,
            refactoredData: {
                reportId: reportId,
                email: loggedInUser?.contactInfo?.email ?? "",
                phone: loggedInUser?.contactInfo?.phone ?? "",
                fullName: loggedInUser?.contactInfo?.fullName ?? "",
                firstName: loggedInUser?.contactInfo?.firstName ?? "",
                lastName: loggedInUser?.contactInfo?.lastName ?? "",
                userName: loggedInUser?.contactInfo?.userName ?? "",
                countryCode: loggedInUser?.contactInfo?.countryCode ?? "",
                address: loggedInUser?.contactInfo?.address ?? "",
                zipCode: loggedInUser?.contactInfo?.zipCode ?? "",
                city: loggedInUser?.contactInfo?.city ?? "",
                company: loggedInUser?.contactInfo?.company ?? "",
                state: loggedInUser?.contactInfo?.state ?? "",
                country: loggedInUser?.contactInfo?.country ?? "",
                summary: "",
                notes: "",
                latitude: coordinates?.lat,
                longitude: coordinates?.lng,
                Thumbnail: selectedBuilding?.photos?.[0]?.photo_reference,
                file: selectedBuilding?.solarData?.imagePath,
                roofType: "",
                approxAge: "",
                buildingName: selectedBuilding?.displayName ? selectedBuilding?.displayName?.text : selectedBuilding?.shortFormattedAddress,
                buildingAddress: selectedBuilding?.shortFormattedAddress,
                coordinates: [
                    {
                        lat: selectedBuilding?.location?.latitude, lng: selectedBuilding?.location?.longitude
                    }
                ],
                reportTypeNumber: 2, //! for canvas report of single building 
            }
        }
    }

    const afterAPICall = (response) => {
        dispatch(toggleMapBreadcrumb(true));
        dispatch(clearSolarData());
        dispatch(toggleSingleBuildingModalModal());
        dispatch(toggleMapSlide());
        dispatch(clearSelectedReportType());

        dispatch(setLoginData({
            ...loggedInUser,
            subscriptionCredits: loggedInUser?.subscriptionCredits - response?.decrementValue,
        }))
        dispatch(clearSelectedArea());
        navigate(NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT_LIST);
    }

    const { isLoading, handleSubmit } = useApiRequest({
        apiAction: addReport,
        setValueAction: setReportData,
        beforeAPICall,
        afterAPICall,
        isForm: false,
    });

    const handleSave = () => {
        handleSubmit();
    };

    return (
        <ModalHOC open={singleBuildingModal} maxWidth="max-w-md" toggleModal={toggleModal} title="Are you sure you want to request for report">
            <div>
                <ResultItem item={selectedBuilding} slideOver={true} />
                <div className='flex items-center justify-between gap-2'>
                    <Button className="w-full" variant="outline" onClick={toggleModal}>
                        No
                    </Button>
                    <Button isLoading={isLoading} className="w-full" type='button' onClick={handleSave}>
                        yes
                    </Button>
                </div>
            </div>
        </ModalHOC>
    )
}

export default SingleBuildingRequestModal