import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../common/Button'
import ModalHOC from '../hoc/ModalHOC'
import { useNavigate } from 'react-router-dom'
import { NAVIGATION } from '../../../../lib/navigation'
import useApiRequest from '../../../../store/slices/hook/useApiRequest'
import { clearLoginData, logout } from '../../../../store/slices/api/auth/loginSlice'
import { toggleLogoutModal } from '../../../../store/slices/common/toggleSlice'

const LogoutModal = () => {
    const logoutModal = useSelector(state => state.toggle.logoutModal)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toggleModal = () => dispatch(toggleLogoutModal())

    const afterAPICall = (response) => {
        toggleModal();
        navigate(NAVIGATION.AUTH_NAVIGATION.LOGIN);
    }

    const { isLoading, handleSubmit } = useApiRequest({
        apiAction: logout,
        setValueAction: clearLoginData,
        afterAPICall,
        isForm: false,
    });


    const handleLogout = () => {
        handleSubmit();
    };
    return (
        <ModalHOC open={logoutModal} toggleModal={toggleModal} title="Are you sure you want to logout?" maxWidth='max-w-xs'>
            <div className='flex items-center justify-between gap-2'>
                <Button className="w-full" variant="outline" onClick={toggleModal}>
                    No
                </Button>
                <Button className="w-full" type='button' onClick={handleLogout}>
                    yes
                </Button>
            </div>
        </ModalHOC>
    )
}

export default LogoutModal