import React from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import {  MdDomain } from 'react-icons/md'
import Input from '../../../common/Input'

const InfoAddress = (
    {
        control,
        activeIndices,
        toggleAccordion
    }
) => {
    return (
        <div className='bg-neutral-50 w-full border border-solid shadow-lg mb-6 border-stone-300  p-8 rounded-lg'>
            <button type="button" className='flex w-full outline-none items-center justify-between' onClick={() => toggleAccordion("Address")}>
                <div className='text-xl flex items-center gap-2'><MdDomain /> <span>Address</span></div>
                <span
                    className='text-xl flex gap-3 items-center rounded-md'
                >
                    {activeIndices.includes("Address") ? <FaChevronUp /> : <FaChevronDown />}
                </span>
            </button>
            <div className={`py-2 ${activeIndices.includes("Address") ? '' : 'hidden'}`}>
                <Input
                    control={control}
                    name="address"
                    rules={{
                        required: "this field is required"
                    }}
                    label="Address"
                />
                <div className='flex gap-6 flex-col md:flex-row'>
                    <Input
                        control={control}
                        name="city"
                        rules={{
                            required: "this field is required"
                        }}
                        label="City"
                    />
                    <Input
                        control={control}
                        name="state"
                        rules={{
                            required: "this field is required"
                        }}
                        label="State"
                    />
                </div>

                <div className='flex gap-6 flex-col md:flex-row'>
                    <Input
                        control={control}
                        name="zipCode"
                        rules={{
                            required: "this field is required"
                        }}
                        label="Zip Code"
                    />
                    <Input
                        control={control}
                        name="country"
                        rules={{
                            required: "this field is required"
                        }}
                        label="Country"
                    />
                </div>
            </div>
        </div>
    )
}

export default InfoAddress