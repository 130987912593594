function IndustryCard({ imageSrc, industry }) {
    return (

        <div className="flex flex-col items-center mt-3 md:mt-0 justify-center rounded-lg bg-secondary w-full md:w-1/6 py-12">
            <img loading="lazy" src={imageSrc} alt={`${industry} industry icon`} className="aspect-square w-20" />
            <h3 className="mt-6 font-semibold uppercase text-gray-600">{industry}</h3>
        </div>
    );
}

export default function IndustriesSection() {
    const industries = [
        { imageSrc: "/assets/industry/home.png", industry: "Construction" },
        { imageSrc: "/assets/industry/bank.png", industry: "Government" },
        { imageSrc: "/assets/industry/page.png", industry: "Insurance" },
        { imageSrc: "/assets/industry/solar.png", industry: "Solar" },
        { imageSrc: "/assets/industry/cog.png", industry: "Utilities" },
    ];

    return (
        <>
            <section className="flex flex-col md:flex-row items-center justify-between mt-32 px-6 md:px-20  max-md:max-w-full">
                {industries.map((industry, index) => (
                    <IndustryCard key={index} imageSrc={industry.imageSrc} industry={industry.industry} />
                ))}
            </section>
        </>

    );
}