import * as React from "react";
import Button from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import { NAVIGATION } from "../../lib/navigation";
import { selectLoggedInData } from "../../store/slices/api/auth/loginSlice";
import { useSelector } from "react-redux";

export default function ThanksPage() {
    const navigate = useNavigate();
    const loggedInUser = useSelector(selectLoggedInData);

    const handleNavigate = (path) => {
        navigate(path)
    }


    return (

        <div className="w-full pt-32">
            <div className='w-full max-w-md mb-16 mx-auto px-6'>
                <div className='w-full'>
                    <div className="flex items-center">
                    <h2 className="mt-6 text-2xl font-semibold">Thankyou {`${loggedInUser?.firstName ?? " "} ${loggedInUser?.lastName ?? " "}`}</h2>

                    <img alt="confetti" src="/assets/confetti.svg" className="h-20 w-20" />
                    </div>
                    <p className="mt-6 text-gray-600 text-lg font-semibold">Thankyou for subscription. Now you can navigate to dashboard or your transactions history page by clicking on the buttons given below.</p>
                    <div className=" my-6 flex items-center justify-between">
                        <Button onClick={() => handleNavigate(NAVIGATION.INNER_NAVIGATION.DASHBOARD)}>
                            Dashboard
                        </Button>

                        <Button onClick={() => handleNavigate(NAVIGATION.INNER_NAVIGATION.PROFILE.MY_PURCHASE)}>
                            Transactions
                        </Button>
                    </div>
                </div>
            </div>
        </div>


    );
}

