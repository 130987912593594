import React from 'react'
import ReportTable from '../../../components/pages/inner/report/ReportTable'
import { useSelector } from 'react-redux';
import { selectDashboardCardData } from '../../../store/slices/api/inner/dashboardCardSlice';

const XactimateReportList = () => {
  const tabs = useSelector(selectDashboardCardData);

  return (
    <ReportTable selectedTab={tabs[2]?._id} tabs={tabs} />
  )
}

export default XactimateReportList