import { Link } from "react-router-dom";
import Button from "../Button";
import { FaFacebook, FaLinkedin, FaXTwitter } from "react-icons/fa6";
import { NAVIGATION } from "../../../lib/navigation";

export default function FooterSection({
  footerClasses = "mt-24"
}) {
  return (
    <footer className={`p-8 md:p-20 bg-slate-950 ${footerClasses}`}>
      <div className="grid grid-cols-1 md:grid-cols-8 gap-8 mx-auto">
        <div className="md:col-span-2">
          <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src="/assets/logo-white.svg" className="h-8" alt="Roof Reveal Logo" />
          </Link>

          <p className="text-gray-400 mt-6">RoofReveal is a cutting-edge SaaS application designed for efficient and accurate roofing assessments.</p>
          <div className="mt-6 text-2xl flex gap-3 text-white">
            <FaFacebook />
            <FaXTwitter />
            <FaLinkedin />
          </div>
        </div>

        <div className="md:col-span-4 flex flex-col md:flex-row justify-between">
          <div className="md:col-span-2 mb-6 md:mb-0">
            <p className="uppercase text-white font-semibold">Let’s connect</p>
            <ul className="text-gray-400 mt-6">
              <li className="mb-2">Contact and locations</li>
              <li className="mb-2">+1 444-222-9999</li>
              <li className="mb-2">info@companyname.com</li>
            </ul>
          </div>

          <div className="mb-6 md:mb-0">
            <p className="uppercase text-white font-semibold">Who we are</p>
            <ul className="text-gray-400 mt-6">
              <li className="mb-2"><Link to={NAVIGATION.ABOUT}>About us</Link></li>
              <li className="mb-2">Our team</li>
            </ul>
          </div>

          <div className="mb-6 md:mb-0">
            <p className="uppercase text-white font-semibold">Resources</p>
            <ul className="text-gray-400 mt-6">
              <li className="mb-2">Blog</li>
              <li className="mb-2">Case studies</li>
            </ul>
          </div>
        </div>

        <div className="md:col-span-2">
          <p className="uppercase text-white font-semibold">Case studies</p>
          <form className="text-gray-400 mt-6">
            <div className="relative h-11 w-full min-w-[200px]">
              <input placeholder="Email"
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100" />
              <label
                className="after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Email
              </label>
            </div>
            <Button variant="outline" className="text-gray-300 mt-6 w-full">Subscribe</Button>
          </form>
        </div>
      </div>

      <hr className="my-10 mx-auto" />
      <div className="flex flex-col md:flex-row items-center justify-between uppercase mx-auto">
        <p className="text-gray-400 mb-4 md:mb-0">@2024 Roof Reveal. ALL RIGHTS RESERVED.</p>
        <div className="flex items-center gap-4 text-white">
          <p>PRIVACY POLICY</p>
          <p>TERMS OF USE</p>
        </div>
      </div>
    </footer>
  );
}
