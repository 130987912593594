export const NAVIGATION = {

  // *public pages
  HOME: "/",
  FIND_ROOF: "/find-roof",
  ABOUT: "/about",
  FEATURES: "/features",
  CONTACT: "/contact",
  THANKS: "/subscription-completed",

  // *auth pages
  AUTH_NAVIGATION: {
    LOGIN: "/auth/login",
    SIGNUP: "/auth/sign-up",
    VERIFY_EMAIL: "/auth/verify-email",
    FORGET_PASSWORD: "/auth/forget-password",
    RESET_PASSWORD: "/auth/reset-password"
  },

  INNER_NAVIGATION: {
    
    // *inner pages
    DASHBOARD: "/inner/dashboard",
    REPORT_LIST: "/inner/report-list",
    CANVAS_REPORT_LIST: "/inner/canvas-report-list",
    EXPLORER_REPORT_LIST: "/inner/explorer-report-list",
    XACTIMATE_REPORT_LIST: "/inner/xactimate-report-list",

    // *canvas pages
    CANVAS:{
      FIND_LOCATION: "/inner/canvas/find-location",
      CANVAS_MAP: "/inner/canvas/canvas-map",
      CANVAS_DRAW: "/inner/canvas/canvas-draw",
    },
    
    // *explorer pages
    EXPLORER:{
      FIND_ROOF: "/inner/explorer/find-roof",
      EXPLORER_MAP: "/inner/explorer/explorer-map",
      EXPLORER_INFO: "/inner/explorer/explorer-info"
    },

     // *xactimate pages
    XACTIMATE: {
      XACTIMATE_INFO: "/inner/xactimate/xactimate-info" 
    },
    
    // !misc
    CANVAS_REPORT: '/inner/canvas-report',
    CANVAS_REPORT2: '/inner/canvas-report2',
    EXPLORER_REPORT: '/inner/explorer-report',

    // *subscription pages
    SUBSCRIPTION: {
      PLANS: "/inner/subscription/plan",
      CHECKOUT: "/inner/subscription/checkout"
    },

    // *profile pages
    PROFILE: {
      EDIT_PROFILE: "/inner/profile/edit-profile",
      CHANGE_PASSWORD: "/inner/profile/change-password",
      CONTACT_INFORMATION: "/inner/profile/contact-information",
      BILLING_INFORMATION: "/inner/profile/billing-information",
      MY_PURCHASE: "/inner/profile/my-purchase",
      MY_CREDIT: "/inner/profile/my-credits"
    },

    // *help pages
    HELP_CENTER: {
      MAIN: "/inner/help/help-center",
      SINGLE_HELP: "/inner/help/help-center",
      REQUEST: "/inner/help/request"
    }

  },
};