import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import osmProviders from '../../../lib/osmProviders';
import L from 'leaflet';
import markerIconSvg from '../../../lib/icons/marker.svg';
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import GeotiffLayer from './GeotiffLayer';
import { FiLoader } from 'react-icons/fi';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconUrl: markerIconSvg,
  iconRetinaUrl: markerIconSvg,
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
});

const options = {
  pixelValuesToColorFn: (values) => {
    // transforming single value into an rgba color
    const nir = values[0] || 0;

    if (nir === 0) return;
    const r = 252;
    const g = 79;
    const b = 0;
    return `rgba(${r},${g},${b}, 1)`;
  },
  resolution: 64,
  opacity: 1
};

const Map = ({ mapRef, scrollWheelZoom, children, coordinates, geoTiffUrls, isLoading=false }) => {
  return (
    <MapContainer
      center={[coordinates?.lat, coordinates?.lng]}
      zoom={18}
      scrollWheelZoom={scrollWheelZoom}
      ref={mapRef}
      zoomControl={false}
      className="h-full z-10 w-full overflow-hidden"
    >
      {isLoading && <div className='h-screen absolute w-screen backdrop-blur-sm bg-black/75 z-[999] flex items-center justify-center text-white text-xl'>
        <FiLoader className='animate-spin' />
      </div>}
      {children}
      <TileLayer attribution={osmProviders.maptiler.attribution} maxZoom={20} url={osmProviders.maptiler.url} />
      {geoTiffUrls && geoTiffUrls?.map((url, index) => (
        <GeotiffLayer key={index} url={url} options={options} />
      ))}
      {/* {geoTiffUrls && <GeotiffLayer url={geoTiffUrls} options={options} />} */}
    </MapContainer>
  );
};

export default Map;
