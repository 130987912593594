import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: [],
    imageData: [],
};

export const addReport = createAsyncThunk(
    'user/report',
    async (data) => {
        try {
            const response = await myAxios.post(`user/report`, data);
            return { success: true, message: "Report types fetched successfully!", data: response?.data?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const reportImage = createAsyncThunk(
    'user/reportImage',
    async (data) => {
        try {
            if (!data) return { success: false, message: 'Please, provide the latitude and longitude.' };;
            const response = await myAxios.post(`user/reportImage`, data);
            return { success: true, message: "Report images fetched successfully!", data: response?.data?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        clearReportData: state => {
            state.data = [];
        },
        setReportData: (state, action) => {
            state.data = action.payload;
        },
        clearReportImageData: state => {
            state.imageData = [];
        },
        setReportImageData: (state, action) => {
            state.imageData = action.payload;
        },
    },
});

export const reportReducer = reportSlice.reducer;
export const { clearReportData, setReportData, clearReportImageData, setReportImageData } = reportSlice.actions;

export const selectReportData = state => state.report.data;
export const selectReportImageData = state => state.report.imageData;
