import React from 'react'
import Section from '../../../common/public/Section'
import { MdCheck } from 'react-icons/md'

const FeatureWithPoster = ({
    title,
    image,
    list,
    gray = false,
    paddingBottom='pb-0'
}) => {
    return (
        <Section gray={gray} paddingBottom={paddingBottom}>
            <div>
                {image && <img src={image} alt='poster image for features' className='w-full' />}

                <div className='bg-[#1A1A1B] px-6 py-10 text-white'>
                    <h2 className='text-2xl font-semibold'>{title}</h2>

                    <div className='grid grid-col-1 md:grid-cols-2 gap-6 mt-6'>
                        {list?.map((item, idx) => <div key={idx} className='flex gap-2'>
                            <div>
                                <div className={`mr-2 rounded-full p-1 text-primary bg-white`}>
                                    <MdCheck />
                                </div>
                            </div>

                            <p dangerouslySetInnerHTML={{ __html: item }} />
                        </div>)}
                    </div>
                </div>
            </div>

        </Section>
    )
}

export default FeatureWithPoster