import React, { useRef, useState } from "react";
import MainHOC from "../../components/pages/public/hoc/MainHOC";
import Breadcrumb from "../../components/common/inner/Breadcrumb";
import Map from "../../components/common/inner/Map";
import { Marker, Popup } from "react-leaflet";
import Button from "../../components/common/Button";
import circle from '../../lib/icons/circle.svg'
import { useLocation, useNavigate } from "react-router-dom";
import { selectPlaceData } from "../../store/slices/api/misc/autoCompleteSlice";
import { useDispatch, useSelector } from "react-redux";
import L from 'leaflet'
import AutoComplete from "../../components/common/inner/AutoComplete";
import Headline from "../../components/common/inner/Headline";
import { NAVIGATION } from "../../lib/navigation";
import LoginModal from "../../components/pages/public/findRoof/LoginModal";
import { toggleLoginModal } from "../../store/slices/common/toggleSlice";

const mapMarker = new L.Icon({
    iconUrl: circle,
    iconSize: [41, 41],
    iconAnchor: [20, 41],
    popupAnchor: [1, -34],
})

export default function FindRoofPage() {
    const { state: address } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mapRef = useRef(null);
    const [scrollWheelZoom, setScrollWheelZoom] = useState(true);
    

    const selectedPlace = useSelector(selectPlaceData);
    const [coordinates, setCoordinates] = useState(selectedPlace?.geometry?.location ?? {
        lat: 51.505,
        lng: -0.09
    })

    function openPopup(e) {
        e.target.openPopup();
    }

    const toggleModal = async () => await dispatch(toggleLoginModal())

    return (
        <MainHOC footerClasses="mt-0">
            {address ? <div>
                        <Breadcrumb className="mt-10" crumbs={[{ title: "Find Roof", href: NAVIGATION.FIND_ROOF }, { title: address?.text2, href: "" }]} />
                <div className="relative h-screen w-full">

                    {/* map */}
                    <Map mapRef={mapRef} scrollWheelZoom={scrollWheelZoom} coordinates={coordinates}>
                        <Marker icon={mapMarker} eventHandlers={{ add: openPopup }} position={[coordinates?.lat, coordinates?.lng]}>
                            <Popup>
                                <div className="bg-white rounded-lg max-w-64 w-full">
                                    <h3 className="text-lg mt-6 mb-2 text-center font-semibold text-gray-900">
                                        Confirm this is the property you’re looking for?
                                    </h3>
                                    <div className="p-2 flex items-center gap-4">
                                        <Button type="button" onClick={toggleModal} className="w-full">
                                            Yes
                                        </Button>
                                        <Button type="button" onClick={() => navigate('/')} className="w-full" variant="outline">
                                            No
                                        </Button>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    </Map>
                </div>
            </div> : <div>
                <Breadcrumb crumbs={[{ title: "Find Roof", href: NAVIGATION.FIND_ROOF }]} />
                <div className='mb-20 px-4 py-20 w-full max-w-2xl mx-auto'>
                    <div className="bg-neutral-50 text-zinc-900 w-full border border-solid shadow-sm border-stone-300 rounded-xl px-3 md:px-16 py-8">
                        <Headline title="Find Your Roof" desc="Discovering Roofing Excellence: Begin Your Journey To Simplifying Your Business Processes." />
                        <AutoComplete pathToRedirect={NAVIGATION.FIND_ROOF} />
                    </div>
                </div>
            </div>}

            <LoginModal toggleModal={toggleModal} />
        </MainHOC>
    );
}


