import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: [],
    history: [],
};

export const fetchMyPurchase = createAsyncThunk(
    'user/myPurchase',
    async (query) => {
        try {
            const response = await myAxios.get(`user/mypurchase`, {
                params: {
                    ...query
                }
            });
            return { success: true, message: "Purchased data fetched successfully!", data: response?.data?.data?.purchase };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const fetchMyCreditHistory = createAsyncThunk(
    'user/myHistory',
    async (query) => {
        try {
            const response = await myAxios.get(`user/history`, {
                params: {
                    ...query
                }
            });
            return { success: true, message: "Credit history fetched successfully!", data: response?.data?.data?.history };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);


const myPurchaseSlice = createSlice({
    name: 'myPurchase',
    initialState,
    reducers: {
        clearMyPurchaseData: state => {
            state.data = [];
        },
        setMyPurchaseData: (state, action) => {
            state.data = action.payload;
        },
        setMyPurchaseDataWithPrev: (state, action) => {
            state.data = [...state?.data, ...action.payload];
        },
        clearMyCreditData: state => {
            state.history = [];
        },
        setMyCreditData: (state, action) => {
            state.history = action.payload;
        },
        setMyCreditDataWithPrev: (state, action) => {
            state.history = [...state?.history, ...action.payload];
        },
    },
});

export const myPurchaseReducer = myPurchaseSlice.reducer;
export const { clearMyPurchaseData, setMyPurchaseData,setMyPurchaseDataWithPrev, clearMyCreditData, setMyCreditData, setMyCreditDataWithPrev } = myPurchaseSlice.actions;

export const selectMyPurchaseData = (state) => state.myPurchase.data;
export const selectMyCreditData = (state) => state.myPurchase.history;
