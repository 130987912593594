import { cva } from 'class-variance-authority'
import React from 'react'
import { cn } from '../../lib/cn'
import { FiLoader } from 'react-icons/fi'

const buttonVariants = cva(
    "flex gap-2 items-center uppercase justify-center rounded-full disabled:pointer-events-none disabled:opacity-50 px-8 font-custom-bold leading-[1.2em]",
    {
        variants: {
            variant: {
                default: "bg-primary text-white border-none outline-none",
                dark:
                    "bg-[#1A1A1B] text-white",
                outline:
                    "bg-transparent text-black border-border",
                outlinePrimary: "bg-transparent text-primary border-primary",
                outlineLight: "bg-transparent text-white border-white",
                link: "text-primary underline-offset-4 hover:underline",
            },
            size: {
                default: "py-2 border text-xs md:py-3 md:text-md md:border-2 md:font-medium",
                small: "py-2 border text-xs",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default"
        },

    }
)

const Button = ({ isLoading, children, variant, className, onClick, size = "default", type = "submit", loader = true, disabled=false }) => {
    return (
        <button type={type} onClick={onClick} disabled={disabled || isLoading} className={cn(buttonVariants({ variant, size, className }))}>
            {(loader && isLoading) && <FiLoader className={`animate-spin ${size === "default" && 'text-xl'}`} />}
            {children}
        </button>
    )
}

export default Button