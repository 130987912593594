import React from 'react'
import InnerHeader from '../../../common/inner/InnerHeader'
import Breadcrumb from '../../../common/inner/Breadcrumb'
import { MdModeEdit, MdOutlinePassword, MdOutlinePostAdd, MdOutlineRequestPage, MdCreditCard, MdOutlineDeleteForever } from "react-icons/md";
import { Link, useLocation } from 'react-router-dom';
import { NAVIGATION } from '../../../../lib/navigation';
import { selectLoggedInData } from '../../../../store/slices/api/auth/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import LogoutModal from '../profile/LogoutModal';
import DeleteProfileModal from '../profile/DeleteProfileModal';
import { toggleDeleteProfileModal } from '../../../../store/slices/common/toggleSlice';
import { FaHistory } from 'react-icons/fa';

const links = [
    { label: "Edit Profile", type: "link", icon: <MdModeEdit />, href: NAVIGATION.INNER_NAVIGATION.PROFILE.EDIT_PROFILE },
    { label: "Change Password", type: "link", icon: <MdOutlinePassword />, href: NAVIGATION.INNER_NAVIGATION.PROFILE.CHANGE_PASSWORD },
    { label: "Contact Information", type: "link", icon: <MdOutlinePostAdd />, href: NAVIGATION.INNER_NAVIGATION.PROFILE.CONTACT_INFORMATION },
    { label: "Billing Information", type: "link", icon: <MdOutlineRequestPage />, href: NAVIGATION.INNER_NAVIGATION.PROFILE.BILLING_INFORMATION },
    { label: "My Purchase", type: "link", icon: <MdCreditCard />, href: NAVIGATION.INNER_NAVIGATION.PROFILE.MY_PURCHASE },
    { label: "My Credits", type: "link", icon: <FaHistory />, href: NAVIGATION.INNER_NAVIGATION.PROFILE.MY_CREDIT },
    { label: "Delete Account", type: "delete", icon: <MdOutlineDeleteForever /> },
];

const ProfileHOC = ({ title, action, children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const loggedInUser = useSelector(selectLoggedInData);

    const toggleDeleteProfile = () => dispatch(toggleDeleteProfileModal());

    return (
        <div>
            <InnerHeader />
            <Breadcrumb crumbs={[{ title: "user profile" }]} />

            <div className='md:px-24 w-full max-w-screen-2xl mx-auto'>
                <div className="h-24 my-8 md:my-16 flex flex-col md:flex-row items-center justify-between">
                    <div className='flex items-center gap-6'>
                        <div className="flex justify-center items-center w-20 h-20 text-white text-4xl font-bold bg-primary rounded-[50px]">
                            {loggedInUser?.firstName?.charAt(0)?.toUpperCase()}
                        </div>
                        <div>
                            <h1 className='text-2xl font-semibold'>{`${loggedInUser?.firstName ?? " "} ${loggedInUser?.lastName ?? " "}`}</h1>
                            <h4>{loggedInUser?.email}</h4>
                        </div>
                    </div>

                    <div className="h-24 min-w-56 px-8 py-3 md:py-0 flex flex-col justify-center border border-gray-200 rounded-md mt-6 md:mt-0">
                        <h4 className='text-gray-500'>Subscription</h4>
                        {loggedInUser?.subscriptionName ? <h1 className='text-2xl font-semibold uppercase'>{loggedInUser?.subscriptionName}</h1> : <Link to={NAVIGATION.INNER_NAVIGATION.SUBSCRIPTION.PLANS} className='text-primary'>No Active Subscription</Link>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row mt-32 md:mt-0">
                    <div className='w-full md:w-64 mb-6 md:mb-0'>
                        <aside className="sticky top-10">
                            <div className="h-full overflow-y-auto">
                                <ul className="space-y-2 font-medium">
                                    {links.map((item, idx) => (
                                        <li key={idx}>
                                            {item.type === "link" ? (
                                                <Link to={item.href} className={`flex items-center p-2 text-gray-900 rounded-lg ${location.pathname === item.href ? "bg-primary/5 group text-primary" : ""}`}>
                                                    {item.icon}
                                                    <span className="flex-1 ms-3 whitespace-nowrap">{item.label}</span>
                                                </Link>
                                            ) : (
                                                // <button onClick={item.type === "delete" ? toggleDeleteProfile : () => { }} className={`flex items-center p-2 text-red-600 rounded-lg`}>
                                                //     {item.icon}
                                                //     <span className="flex-1 ms-3 whitespace-nowrap">{item.label}</span>
                                                // </button>

                                                null
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </aside>
                    </div>
                    <div className='w-full pl-4 pe-4 md:pe-0 md:pl-16'>
                        <div className='flex items-center justify-between'>
                            <h1 className='text-2xl font-medium'>{title}</h1>
                            {action && action}
                        </div>
                        <div>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            <LogoutModal />
            <DeleteProfileModal toggleModal={toggleDeleteProfile} />
        </div>
    )
}

export default ProfileHOC;
