import React from 'react'
import { useSelector } from 'react-redux'
import Button from '../../../common/Button'
import ModalHOC from '../hoc/ModalHOC'


const DeleteProfileModal = ({toggleModal}) => {
    const deleteProfileModal = useSelector(state => state.toggle.deleteProfileModal)

    return (
        <ModalHOC open={deleteProfileModal} toggleModal={toggleModal} title="Are you sure you want to delete your profile?" maxWidth='max-w-xs'>
            <div className='flex items-center justify-between gap-2'>
                <Button className="w-full" variant="outline" onClick={toggleModal}>
                    No
                </Button>
                <Button className="w-full" type='button' onClick={() => console.log('delete profile will be implemented!')}>
                    yes
                </Button>
            </div>
        </ModalHOC>
    )
}

export default DeleteProfileModal