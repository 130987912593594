import React, { useEffect, useRef, useState } from "react";
import DashboardHOC from "../../../components/pages/inner/hoc/DashboardHOC";
import Breadcrumb from "../../../components/common/inner/Breadcrumb";
import { NAVIGATION } from "../../../lib/navigation";
import Map from "../../../components/common/inner/Map";
import { Marker, Popup } from "react-leaflet";
import Button from "../../../components/common/Button";
import circle from '../../../lib/icons/circle.svg';
import L from 'leaflet';
import { useDispatch, useSelector } from "react-redux";
import { fetchMapSeg, selectMapSegData, selectPlaceData, setMapSeg } from "../../../store/slices/api/misc/autoCompleteSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectLoggedInData } from "../../../store/slices/api/auth/loginSlice";
import LowCreditWarning from "../../../components/common/inner/LowCreditWarning";
import { setSelectedCoordinates } from "../../../store/slices/common/selectedCoordinatesSlice";
import useApiRequest from "../../../store/slices/hook/useApiRequest";
import { FiLoader } from "react-icons/fi";

const mapMarker = new L.Icon({
  iconUrl: circle,
  iconSize: [41, 41],
  iconAnchor: [20, 41],
  popupAnchor: [1, -34],
});

export default function ExplorerMap() {
  const { state: address } = useLocation();
  const localLat = localStorage.getItem("selectedLat") ?? null;
  const localLng = localStorage.getItem("selectedLng") ?? null;
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const [scrollWheelZoom, setScrollWheelZoom] = useState(true);
  const selectedReportType = useSelector((state) => state?.selectedReportType?.value);
  const loggedInUser = useSelector(selectLoggedInData);
  const selectedPlace = useSelector(selectPlaceData);
  const selectedMapSeg = useSelector(selectMapSegData);
  const [coordinates, setCoordinates] = useState(selectedPlace?.geometry?.location ?? {
    lat: localLat,
    lng: localLng
  });

  const { isLoading: mapSegLoading, handleSubmit: handleMapSeg, error: mapSegError } = useApiRequest({
    apiAction: fetchMapSeg,
    setValueAction: setMapSeg,
    isForm: false,
    successMessage: false,
    nullOnError: true,
  });

  const handleMapSegFN = async (latitude, longitude) => {
    await handleMapSeg({
      latitude,
      longitude
    });
  }

  function openPopup(e) {
    e.target.openPopup();
  }

  const handleYesClick = () => {
    navigate(`${NAVIGATION.INNER_NAVIGATION.EXPLORER.EXPLORER_INFO}/${params?.reportId}`);
  };

  const handleMarkerDragEnd = (event) => {
    const { lat, lng } = event.target.getLatLng();

    setCoordinates({ lat, lng });
    dispatch(setSelectedCoordinates({ lat, lng }))
    handleMapSegFN(lat, lng)
  };

  useEffect(() => {
    setCoordinates(selectedPlace?.geometry?.location ?? {
      lat: localLat,
      lng: localLng
    })
    dispatch(setSelectedCoordinates(selectedPlace?.geometry?.location ?? {
      lat: localLat,
      lng: localLng
    }))

    handleMapSegFN(selectedPlace?.geometry?.location?.lat ?? localLat, selectedPlace?.geometry?.location?.lng ?? localLng)
  }, []);

  useEffect(() => {
    if ("geolocation" in navigator && (coordinates.lat === null || coordinates.lng === null)) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          dispatch(setSelectedCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }))
          handleMapSegFN(position.coords.latitude, position.coords.longitude)

        },
        (error) => {
          console.log("Error getting geolocation: ", error);
          setCoordinates({
            lat: localLat ?? 51.505,
            lng: localLng ?? -0.09,
          });
          dispatch(setSelectedCoordinates({
            lat: localLat ?? 51.505,
            lng: localLng ?? -0.09,
          }))
        }
      );
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, [coordinates.lat, coordinates.lng]);

  return (
    <DashboardHOC>
      <Breadcrumb crumbs={[{ title: "Explorer Report", href: `${NAVIGATION.INNER_NAVIGATION.EXPLORER.FIND_ROOF}/${params?.reportId}` }, { title: selectedMapSeg?.result?.formatted_address ?? address?.text2, href: "" }]} />
      <div className="relative h-screen w-full">
        {(coordinates && coordinates?.lat !== null) && <Map mapRef={mapRef} scrollWheelZoom={scrollWheelZoom} coordinates={coordinates}>
          {loggedInUser?.subscriptionCredits < selectedReportType?.credits && <LowCreditWarning map={true} />}
          <Marker
            icon={mapMarker}
            eventHandlers={{ add: openPopup, dragend: handleMarkerDragEnd }}
            position={[coordinates.lat, coordinates.lng]}
            draggable={true}
          >
            <Popup>
              {selectedMapSeg ? <div className="bg-white rounded-lg max-w-64 w-full">
                <h3 className="text-lg mt-6 mb-2 text-center font-semibold text-gray-900">
                  Confirm this is the property you’re looking for?
                </h3>
                <div className="p-2 flex items-center gap-4">
                  <Button type="button" isLoading={mapSegLoading} onClick={handleYesClick} className="w-full">
                    Yes
                  </Button>
                  <Button type="button" onClick={() => { }} className="w-full" variant="outline">
                    No
                  </Button>
                </div>
              </div> : <div className="bg-white rounded-lg max-w-64 w-full">
                <h3 className="text-lg mt-6 mb-2 text-center font-semibold text-gray-900">
                  {mapSegLoading ? <div className="flex items-center gap-2"><FiLoader className={`animate-spin`} /> Fetching details</div> : mapSegError ? mapSegError : "We cant generate report for this particular location"}
                </h3>
              </div>}
            </Popup>
          </Marker>
        </Map>}
      </div>
    </DashboardHOC>
  );
}
