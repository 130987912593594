import React, { useEffect } from "react";
import DashboardHOC from "../../components/pages/inner/hoc/DashboardHOC";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardCard, selectDashboardCardData, setDashboardCardData } from "../../store/slices/api/inner/dashboardCardSlice";
import useApiRequest from "../../store/slices/hook/useApiRequest";
import { setSelectedReportType } from "../../store/slices/common/selectedReportTypeSlice";
import MailVerificationWarning from "../../components/common/inner/MailVerificationWarning";

export default function Dashboard() {
  const cards = useSelector(selectDashboardCardData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, handleSubmit: fetchData } = useApiRequest({
    apiAction: fetchDashboardCard,
    setValueAction: setDashboardCardData,
    successMessage: false,
    isForm: false,
  });
  const isEmailVerified = localStorage.getItem('isEmailVerify') === 'true';

  useEffect(() => {
    fetchData()
  }, [])

  const handleClick = (card) => {
    dispatch(setSelectedReportType(card))
    navigate(`${card.href}/${card._id}`);
  }

  return (
    <DashboardHOC>
      {!isEmailVerified && <MailVerificationWarning />}
      <div className="h-full md:h-[78vh] w-full px-6 md:px-0 pb-10 md:pb-0">
        <div className="h-full flex flex-col md:flex-row gap-6 items-center justify-center">
          {cards.map((card) => <div key={card._id} className="flex flex-col w-full h-80 md:w-80">
            <div className="flex overflow-hidden relative flex-col grow justify-between p-2.5 uppercase rounded-3xl shadow-md  max-md:mt-10">
              <img
                loading="lazy"
                src={card.image}
                alt={card.title}
                className="object-cover absolute inset-0 size-full"
              />
              <div className={`relative justify-center items-center text-center px-8 py-6 font-black tracking-widest text-white rounded-2xl shadow-sm max-md:px-5 ${card.background === 'bg-primary' ? 'bg-primary' : card.background === 'bg-[#084FDA]' ? 'bg-[#084FDA]' : 'bg-[#2A8414]'}`}>
                {card.title}
              </div>
              <div onClick={() => handleClick(card)} className="cursor-pointer relative justify-center mt-10 self-center p-2.5 text-sm tracking-wider text-black bg-white shadow-sm rounded-[35px] max-md:px-5">
                {card.buttonLabel}
              </div>
            </div>
          </div>)}
        </div>
      </div>
    </DashboardHOC>
  );
}

