import React, { useEffect, useState } from 'react'
import DashboardHOC from '../../../components/pages/inner/hoc/DashboardHOC'
import Breadcrumb from '../../../components/common/inner/Breadcrumb'
import Headline from '../../../components/common/inner/Headline'
import Button from '../../../components/common/Button'
import { MdCheck } from "react-icons/md";
import { useNavigate } from 'react-router-dom'
import { NAVIGATION } from '../../../lib/navigation'
import { useDispatch, useSelector } from 'react-redux'
import useApiRequest from '../../../store/slices/hook/useApiRequest'
import { fetchPlan, fetchSubscribeInitData, selectPlanData, setPlanData, setSubscribeInitDtaData } from '../../../store/slices/api/inner/planSlice'
import { setSelectedPlan } from '../../../store/slices/common/selectedPlanSlice'

const Plans = () => {
    const cards = useSelector(selectPlanData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [type, setType] = useState('month')

    const afterAPICall = () => {
        navigate(NAVIGATION.INNER_NAVIGATION.SUBSCRIPTION.CHECKOUT);
    }

    const { handleSubmit: fetchData } = useApiRequest({
        apiAction: fetchPlan,
        setValueAction: setPlanData,
        successMessage: false,
        isForm: false,
    });
    const { handleSubmit: fetchSubscribeInit } = useApiRequest({
        apiAction: fetchSubscribeInitData,
        setValueAction: setSubscribeInitDtaData,
        successMessage: false,
        isForm: false,
        afterAPICall,
    });

    const handleClick = (data) => {
        fetchSubscribeInit({
            subscriptionName: data.subscriptionName,
            subPrice: data.subscriptionPrice,
            type: data.type,
            deviceType: "WEB"
        });
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <DashboardHOC>

            <Breadcrumb crumbs={[{ title: "Subscription", href: "" }]} />
            <div className='px-4 py-20 w-full max-w-5xl mx-auto'>
                <div className="w-full pt-8">
                    <Headline title="Choose the credits <br/> that fits your needs" desc="" />
                    <div className="container px-0 md:px-5 py-8 mx-auto">
                        <div className="flex flex-col text-center w-full mb-7">
                            <div className="flex mx-auto border border-primary/30 p-1 rounded-lg overflow-hidden mt-6">
                                <button type='button' onClick={() => setType('month')} className={`${type === 'month' ? "bg-primary text-white" : "text-zinc-900"} py-2 px-4 focus:outline-none rounded-lg`}>Monthly</button>
                                <button type='button' onClick={() => setType('year')} className={`${type === 'year' ? "bg-primary text-white" : "text-zinc-900"} py-2 px-4 focus:outline-none rounded-lg`}>Annually</button>
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-10">
                            {cards?.map(card => card?.type === type && <div key={card._id} className={`p-4 md:w-1/3 w-full`}>
                                <div className={`h-full p-6 rounded-2xl border-2 ${card.isPopular ? 'border-primary' : 'border-gray-300'} flex flex-col relative overflow-hidden`}>
                                    <span className='mb-4'>
                                        <span className={`rounded-full px-4 font-custom-bold leading-[1.2em] bg-transparent border ${card.isPopular ? 'text-primary border-primary/30' : 'text-black'} py-2 text-sm`}>
                                            {card.subscriptionName}
                                        </span>
                                    </span>
                                    <div className='pb-4 mb-4 border-b border-gray-200'>
                                        <h1 className="text-4xl font-semibold text-gray-900 leading-none flex items-center">
                                            <span>${card.subscriptionPrice}</span>
                                            <span className="text-lg ml-1 font-normal text-gray-500">/mo</span>
                                        </h1>
                                        <p className='text-sm text-gray-400 mt-2'>{card.credits} Credits</p>
                                    </div>
                                    <div className='mb-5'>
                                        {card?.description?.map((desc, idx) => <p key={idx} className="flex items-center text-gray-600 mb-2">
                                            <span className={`mr-2 rounded-full p-1 ${card.isPopular ? 'text-primary bg-primary/10' : 'bg-gray-200'}`}>
                                                <MdCheck />
                                            </span> {desc}
                                        </p>)}
                                    </div>

                                    <Button type='button' onClick={() => (dispatch(setSelectedPlan(card)), handleClick(card))} variant={card.isPopular ? 'default' : 'dark'} className="mt-auto">
                                        Choose Plan
                                    </Button>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>

        </DashboardHOC>
    )
}

export default Plans