import React, { useRef, useState } from "react";
import MapTopBar from "../../components/common/inner/MapTopBar";
import { useDispatch, useSelector } from "react-redux";
import CanvasCreditsModal from "../../components/pages/inner/canvas/CanvasCreditsModal";
import ReportHOC, { ReportTitle } from "../../components/pages/inner/hoc/ReportHOC";
import { useReactToPrint } from "react-to-print";
import { useLocation, useParams } from "react-router-dom";
import { selectLoggedInData } from "../../store/slices/api/auth/loginSlice";
import { clearSelectedArea } from "../../store/slices/common/selectedAreaSlice";
import { clearPlaceData, clearSolarData } from "../../store/slices/api/misc/autoCompleteSlice";
import { toggleCanvasModal } from "../../store/slices/common/toggleSlice";
import AddressLink from "./AddressLink";
import ClientDetails from "./ClientDetails";
import { selectReportImageData } from "../../store/slices/api/inner/reportSlice";
import html2pdf from "html2pdf.js";
import { toast } from "react-toastify";

export default function CanvasReport2() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const loggedInData = useSelector(selectLoggedInData)
  const [isDownloadLoading, setDownloadLoading]  = useState(false);
  const params = useParams();

  const toggleModal = async () => await dispatch(toggleCanvasModal());
  const reportData = useSelector(selectReportImageData)

  const pdfRef = useRef(null);

  const handleGeneratePDF = () => {
    const element = pdfRef.current;
    setDownloadLoading(true)
    html2pdf()
      .from(element)
      .set({
        margin: [0, 0],
        filename: `${reportData?.buildingName}-canvasing.pdf`,
        html2canvas: { scale: 2, useCORS: true, allowTaint: true },
        jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4' },
        compressPDF: true,
      })
      .save()
      .then(() => {
        dispatch(clearSelectedArea());
        dispatch(clearSolarData());
        dispatch(clearPlaceData());
        toast.success("report downloaded successfully!");
        setDownloadLoading(false)
      })
      .catch((error) => {
        console.error("Error during PDF generation:", error);
        toast.error("Sorry, something went wrong!");
        setDownloadLoading(false)
      });
  };


  const printPDF = useReactToPrint({
    content: () => pdfRef?.current,
  });

  const handlePrintPDF = async () => {
    await printPDF();
    dispatch(clearSelectedArea());
    dispatch(clearSolarData());
    dispatch(clearPlaceData());
  };

  return (
    <section>
      <MapTopBar head="Canvasing Report" designLink={state?.designLink} reportLink={state?.reportLink} title="Canvasing Report" toggleModal={toggleModal} handleDownload={handleGeneratePDF} isDownloadLoading={isDownloadLoading} handlePrint={handlePrintPDF} />

      <div className="py-20 bg-neutral-50">
        <div ref={pdfRef} className="flex flex-col justify-center items-center max-md:px-5">
          <ReportHOC head="Canvasing Report" pageNumber={1}>
            <div className='w-full mx-auto mt-6 h-[500px] overflow-hidden relative'>
              <img
                loading="lazy"
                src={reportData?.file ?? "/assets/placeholder.jpg"}
                className="w-full h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover"
                alt="Description"
              />
            </div>

            <div className="flex justify-between gap-6 mt-6">
              <div className="w-1/2">
                <ReportTitle title="Prepared for" />
                <ClientDetails loggedInData={loggedInData} />
              </div>
              <div className="w-1/2">
                <ReportTitle title="Table of Contents" />
                <div className="mt-5">
                  <ul className="leaders">
                    <li><span>Roof details</span> <span>2</span></li>
                    <li><span>Online maps</span> <span>3</span></li>
                    <li><span>Important legal notice & disclaimer</span> <span>4</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </ReportHOC>
          <ReportHOC head="Canvasing Report" pageNumber={2} title="Roof Details">
            <div className="flex flex-col justify-center pb-6 mt-6 leading-5 border border-solid border-zinc-200 text-zinc-900 max-md:max-w-full">
              <div className='w-full mx-auto mt-6 h-[500px] overflow-hidden relative'>
                <img
                  loading="lazy"
                  src={reportData?.file ?? "/assets/placeholder.jpg"}
                  className="w-full h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover"
                  alt="Description"
                />
              </div>
              <div className="mx-8 mt-8 max-md:max-w-full">
                Roof Type: 3 Tab Shingle
              </div>
              <div className="mx-8 mt-4 max-md:max-w-full">
                Roof Age: 15 Years
              </div>
              <div className="mx-8 mt-4 max-md:max-w-full">
                {reportData?.buildingName}, {reportData?.address}
              </div>
            </div>
          </ReportHOC>
          <ReportHOC head="Canvasing Report" pageNumber={3} title="Online Maps">
            <AddressLink from={"Premier Choice Exteriors, 4580 Mack Ave Suite G, Frederick, MD 21703, United States"} to={reportData?.buildingName} />
          </ReportHOC>
          <ReportHOC head="Canvasing Report" pageNumber={4} title="IMPORTANT LEGAL NOTICE & DISCLAIMER">
            <div className="mt-6 text-xs font-semibold leading-5 text-zinc-900 max-md:max-w-full">
              Notice and Disclaimer
            </div>
            <div className="mt-3 text-xs leading-5 text-zinc-900 max-md:max-w-full">
              No Warranty: The Copyrighted Materials are provided to you "as is," and you agree to use it at your own risk.
            </div>
            <div className="mt-3 text-xs leading-5 text-zinc-900 max-md:max-w-full">
              RoofRavel Technologies makes no guarantees, representations or warranties of any kind, express or implied, arising by law or otherwise, including but not limited to, content, quality, accuracy, completeness, effectiveness, reliability, fitness for a particular purpose, usefulness, use or results to be obtained from the Copyrighted Materials.
            </div>
            <div className="mt-3 text-xs leading-5 text-zinc-900 max-md:max-w-full">
              Contractors agree to always conduct a preliminary site survey to verify Roof Report ordered. In the event of an error in a Report, your sole remedy will be a refund of the fees paid by you to obtain this Report.
            </div>
          </ReportHOC>
        </div>
      </div>

      <CanvasCreditsModal toggleModal={toggleModal} />
    </section>
  );
}
