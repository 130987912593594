import * as React from "react";
import LoginForm from "../../components/pages/auth/LoginForm";


export default function LoginPage() {


    return (
        <div className="w-full pt-32">
            <div className='w-full max-w-md mb-16 mx-auto px-6'>
                <LoginForm />
            </div>
        </div>
    );
}

