import React from 'react'
import InfoCustomerContact from './InfoCustomerContact'
import InfoAddress from './InfoAddress'
import InfoReportSummery from './InfoReportSummery'

const InfoForm = ({
    control,
    activeIndices,
    toggleAccordion,
}) => {
    return (
        <div>
            {/* customer contact */}
            <InfoCustomerContact control={control} activeIndices={activeIndices} toggleAccordion={toggleAccordion}/>

            {/* address */}
            <InfoAddress control={control} activeIndices={activeIndices} toggleAccordion={toggleAccordion}/>

            {/* report summary */}
            <InfoReportSummery control={control} activeIndices={activeIndices} toggleAccordion={toggleAccordion}/>
        </div>
    )
}

export default InfoForm