import { useEffect, useRef } from "react";
import proj4 from "proj4";
import { useLeafletContext } from "@react-leaflet/core";
import { useMap } from "react-leaflet";
import parseGeoraster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import { useDispatch } from "react-redux";
import { setMapLoading } from "../../../store/slices/api/misc/autoCompleteSlice";

window.proj4 = proj4;

const GeotiffLayer = ({ url, options }) => {
  const geoTiffLayerRef = useRef(null);
  const context = useLeafletContext();
  const map = useMap();
  const apiKey = "AIzaSyDT\_BK45Q2Vl7lPDpyPIjylGbFXzQY6Z1g";
  const solarUrl = url.includes('solar.googleapis.com') ? `${url}&key=${apiKey}` : url;
  const dispatch = useDispatch();

  useEffect(() => {
    const container = context.layerContainer || context.map;

    fetch(solarUrl)
      .then((response) => {
        if (!response.ok) {
          dispatch(setMapLoading(false))
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => parseGeoraster(arrayBuffer))
      .then((georaster) => {
        if (georaster) {
          options.georaster = georaster;
          const layer = new GeoRasterLayer(options);
          geoTiffLayerRef.current = layer;
          container.addLayer(layer);

          const bounds = layer.getBounds();
          if (bounds.isValid()) {
            map.fitBounds(bounds);
          } else {
            console.warn("GeoRasterLayer has invalid bounds:", bounds);
          }
        } else {
          console.error("Parsed georaster is undefined or null.");
        }
        dispatch(setMapLoading(false))
      })
      .catch((error) => {
        console.log("Error loading or parsing GeoTIFF:", error);
        dispatch(setMapLoading(false))
      })

    return () => {
      if (geoTiffLayerRef.current) {
        container.removeLayer(geoTiffLayerRef.current);
      }
    };
  }, [context, solarUrl, map, options]);

  return null;
};

export default GeotiffLayer;
