import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: [],
    subscribeInitData: null,
};

export const fetchPlan = createAsyncThunk(
    'user/plan',
    async (query) => {
        try {
            const response = await myAxios.get(`user/subscription`);
            return { success: true, message: "Plans fetched successfully!", data: response?.data?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const cancelPlan = createAsyncThunk(
    'user/cancelPlan',
    async (query) => {
        try {
            const response = await myAxios.put(`user/subscription`);
            return { success: true, message: "Plans fetched successfully!", data: response?.data?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const fetchSubscribeInitData = createAsyncThunk(
    'user/subscriptionUrl',
    async (data) => {
        try {
            const response = await myAxios.post(`user/subscription`, data);
            return { success: true, message: "subscription initialized successfully!", data: response?.data?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

const planSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        clearPlanData: state => {
            state.data = [];
        },
        setPlanData: (state, action) => {
            state.data = action.payload;
        },
        clearSubscribeInitDtaData: state => {
            state.subscribeInitData = [];
        },
        setSubscribeInitDtaData: (state, action) => {
            state.subscribeInitData = action.payload;
        },
    },
});

export const planReducer = planSlice.reducer;
export const { clearPlanData, setPlanData, clearSubscribeInitDtaData, setSubscribeInitDtaData } = planSlice.actions;

export const selectPlanData = (state) => state.plan.data;
export const selectSubscribeInitData = (state) => state.plan.subscribeInitData;
