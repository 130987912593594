import React, { useRef, useState } from 'react'
import MainHOC from '../../components/pages/public/hoc/MainHOC';
import DescSection from '../../components/common/public/DescSection';
import SectionHeadline from '../../components/common/public/SectionHeadline';
import FeatureWithPoster from '../../components/pages/public/feature/FeatureWithPoster';

const feature1 = [
    "<strong>AI-Powered Targeting:</strong> Utilize the latest in artificial intelligence to identify homes with roofing needs within specifies locations.",
    "<strong>Google Maps Integration:</strong> Seamlessly navigate neighborhoods and visually assess roofs, ensuring accurate targeting and efficient lead generation.",
    "<strong>Comprehensive Criteria Matching:</strong> Define specific criteria such as roof type, or other customizable factors to refine your lead selection.",
    "<strong>Effortless Outreach:</strong> With a user-friendly interface, effortlessly plan and execute your canvassing strategy, enhancing your sales team's efficiency and eliminate the cost and headache of using canvassers."
];

const feature2 = [
    "<strong>Automated Measurement Precision:</strong> Roof Reveal's state-of-the-art AI seamlessly calculates and compiles all necessary roofing measurements with unparalleled accuracy, ensuring your material orders are precise and tailored to each unique project.",
    "<strong>Comprehensive Reports:</strong> Say goodbye to manual calculations and time-consuming measurements. Our tool generates detailed reports that encompass every aspect of the roof, from dimensions to pitch, providing a comprehensive overview for streamlined material ordering.",
    "<strong>Effortless Material Planning:</strong> With the detailed insights provided by our reports, plan your material orders with confidence. Our tool eliminates guesswork, allowing you to optimize your inventory and reduce waste.",
    "<strong>Customizable Parameters:</strong> Tailor your measurements based on specific project requirements. Whether it's roofing materials, insulation, or any other component, Roof Reveal's flexibility ensures a perfect fit for your needs.",
    "<strong>Integration with Suppliers:</strong> Seamlessly share your detailed reports with suppliers, fostering efficient communication and ensuring a smooth material procurement process."
];

const feature3 = [
    "<strong>Instant Estimate Generation:</strong> Roof Reveal's Automated Estimation Module utilizes the detailed measurements provided by our advanced tool to instantly generate comprehensive and precise estimates for your roofing projects.",
    "<strong>Tailored Pricing:</strong> Your estimates will be customized based on various factors, such as location, existing site conditions, and market conditions. Roof Reveal ensures that your Estimates are not just quick but also reflect the unique requirements of each project.",
    "<strong>Real-time Adjustments:</strong> Make on-the-fly adjustments to your estimates as project details evolve. Roof Reveal's flexibility allows you to adapt to changing circumstances while maintaining accuracy in your pricing.",
    "<strong>Customer-Friendly Interface:</strong> Impress your clients with transparent and professional estimates. Roof Reveal's user-friendly interface allows you to present detailed breakdowns of costs, building trust and confidence with your customers.",
    "<strong>Efficient Communication:</strong> Share estimates effortlessly with your customers, promoting clear communication and ensuring that everyone is on the same page regarding project costs and expectations."
]

export default function FeaturesPage() {
    const [activeTab, setActiveTab] = useState("canvasing");
    const canvasingRef = useRef(null);
    const measurementRef = useRef(null);
    const estimationRef = useRef(null);
    const orderingRef = useRef(null);

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleTabClick = (tabName, ref) => {
        setActiveTab(tabName);
        scrollToRef(ref);
    };
    return (
        <MainHOC>
            <div className="h-screen px-6 md:px-20  w-full bg-cover bg-center flex flex-col justify-center" style={{
                backgroundImage: "url(/assets/bg/services.png)",
            }}>
                <div className='w-full max-w-xl'>
                    <SectionHeadline light={true} title="Features" desc="Using AI to Simplify Your Processes" />
                    <p className='mt-10 text-white'>Effortlessly access precise roofing measurements, valuable insights, and streamlined processes with our platform. Utilizing Artificial Intelligence, our innovative solutions automate tasks, enhance efficiency, and provide intelligent insights, ensuring your operations are more effective, saving both time and resources. Embrace the future of roofing projects with our AI-driven approach.</p>
                </div>

            </div>

            <div className="flex justify-center items-center py-10 w-full text-sm font-semibold uppercase bg-white border-b border-solid border-zinc-200 text-gray-600 max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col md:flex-row gap-5 justify-center items-center">
                    <div className={`cursor-pointer ${activeTab === "overview" ? "text-primary" : ""}`}
                        onClick={() => handleTabClick("overview", canvasingRef)}>01. Canvasing Tool</div>
                    <div className={`cursor-pointer ${activeTab === "history" ? "text-primary" : ""}`}
                        onClick={() => handleTabClick("history", measurementRef)}>02. Advanced Measurement</div>
                    <div className={`cursor-pointer ${activeTab === "mission" ? "text-primary" : ""}`}
                        onClick={() => handleTabClick("mission", estimationRef)}>03. Automated Estimation</div>
                    <div className={`cursor-pointer ${activeTab === "vision" ? "text-primary" : ""}`}
                        onClick={() => handleTabClick("vision", orderingRef)}
                    >04. Automated Estimation</div>
                </div>
            </div>

            <section ref={canvasingRef}>
                <DescSection title="Canvasing Tool" desc="Roof Reveal's Canvassing Tool" >
                    <p>
                        Canvasing Tool - Roof Reveal's Canvassing Tool is an intelligent and dynamic solution that revolutionizes the way homes are identified and targeted for potential roofing projects. Powered by cutting-edge AI technology, this tool harnesses the vast expanse of Google Maps to meticulously sift through neighborhoods and pinpoint homes that align with the specific criteria of your potential customers.
                    </p><br />
                    <p>Our Canvassing Tool goes beyond traditional methods, offering a seamless and automated approach to lead generation for roofing professionals. It intelligently analyzes geographic and demographic data, ensuring that your outreach efforts are precisely tailored to homes that are more likely to require roofing services.</p>
                </DescSection>
            </section>

            <section>
                <FeatureWithPoster image="/assets/feature/banner.png" title="Key Features:" list={feature1} paddingBottom='pb-24' />
            </section>

            <section ref={measurementRef}>
                <DescSection title="Advanced Measurement" desc="Access to Roof Reveals Explorer Report" gray={true}>
                    <p>
                        Introducing Roof Reveals Explorer Report Module — the ultimate solution for roofing professionals seeking precision and efficiency in their projects. Our innovative tool transforms the way materials are ordered for roof installations by harnessing the power of AI and cutting-edge technology.
                    </p>
                </DescSection>
            </section>

            <section>
                <FeatureWithPoster image="/assets/feature/banner2.png" title="Key Features:" list={feature2} gray={true} paddingBottom='pb-24' />
            </section>

            <div className='pt-24'>
                <img src='/assets/feature/banner1.png' className='w-full' alt="feature banner" />
            </div>


            <section ref={estimationRef}>
                <DescSection title="Automated Estimation" desc="Estimating Assistant - Xactimate Report" >
                    <p>
                        Unlock the power of precision and professionalism with Roof Reveal's Xactimate Report. Seamlessly integrated into our comprehensive suite of tools, the Xactimate Report delivers accurate and detailed estimates in a standardized format, tailored to the unique characteristics of each project. By automating the estimation process and adhering to industry standards, Roof Reveal's Xactimate Report streamlines project planning, facilitates transparent communication with clients and stakeholders, and ensures that projects stay on budget and on schedule. With Roof Reveal's Xactimate Report, bid confidently, secure more projects, and elevate your reputation as a trusted expert in the roofing industry.
                    </p>
                </DescSection>
            </section>


            <section ref={orderingRef}>
                <FeatureWithPoster image="/assets/feature/banner3.png" title="Key Features:" list={feature3} />
            </section>


        </MainHOC>
    )
}
