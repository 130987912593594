import React, { useState } from "react";
import SectionHeadline from "../../common/public/SectionHeadline";
import Button from "../../common/Button";
import { FaArrowRightLong } from "react-icons/fa6";
import { LuMinus, LuPlus } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { NAVIGATION } from "../../../lib/navigation";

export default function FeatureItemSection() {
  const [activeIndex, setActiveIndex] = useState(0);
  const toggleAccordion = (index) => setActiveIndex(prevIndex => (prevIndex === index ? null : index));
  const navigate = useNavigate();

  const features = [
    {
      title: "Roof Reveal's Canvassing Tool",
      description:
        "Canvasing Tool - Roof Reveal's Canvassing Tool is an intelligent and dynamic solution that revolutionizes the way homes are identified and targeted for potential roofing projects. Powered by cutting-edge AI technology, this tool harnesses the vast expanse of Google Maps to meticulously sift through neighborhoods and pinpoint homes that align with the specific criteria of your potential customers.",
    },
    {
      title: "Access To RoofReveal Measurements Reports",
      description:
        "Canvasing Tool - Roof Reveal's Canvassing Tool is an intelligent and dynamic solution that revolutionizes the way homes are identified and targeted for potential roofing projects. Powered by cutting-edge AI technology, this tool harnesses the vast expanse of Google Maps to meticulously sift through neighborhoods and pinpoint homes that align with the specific criteria of your potential customers.",

    },
    {
      title: "Xactimate Report",
      description:
        "Canvasing Tool - Roof Reveal's Canvassing Tool is an intelligent and dynamic solution that revolutionizes the way homes are identified and targeted for potential roofing projects. Powered by cutting-edge AI technology, this tool harnesses the vast expanse of Google Maps to meticulously sift through neighborhoods and pinpoint homes that align with the specific criteria of your potential customers.",
    },
    {
      title: "Material Ordering",
      description:
        "Canvasing Tool - Roof Reveal's Canvassing Tool is an intelligent and dynamic solution that revolutionizes the way homes are identified and targeted for potential roofing projects. Powered by cutting-edge AI technology, this tool harnesses the vast expanse of Google Maps to meticulously sift through neighborhoods and pinpoint homes that align with the specific criteria of your potential customers.",
    },
  ];

  return (
    <section className="flex flex-col md:flex-row bg-secondary">
      <div className="px-6 md:px-20  pt-24 w-full md:w-1/2">
        <SectionHeadline title="Features" desc="Using AI to Simplify Your Processes" />
        {/* features */}
        <div className='mt-6'>
          {features.map((feature, index) => (
            <div className='w-full p-6 border-b-2 border-b-gray-300'>
              <div className='flex items-center justify-between'>
                <p className="font-semibold">{feature.title}</p>
                <button className='h-6 w-6 rounded-sm flex items-center justify-center' onClick={() => toggleAccordion(index)}>
                  {index === activeIndex ? <LuMinus /> : <LuPlus />}
                </button>
              </div>
              <div
                className={`mt-4 py-2 ${activeIndex === index ? '' : 'hidden'}`}
              >
                <p className="mb-2 text-gray-500">{feature.description}</p>
                <Link to={NAVIGATION.FEATURES} className="mt-6 uppercase text-primary flex gap-2 items-center">Explore more <FaArrowRightLong /></Link>
              </div>
            </div>
          ))}
        </div>
        <Button onClick={() => navigate(NAVIGATION.FEATURES)} variant="outlinePrimary" className="mt-10">View all Features <FaArrowRightLong /></Button>
      </div>
      <div className="w-full md:w-1/2">
        <img
          src="/assets/bg/feature-section.png"
          alt="Features illustration"
          className="grow w-full aspect-[0.76] max-md:mt-10 max-md:max-w-full"
        />
      </div>
    </section>
  );
}