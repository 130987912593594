import * as React from "react";
import FeatureItemSection from "../../components/pages/public/FeatureItemSection";
import IndustriesSection from "../../components/pages/public/IndustriesSection";
import StatItemSection from "../../components/pages/public/StatItemSection";
import Headline from "../../components/common/inner/Headline";
import AutoComplete from "../../components/common/inner/AutoComplete";
import { NAVIGATION } from "../../lib/navigation";
import DescSection from "../../components/common/public/DescSection";
import MainHOC from "../../components/pages/public/hoc/MainHOC";
import WhyRoofSection from "../../components/pages/public/home/WhyRoofSection";

function Hero() {
  return (
    <div className='h-screen md:h-[80vh] flex items-center justify-center px-4 py-20 w-full max-w-2xl mx-auto'>
      <div className="bg-neutral-50 text-zinc-900 w-full border border-solid shadow-sm border-stone-300 rounded-xl px-3 md:px-16 py-8">
        <Headline title="Find Your Roof" desc="Discovering Roofing Excellence: Begin Your Journey To Simplifying Your Business Processes." />
        <AutoComplete pathToRedirect={NAVIGATION.FIND_ROOF} />
      </div>
    </div>
  );
}

export function HomePage() {
  return (
    <MainHOC home={true}>
      <div className="flex overflow-hidden relative flex-col justify-center">
        <img
          loading="lazy"
          src="/assets/bg/hero.png"
          alt="Background"
          className="object-cover absolute inset-0 size-full"
        />
        <div className="flex relative justify-center items-center w-full max-md:max-w-full">
          <div className="flex flex-col mb-52 w-full max-md:mb-10 max-md:max-w-full">``
            <Hero />
          </div>
        </div>
      </div>

      <WhyRoofSection />
      <StatItemSection />
      <FeatureItemSection />

      <DescSection title="Industries" desc="Insights into Diverse Sectors">
        <p>
          Roof Reveal extends its transformative capabilities beyond roofing professionals, catering seamlessly to a diverse range of industries including construction, government, insurance, solar, and utility, offering a comprehensive solution that transcends traditional boundaries and optimizes operations for success.
        </p>
      </DescSection>

      <IndustriesSection />
    </MainHOC>

  );
}