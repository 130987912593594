import React from 'react'

const AddressLink = ({from, to}) => {
    return (
        <>
            <div className="mt-6 text-xs font-semibold leading-5 text-zinc-900 max-md:max-w-full">
                Online map of property
            </div>
            <div className="mt-3 text-xs leading-5 text-primary max-md:max-w-full">
                <a href={`http://maps.google.com/maps?q=${to}`} target="_blank">{`http://maps.google.com/maps?q=${to}`}</a>
            </div>
            <div className="mt-6 text-xs font-semibold leading-5 text-zinc-900 max-md:max-w-full">
                Directions from {from} to this property
            </div>
            <div className="mt-3 text-xs leading-5 text-primary max-md:max-w-full text-wrap max-w-full">
                <a href={`http://maps.google.com/maps?f=d&source=s_d&saddr=${from}&daddr=${to}`} target="_blank">{`http://maps.google.com/maps?f=d&source=s_d&saddr=${from}&daddr=${to}`}</a>
            </div>
        </>
    )
}

export default AddressLink