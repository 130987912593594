import * as React from "react";
import MainHOC from "../../components/pages/public/hoc/MainHOC";
import SectionHeadline from "../../components/common/public/SectionHeadline";
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";
import useApiRequest from "../../store/slices/hook/useApiRequest";
import { contact, setContactData } from "../../store/slices/api/misc/contactSlice";
import TextArea from "../../components/common/TextArea";
import { FaFacebook, FaLinkedin, FaXTwitter } from "react-icons/fa6";

export default function ContactPage() {

  const afterAPICall = () => {
    setValue("firstName", "")
    setValue("lastName", "")
    setValue("company", "")
    setValue("email", "")
    setValue("phone", "")
    setValue("message", "")
  }

  const { isLoading, control, handleSubmit, setValue } = useApiRequest({
    apiAction: contact,
    setValueAction: setContactData,
    afterAPICall,
  });

  return (
    <MainHOC footerClasses="mt-0">
      <div className="min-h-screen py-14 md:py-0 bg-[#074781] px-6 md:px-20  w-full flex flex-col md:flex-row items-center gap-20">
        <div className="w-full md:w-1/2">
          <SectionHeadline light={true} title="Get in Touch" desc="Contact Us for Inquiries and Assistance" />
          <p className='mt-10 text-white'>Contact us anytime for inquiries or assistance. Our dedicated team is ready to help. Connect through the provided information or use our online form. We look forward to hearing from you!</p>
        </div>


        <div className="w-full md:w-1/2">
          <form onSubmit={handleSubmit}>
            <div className='flex gap-0 md:gap-6 flex-col md:flex-row'>
              <Input
                variant="light"
                control={control}
                name="firstName"
                rules={{
                  required: "this field is required"
                }}
                label="First Name"
              />
              <Input
                variant="light"
                control={control}
                name="lastName"
                rules={{
                  required: "this field is required"
                }}
                label="Last Name"
              />
            </div>

            <Input
              variant="light"
              control={control}
              name="company"
              rules={{
                required: "this field is required"
              }}
              label="Company"
            />
            <div className="flex gap-0 md:gap-6 flex-col md:flex-row">
              <Input
                variant="light"
                control={control}
                name="email"
                rules={{
                  required: "this field is required"
                }}
                label="Email"
              />
              <Input
                variant="light"
                control={control}
                name="phone"
                rules={{
                  required: "this field is required"
                }}
                label="Phone"
              />
            </div>

            <TextArea
              control={control}
              variant="light"
              name="message"
              rules={{
                required: "this field is required"
              }}
              label="Message"
            />

            <div className="flex justify-end my-6">
              <Button isLoading={isLoading}>
                Send
              </Button>
            </div>
          </form>
        </div>

      </div>
      <div className="items-center md:px-20 py-20 bg-primary px-5">
        <div className="flex flex-col md:flex-row gap-20 md:gap-0 justify-between max-w-screen-xl mx-auto">

          <div>
            <p className={`text-white uppercase font-semibold`}>
              Phone Number
            </p>
            <h2 className="mt-3 text-2xl flex gap-3 text-white">
              +1 444-222-9999
            </h2>
          </div>
          <div>
            <p className={`text-white uppercase font-semibold`}>
              Email Address
            </p>
            <h2 className="mt-3 text-2xl flex gap-3 text-white">
              info@companyname.com
            </h2>
          </div>
          <div>
            <p className={`text-white uppercase font-semibold`}>
              Follow us
            </p>
            <div className="mt-3 text-2xl flex gap-3 text-white">
              <FaFacebook />
              <FaXTwitter />
              <FaLinkedin />
            </div>
          </div>
        </div>
      </div>
    </MainHOC>
  );
}


