import React from 'react'
import { useDispatch } from 'react-redux';
import { setSelectedBuilding } from '../../../../store/slices/common/selectedBuildingSlice';
import { toggleSingleBuildingModalModal } from '../../../../store/slices/common/toggleSlice';

const ResultItem = ({ item, slideOver }) => {
  const dispatch = useDispatch();

  const handleItemClick = () => {
    dispatch(setSelectedBuilding({
      ...item,
      imageurl: item?.solarData?.imagePath
    }))
    dispatch(toggleSingleBuildingModalModal());
  }

  return (
    <div onClick={handleItemClick} className={`pointer border border-neutral-200 shadow-sm rounded-xl p-4 gap-4 flex items-center ${slideOver && 'mb-3'}`}>
      <div className='w-[50%] h-24 rounded-lg overflow-hidden bg-gray-400'>
        {/* <img src={item?.solarData?.imagePath} className="w-full h-full object-cover object-center" /> */}
        <img src={item?.imageurl} alt='result' className="w-full h-full object-cover object-center" />
      </div>
      <div className='h-full w-[50%] text-sm'>
        <p>Roof Type: 3 Tab Shingle</p>
        <p>Roof Age: 4 Years</p>
        <p>{item?.displayName ? item?.displayName?.text : item?.shortFormattedAddress}</p>
      </div>
    </div>

  )
}

export default ResultItem