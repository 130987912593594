import * as React from "react";
import Breadcrumb from "../../../components/common/inner/Breadcrumb";
import { NAVIGATION } from "../../../lib/navigation";
import { MdCheck } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import MapSlideOver from "../../../components/pages/inner/canvas/MapSlideOver";
import Map from "../../../components/common/inner/Map";
import { FeatureGroup, Marker, Polygon, Popup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import MapTopBar from "../../../components/common/inner/MapTopBar";
import CanvasCreditsModal from "../../../components/pages/inner/canvas/CanvasCreditsModal";
import { clearSolarData, fetchSolarData, selectPlaceData, selectSolarData, setMapLoading, setSolarData } from "../../../store/slices/api/misc/autoCompleteSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApiRequest from "../../../store/slices/hook/useApiRequest";
import { clearSelectedArea, setSelectedArea } from "../../../store/slices/common/selectedAreaSlice";
import { toggleCanvasModal, toggleMapBreadcrumb, toggleMapSlide } from "../../../store/slices/common/toggleSlice";
import { addReport, setReportData } from "../../../store/slices/api/inner/reportSlice";
import { selectLoggedInData, setLoginData } from "../../../store/slices/api/auth/loginSlice";
import LogoutModal from "../../../components/pages/inner/profile/LogoutModal";
import InnerFooter from "../../../components/common/inner/InnerFooter";
import InnerHeader from "../../../components/common/inner/InnerHeader";
import SingleBuildingRequestModal from "../../../components/pages/inner/canvas/SingleBuildingRequestModal";
import { clearSelectedReportType } from "../../../store/slices/common/selectedReportTypeSlice";
import LowCreditWarning from "../../../components/common/inner/LowCreditWarning";

export default function CanvasMap() {
  const { state: address } = useLocation();
  const params = useParams();
  const mapRef = React.useRef(null);
  const [scrollWheelZoom, setScrollWheelZoom] = React.useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedReportType = useSelector((state) => state?.selectedReportType?.value)
  const mapBreadcrumb = useSelector(state => state.toggle.mapBreadcrumb);
  const mapLoading = useSelector(state => state.autoComplete.mapLoading);
  const selectedPlace = useSelector(selectPlaceData);
  const selectedArea = useSelector(state => state.selectedArea.value);
  const selectedAreaBuildings = useSelector(selectSolarData);
  const loggedInUser = useSelector(selectLoggedInData);
  const [coordinates, setCoordinates] = React.useState(selectedPlace?.geometry?.location ?? {
    lat: localStorage.getItem("selectedLat") ?? null,
    lng: localStorage.getItem("selectedLng") ?? null
  });
  const [geoTiffUrls, setGeoTiffUrls] = React.useState([]);

  const { isLoading: polygonLoading, handleSubmit: handlePolygonCreated } = useApiRequest({
    apiAction: fetchSolarData,
    setValueAction: setSolarData,
    setCustomLoadingOnError: setMapLoading,
    isForm: false,
    successMessage: false,
  });

  const _created = async ({ layer: { editing: { latlngs: [[latlng]] } } }) => {
    await dispatch(setSelectedArea(latlng));
    dispatch(setMapLoading(true))
    await handlePolygonCreated({
      polygon: latlng
    });
    await dispatch(toggleMapBreadcrumb(false));
  };


  const handleCheck = async () => {
    setScrollWheelZoom(false);
    await dispatch(toggleMapSlide());
  };

  const toggleModal = async () => await dispatch(toggleCanvasModal());

  // const handleGeneratePDF = () => {
  //   navigate(NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT2, {
  //     state: {
  //       items: selectedAreaBuildings ? selectedAreaBuildings : [],
  //       designLink: `${NAVIGATION.INNER_NAVIGATION.CANVAS.CANVAS_MAP}/${params?.reportId}`,
  //       reportLink: NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT2
  //     }
  //   });
  // };

  const _edited = async ({ layer: { editing: { latlngs: [[latlng]] } } }) => {
    try {

      await dispatch(setSelectedArea(latlng));
      dispatch(setMapLoading(true))
      await handlePolygonCreated({
        polygon: latlng
      });
      await dispatch(toggleMapBreadcrumb(false));
    } catch (error) {
      console.log("Error during edit");

    }
  };


  const _deleted = async (e) => {
    setGeoTiffUrls([])
    await dispatch(toggleMapBreadcrumb(true));
    await dispatch(clearSolarData());
    await dispatch(clearSelectedArea());
  };

  // handling save button
  const beforeAPICall = (data) => {
    return {
      success: true,
      refactoredData: {
        reportId: params?.reportId,
        email: loggedInUser?.contactInfo?.email ?? "",
        phone: loggedInUser?.contactInfo?.phone ?? "",
        fullName: loggedInUser?.contactInfo?.fullName ?? "",
        firstName: loggedInUser?.contactInfo?.firstName ?? "",
        lastName: loggedInUser?.contactInfo?.lastName ?? "",
        userName: loggedInUser?.contactInfo?.userName ?? "",
        countryCode: loggedInUser?.contactInfo?.countryCode ?? "",
        address: loggedInUser?.contactInfo?.address ?? "",
        zipCode: loggedInUser?.contactInfo?.zipCode ?? "",
        city: loggedInUser?.contactInfo?.city ?? "",
        company: loggedInUser?.contactInfo?.company ?? "",
        state: loggedInUser?.contactInfo?.state ?? "",
        country: loggedInUser?.contactInfo?.country ?? "",
        summary: "",
        notes: "",
        latitude: coordinates?.lat,
        longitude: coordinates?.lng,
        Thumbnail: selectedAreaBuildings[0]?.photos?.[0]?.photo_reference,
        file: selectedAreaBuildings?.[0]?.imageurl,
        roofType: "",
        approxAge: "",
        buildingName: address?.text2,
        coordinates: selectedArea,
        canvasBuildingList: selectedAreaBuildings
          ? selectedAreaBuildings.map(building => ({
            imageurl: building?.imageurl,
            displayName: building?.displayName,
            shortFormattedAddress: building?.shortFormattedAddress,
            solarData: building?.solarData,
          }))
          : [],
        reportTypeNumber: 1, //! for canvas report of multiple buildings 
      }
    }
  }

  const afterAPICall = (response) => {
    setGeoTiffUrls([])
    dispatch(toggleMapBreadcrumb(true));
    dispatch(clearSolarData());
    dispatch(clearSelectedReportType());
    dispatch(setLoginData({
      ...loggedInUser,
      subscriptionCredits: loggedInUser?.subscriptionCredits - response?.decrementValue,
    }))
    dispatch(clearSelectedArea());
    navigate(NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT_LIST);
  }

  const { isLoading, handleSubmit } = useApiRequest({
    apiAction: addReport,
    setValueAction: setReportData,
    beforeAPICall,
    afterAPICall,
    isForm: false,
  });

  const handleSave = () => {
    handleSubmit();
  };

  React.useEffect(() => {
    const data = selectedAreaBuildings?.map((building) => building?.solarData?.maskUrl);
    (data && data?.[0] && data?.[0] !== undefined) && setGeoTiffUrls(data)
  }, [selectedAreaBuildings])

  React.useEffect(() => {
    if ("geolocation" in navigator && (coordinates.lat === null || coordinates.lng === null)) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.log("Error getting geolocation: ", error);
          setCoordinates({
            lat: 51.505,
            lng: -0.09,
          });
        }
      );
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, [coordinates.lat, coordinates.lng]);

  return (

    <div className='min-h-screen flex flex-col'>

      <div className='flex-1'>
        <div className="relative h-screen w-full">
          {(coordinates && coordinates?.lat !== null) && <Map isLoading={mapLoading} mapRef={mapRef} coordinates={coordinates} scrollWheelZoom={scrollWheelZoom} geoTiffUrls={geoTiffUrls}>
            <InnerHeader />
            {mapBreadcrumb && <Breadcrumb crumbs={[{ title: "Canvasing Report", href: `${NAVIGATION.INNER_NAVIGATION.CANVAS.FIND_LOCATION}/${params?.reportId}` }, { title: address?.text2, href: "" }]} />}
            {/* {!mapBreadcrumb && <MapTopBar isLoading={isLoading} handleSave={handleSave} head={params?.reportId ? `#${params?.reportId}` : "Canvasing Report"} designLink={`${NAVIGATION.INNER_NAVIGATION.CANVAS.CANVAS_MAP}/${params?.reportId}`} reportLink={NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT2} title="Canvasing Report" toggleModal={toggleModal} handleDownload={handleGeneratePDF} handlePrint={handleGeneratePDF} />} */}
            {!mapBreadcrumb && <MapTopBar isLoading={isLoading} handleSave={handleSave} head={"Canvasing Report"} designLink={`${NAVIGATION.INNER_NAVIGATION.CANVAS.CANVAS_MAP}/${params?.reportId}`} reportLink={`${NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT2}/${params?.reportId}`} title="Canvasing Report" toggleModal={toggleModal} />}
            {loggedInUser?.subscriptionCredits < selectedReportType?.credits && <LowCreditWarning map={true} />}
            <FeatureGroup>
              <EditControl
                className="z-10"
                position="bottomright"
                onEdited={_edited}
                onCreated={_created}
                onDeleted={_deleted}
                draw={{
                  rectangle: false,
                  circle: false,
                  circlemarker: false,
                  marker: false,
                  editing: false,
                  polyline: false,
                  polygon: {
                    allowIntersection: false,
                    shapeOptions: {
                      color: "#FC4F00"
                    },
                  },
                }}
              />
              <Polygon positions={selectedArea} color="#FC4F00" />
            </FeatureGroup>
            <Marker position={[coordinates?.lat, coordinates?.lng]}>
              {selectedPlace?.name && <Popup>
                {selectedPlace?.name}
              </Popup>}
            </Marker>
            {/* {selectedAreaBuildings && selectedAreaBuildings?.map((building, idx) => <Marker icon={customIcon}
            key={idx}
            position={[building?.geometry?.location?.lat, building?.geometry?.location?.lng]}>
            {building?.name && <Popup>
              {building?.name}
            </Popup>}
          </Marker>)} */}
          </Map>}

          <button onClick={handleCheck} className="absolute flex items-center justify-center z-10 right-5 h-10 w-10 top-[33vh] rounded-sm bg-white">
            <MdCheck />
          </button>
        </div>

        {selectedAreaBuildings && <MapSlideOver />}
        <CanvasCreditsModal toggleModal={toggleModal} />
      </div>
      <LogoutModal />
      <SingleBuildingRequestModal coordinates={coordinates} reportId={params?.reportId} address={address} />
      <InnerFooter />
    </div>


  );
}
