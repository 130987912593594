import React from 'react'
import { Link } from 'react-router-dom'
import { NAVIGATION } from '../../../lib/navigation'
import { useSelector } from 'react-redux'
import { selectLoggedInData } from '../../../store/slices/api/auth/loginSlice'

const MailVerificationWarning = ({
   map=false
}) => {
    const loggedInData = useSelector(selectLoggedInData)
    return (
        <div className={`relative mb-6 border ${map ? "z-[999] bg-yellow-500/60 border-yellow-500 text-slate-900" : "bg-yellow-500/10 border-yellow-500 text-yellow-500"} rounded-md p-4`}>You didn't verified your email. <Link className={`border-b ${map ? "border-b-slate-900 text-slate-900" : "border-b-yellow-500"}`} to={`${NAVIGATION.AUTH_NAVIGATION.VERIFY_EMAIL}?email=${loggedInData?.email}`}>Please click here to verify your email.</Link></div>
    )
}

export default MailVerificationWarning