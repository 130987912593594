import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { NAVIGATION } from "../../lib/navigation";
import { MdClose } from "react-icons/md";
import { dummyMethod, resendEmail } from "../../store/slices/api/auth/loginSlice";
import useApiRequest from "../../store/slices/hook/useApiRequest";

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');
  const email = searchParams.get('email');

  const beforeAPICall = (data) => {
    return {
      success: true,
      refactoredData: {
        email: email,
      }
    }
  }

  const { isLoading, handleSubmit } = useApiRequest({
    apiAction: resendEmail,
    beforeAPICall,
    setValueAction: dummyMethod,
    isForm: false,
  });


  const handleResend = () => {
    if (email) {
      handleSubmit();
    } return
  };

  return (
    <div className="w-full pt-32">
      {(error && error === "invalid_otp") && <div>
        <div className={`w-full max-w-md mx-auto relative mb-6 border bg-primary/10 border-primary text-primary rounded-md p-4`}>Your link has been expired. <button className='text-primary'>Please click here to request a new email</button></div>
      </div>}
      <div className='w-full max-w-md mb-16 mx-auto'>
        <div className='w-full px-6'>
          <div className="flex items-center justify-between">
            <Link to={NAVIGATION.HOME}>
              <img src="/assets/logo.svg" className="h-9" alt="Roof Reveal Logo" />
            </Link>

            <Link to={NAVIGATION.HOME}>
              <div className="h-10 w-10 flex items-center justify-center border rounded">
                <MdClose className={`w-4 h-4`} />
              </div>
            </Link>
          </div>
          <p className="mt-6 text-lg font-semibold">Verify your email address</p>
          <p className="text-sm text-gray-400 mt-3">
            We have sent you an email to <span className="text-primary">{email}</span> Open your email to verify your address and finish signing up. If you can’t find it, check your spam folder or request a new email.
          </p>

          <button type="button" className='text-primary block mt-8' onClick={handleResend}>Request a new email</button>
        </div>
      </div>
    </div>
  );
}

