import React from 'react'
import { Link } from 'react-router-dom'
import { NAVIGATION } from '../../../lib/navigation'

const LowCreditWarning = ({
    map=false,
}) => {
    return (
        <div className={`relative mb-6 border ${map ? "z-[999] bg-yellow-500/60 border-yellow-500 text-slate-900" : "bg-yellow-500/10 border-yellow-500 text-yellow-500"} rounded-md p-4`}>You don't have enough credits to generate this report. <Link className={`border-b ${map ? "border-b-slate-900 text-slate-900" : "border-b-yellow-500"}`} to={NAVIGATION.INNER_NAVIGATION.SUBSCRIPTION.PLANS}>Please purchase more credits</Link></div>
    )
}

export default LowCreditWarning