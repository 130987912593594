import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: null,
};

export const forgetPassword = createAsyncThunk(
    'user/forgetPassword',
    async (formData) => {
        try {
            const response = await myAxios.post(`user/forgotpassword`, formData);
            return { success: true, message: "Email send successfully!", data: response?.data?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const resetPassword = createAsyncThunk(
    'user/resetPassword',
    async (formData) => {
        try {
            const response = await myAxios.post(`user/setNewPassword`, {
                userId: formData.userId,
                password: formData.password,
            });
            return { success: true, message: "Password updated successfully!", data: response?.data?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

const passwordSlice = createSlice({
    name: 'password',
    initialState,
    reducers: {
        clearPasswordData: state => {
            state.data = null;
        },
        setPasswordData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const passwordReducer = passwordSlice.reducer;
export const { clearPasswordData, setPasswordData } = passwordSlice.actions;

export const selectPasswordData = (state) => state.password.data;
