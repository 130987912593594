import React from "react";
import DashboardHOC from "../../../components/pages/inner/hoc/DashboardHOC";
import Breadcrumb from "../../../components/common/inner/Breadcrumb";
import { NAVIGATION } from "../../../lib/navigation";
import Headline from "../../../components/common/inner/Headline";
import AutoComplete from "../../../components/common/inner/AutoComplete";
import { useParams } from "react-router-dom";

export default function FindLocation() {
    const params = useParams();

    return (
        <DashboardHOC>
            <Breadcrumb crumbs={[{ title: "Canvasing Report", href: "" }]} />

            <div className='px-4 py-20 mb-36 w-full max-w-2xl mx-auto'>
                <div className="bg-neutral-50 text-zinc-900 w-full border border-solid shadow-sm border-stone-300 rounded-xl px-3 md:px-16 py-8">
                    <Headline title="Canvas Location" desc="Discovering Roofing Excellence: Unveiling Materials, Cutting-Edge
            Technologies, and Industry-Leading Companies" />

                    <AutoComplete pathToRedirect={`${NAVIGATION.INNER_NAVIGATION.CANVAS.CANVAS_MAP}/${params?.reportId}`} />
                </div>
            </div>

        </DashboardHOC>
    );
}
