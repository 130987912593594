import React, { useState } from 'react'
import HelpHOC from '../../../components/pages/inner/hoc/HelpHOC'
import { LuPlus, LuMinus } from "react-icons/lu";
import { NAVIGATION } from '../../../lib/navigation'

const links = [
  {
    id: 1, label: "User-Friendly Interface", faq: [
      { title: "Intuitive Navigation", desc: "Intuitive Navigation: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Clear Layout", desc: "Clear Layout: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Simple Controls", desc: "Simple Controls: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Customizable Preferences", desc: "Customizable Preferences: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Contextual Help", desc: "Contextual Help: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Responsive Design", desc: "Responsive Design: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
    ]
  },
  {
    id: 2, label: "Visual Representation", faq: [
      { title: "Intuitive Navigation", desc: "Intuitive Navigation: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Clear Layout", desc: "Clear Layout: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Simple Controls", desc: "Simple Controls: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Customizable Preferences", desc: "Customizable Preferences: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Contextual Help", desc: "Contextual Help: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Responsive Design", desc: "Responsive Design: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
    ]
  },
  {
    id: 3, label: "Customization Options", faq: [
      { title: "Intuitive Navigation", desc: "Intuitive Navigation: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Clear Layout", desc: "Clear Layout: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Simple Controls", desc: "Simple Controls: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Customizable Preferences", desc: "Customizable Preferences: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Contextual Help", desc: "Contextual Help: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Responsive Design", desc: "Responsive Design: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
    ]
  },
  {
    id: 4, label: "Real-Time Updates", faq: [
      { title: "Intuitive Navigation", desc: "Intuitive Navigation: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Clear Layout", desc: "Clear Layout: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Simple Controls", desc: "Simple Controls: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Customizable Preferences", desc: "Customizable Preferences: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Contextual Help", desc: "Contextual Help: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Responsive Design", desc: "Responsive Design: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
    ]
  },
  {
    id: 5, label: "Interactive Features", faq: [
      { title: "Intuitive Navigation", desc: "Intuitive Navigation: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Clear Layout", desc: "Clear Layout: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Simple Controls", desc: "Simple Controls: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Customizable Preferences", desc: "Customizable Preferences: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Contextual Help", desc: "Contextual Help: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Responsive Design", desc: "Responsive Design: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
    ]
  },
  {
    id: 6, label: "Accessibility", faq: [
      { title: "Intuitive Navigation", desc: "Intuitive Navigation: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Clear Layout", desc: "Clear Layout: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Simple Controls", desc: "Simple Controls: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Customizable Preferences", desc: "Customizable Preferences: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Contextual Help", desc: "Contextual Help: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
      { title: "Responsive Design", desc: "Responsive Design: The interface is designed for easy navigation, allowing users to move seamlessly between different sections and features." },
    ]
  }
];

const SingleHelp = () => {
  const [selectedData, setSelectedData] = useState(links[0])
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => setActiveIndex(prevIndex => (prevIndex === index ? null : index));

  return (
    <HelpHOC
      crumbs={[{ title: "Help", href: NAVIGATION.INNER_NAVIGATION.HELP_CENTER.MAIN }, { title: "single help item" }]}
      title="Interactive Dashboard"
      desc="The Interactive Dashboard feature within RoofReveal is a powerful tool designed to enhance your experience with accessing and managing roofing measurements reports."
    >
      <div className='grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6'>
        <div className='col-span-1'>
          <aside className="w-full h-full">
            <div className="h-full overflow-y-auto">
              <ul className="space-y-2 font-medium">
                {links.map((item) => <li>
                  <button onClick={() => setSelectedData(item)} className={`w-full flex items-center p-2 text-gray-900 rounded-lg ${selectedData.id === item.id ? "bg-primary/5 text-primary" : ""}`}>
                    <span className="ms-3 whitespace-nowrap">{item.label}</span>
                  </button>
                </li>)}
              </ul>
            </div>
          </aside>
        </div>

        <div className='col-span-1 md:col-span-2'>
          {selectedData.faq.map((faq, index) => (
            <div className='w-full mb-6 bg-gray-100 shadow-lg px-8 py-7'>
              <div className='flex items-center justify-between'>
                <p>{selectedData.label}- {faq.title}</p>
                <button className='h-6 w-6 rounded-sm flex items-center justify-center' onClick={() => toggleAccordion(`${selectedData.id}-${index}`)}>
                  {`${selectedData.id}-${index}` === activeIndex ? <LuMinus /> : <LuPlus />}
                </button>
              </div>
              <div
                className={`py-2 ${activeIndex === `${selectedData.id}-${index}` ? '' : 'hidden'}`}
              >
                <p className="mb-2 text-gray-500 ">{faq.desc}</p>
              </div>
            </div>
          ))}
        </div>

      </div>
    </HelpHOC>
  )
}

export default SingleHelp