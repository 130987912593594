import React from 'react'
import Section from '../../../common/public/Section'
import SectionHeadline from '../../../common/public/SectionHeadline'
import Button from '../../../common/Button'
import { useNavigate } from 'react-router-dom'
import { NAVIGATION } from '../../../../lib/navigation'

const WhyRoofSection = () => {

    const navigate = useNavigate();


    return (
        <Section>
            <div className="flex flex-col md:flex-row gap-20">
                <div className="w-full md:w-1/2">
                    <SectionHeadline title=" Welcome to RoofReveal" desc="Why Roof Reveal" />
                    <img
                        loading="lazy"
                        alt="why"
                        src="/assets/bg/why-rr.png"
                        className="mt-16 w-full aspect-[1.56] max-md:mt-10 max-md:max-w-full"
                    />
                    <div className="justify-center mt-10 max-md:max-w-full text-gray-600">
                        <p> At Roof Reveal, we're on a mission to transform the roofing
                            industry by providing innovative solutions that empower
                            professionals to excel in their craft. Here's why Roof Reveal is
                            the ultimate choice for roofing professionals:</p>

                        <p className="mt-6"><span className="font-bold text-gray-800">Cutting-Edge Technology:</span> Roof Reveal harnesses the power of cutting-edge technology, including
                            AI-driven reports and location-specific estimates, to streamline
                            operations and enhance precision.</p>
                        <p className="mt-6"><span className="font-bold text-gray-800">Efficiency Redefined:</span> With
                            Roof Reveal, efficiency is more than just a buzzword—it's a way
                            of life. Our platform automates tedious tasks, accelerates
                            processes, and ensures every project is completed with maximum
                            efficiency.</p>
                        <p className="mt-6">
                            <span className="font-bold text-gray-800">Tailored Solutions:</span> We
                            understand that every roofing project is unique. That's why Roof
                            Reveal offers tailored solutions that adapt to the specific
                            needs and challenges of each project, providing unparalleled
                            flexibility and customization.
                        </p>
                        <p className="mt-6">
                            <span className="font-bold text-gray-800">Industry Expertise:</span> Backed by
                            years of industry experience and a deep understanding of the
                            roofing landscape, Roof Reveal is designed by roofers, for
                            roofers. Our platform reflects the insights and expertise gained
                            from years of hands-on experience in the field.
                        </p>
                        <p className="mt-6"><span className="font-bold text-gray-800">Future-Proof:</span> With Roof
                            Reveal, you're not just investing in a tool—you're investing in
                            the future of your roofing business. Our platform is constantly
                            evolving to meet the changing needs of the industry, ensuring
                            you stay ahead of the curve. Experience the Roof Reveal
                            difference today and unlock new possibilities for success in the
                            roofing industry. Join us as we revolutionize the way roofing
                            professionals work, one project at a time.</p>
                        <Button onClick={() => navigate(NAVIGATION.ABOUT)} type="button" variant="outlinePrimary" className="mt-10">
                            Learn More About RoofReveal
                        </Button>
                    </div>
                </div>

                <div className="w-full md:w-1/2 text-gray-600">
                    <p>Roof Reveal is a game-changing platform that revolutionizes the
                        roofing industry with its key features. Our advanced AI-driven
                        canvassing tool harnesses Google Maps to identify potential
                        customers, while providing detailed roof measurements.</p>

                    <img
                        loading="lazy"
                        alt="why"
                        srcSet="/assets/bg/why-rr-2.png"
                        className="mt-10 w-full h-full max-h-[1200px]"
                    />
                </div>
            </div>
        </Section>

    )
}

export default WhyRoofSection