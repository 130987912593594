import React from 'react'
import { MdClose } from 'react-icons/md'
import FilterPill from './FilterPill'

const AppliedFilters = ({ data }) => {
    return (
        <div className='mt-6 mb-6 pb-6 border-b border-b-gray-200'>
            <div className='flex items-center justify-between'>
                <span className='text-sm'>Applied filters</span>

                <button className='outline-none text-gray-400 text-sm flex gap-3 items-center rounded-md'>
                    <span>Clear All</span>
                    <MdClose className='text-lg' />
                </button>
            </div>

            <div className='flex flex-wrap gap-2 mt-6'>
                {data.map(item => <FilterPill key={item.id} text={item.text} variant={item.active ? "active" : "default"} />)}
            </div>
        </div>
    )
}

export default AppliedFilters