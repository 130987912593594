import React, { useState } from 'react'
import SlideOverHOC from '../hoc/SlideOverHOC'
import ResultList from './ResultList'
import { useDispatch, useSelector } from 'react-redux'
import { MdOutlineFilterList } from "react-icons/md";
import Button from '../../../common/Button'
import FiltersList from './FiltersList'
import { selectSolarData } from '../../../../store/slices/api/misc/autoCompleteSlice'
import { toggleMapSlide } from '../../../../store/slices/common/toggleSlice';

const MapSlideOver = () => {
    const open = useSelector(state => state.toggle.mapSlide)
    const dispatch = useDispatch();
    const [showFilters, setShowFilters] = useState(false);
    const selectedAreaBuildings = useSelector(selectSolarData) ?? [];

    const toggleFilters = () => setShowFilters(prevFilter => !prevFilter)
    const toggleSlideOver = async () => await dispatch(toggleMapSlide());


    return (
        <SlideOverHOC title="Showing results for <b>Roofs</b>" open={open} toggleSlideOver={toggleSlideOver}>
            {showFilters ? <FiltersList toggleFilters={toggleFilters} /> : <ResultList data={selectedAreaBuildings} />}

            {!showFilters && <div className="fixed py-3 bg-white bottom-0" style={{
                width: "calc(100% - 54px)"
            }}>
                <Button variant="dark" type='button' onClick={toggleFilters} className="w-full">
                    <MdOutlineFilterList className="text-xl" />
                    Filters
                </Button>
            </div>}

        </SlideOverHOC>
    )
}

export default MapSlideOver