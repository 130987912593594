import React from 'react'
import { useSelector } from 'react-redux'
import Button from '../../../common/Button'
import ModalHOC from '../hoc/ModalHOC'

const CanvasCreditsModal = ({ toggleModal }) => {
    const canvasModal = useSelector(state => state.toggle.canvasModal)

    return (
        <ModalHOC open={canvasModal} toggleModal={toggleModal} title="Select the report to Up Grade" maxWidth='max-w-xs'>
            <div>
                <label htmlFor='explorerReport' className='flex items-center justify-between border border-primary px-4 py-3 rounded-md mb-2'>
                    <div className='flex items-center gap-2'>
                        <input type='checkbox' id='explorerReport' />
                        <span className='text-sm'>Explorer Report</span>
                    </div>
                    <span className='text-xs text-gray-400'>25 Credits</span>
                </label>
                <label htmlFor='xactimateReport' className='flex items-center justify-between border border-gray-400 px-4 py-3 rounded-md mb-2'>
                    <div className='flex items-center gap-2'>
                        <input type='checkbox' id='xactimateReport' />
                        <span className='text-sm'>Xactimate Report</span>
                    </div>
                    <span className='text-xs text-gray-400'>10 Credits</span>
                </label>

                <Button className="mt-6 w-full">
                    Upgrade
                </Button>
            </div>
        </ModalHOC>
    )
}

export default CanvasCreditsModal