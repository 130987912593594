import React from "react";
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";
import { Link, useNavigate } from "react-router-dom";
import { NAVIGATION } from "../../lib/navigation";
import useApiRequest from "../../store/slices/hook/useApiRequest";
import { forgetPassword } from "../../store/slices/api/auth/passwordSlice";
import { MdClose } from "react-icons/md";

export default function ForgetPassword() {
  const navigate = useNavigate();

  const afterAPICall = (response) => {
    navigate(`${NAVIGATION.AUTH_NAVIGATION.VERIFY_EMAIL}?email=${response?.email}`);
  }

  const { isLoading, control, handleSubmit } = useApiRequest({
    apiAction: forgetPassword,
    afterAPICall,
  });

  return (
    <div className="w-full pt-32">
      <div className='w-full max-w-md mb-16 mx-auto'>
        <div className='w-full px-6'>
          <div className="flex items-center justify-between">

            <Link to={NAVIGATION.HOME}>
              <img src="/assets/logo.svg" className="h-9" alt="Roof Reveal Logo" />
            </Link>

            <Link to={NAVIGATION.HOME}>
              <div className="h-10 w-10 flex items-center justify-center border rounded">
                <MdClose className={`w-4 h-4`} />
              </div>
            </Link>

          </div>
          <p className="mt-6 text-lg font-semibold">Reset your password</p>
          <p className="text-sm text-gray-400 mt-3">
            Enter the email address associated with your account, and we'll send you a link to reset your password.
          </p>
          <form onSubmit={handleSubmit}>

            <Input
              control={control}
              name="email"
              rules={{
                required: "this field is required"
              }}
              type="email"

              label="Email"
            />

            <div className="my-6">
              <Button isLoading={isLoading} className="w-full">
                Continue
              </Button>
            </div>

            <Link to={NAVIGATION.AUTH_NAVIGATION.LOGIN} className='text-primary text-center block'> Back to sign in</Link>
          </form>
        </div>
      </div>
    </div>
  );
}
