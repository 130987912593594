import React, { useEffect, useState, useCallback, useRef } from 'react';
import ProfileHOC from '../../../components/pages/inner/hoc/ProfileHOC';
import { useDispatch, useSelector } from 'react-redux';
import { clearMyCreditData, fetchMyCreditHistory, selectMyCreditData, setMyCreditData, setMyCreditDataWithPrev } from '../../../store/slices/api/inner/myPurchaseSlice';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { formatDate } from '../../../lib/formatDate';
import CancelModal from '../../../components/pages/inner/profile/CancelModal';
import { toggleCancelModal } from '../../../store/slices/common/toggleSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FiLoader } from 'react-icons/fi';

const MyCredit = () => {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const dispatch = useDispatch();
    const debounceTimeout = useRef(null);

    const data = useSelector(selectMyCreditData);
    const { isLoading: fetchLoading, handleSubmit: fetchData } = useApiRequest({
        apiAction: fetchMyCreditHistory,
        setValueAction: setMyCreditDataWithPrev,
        successMessage: false,
        isForm: false,
    });

    // handle Refresh
    const { isLoading: refreshLoading, handleSubmit: fetchRefreshData } = useApiRequest({
        apiAction: fetchMyCreditHistory,
        setValueAction: setMyCreditData,
        successMessage: false,
        isForm: false,
    });

    const toggleModal = () => dispatch(toggleCancelModal());

    const handlePagination = async (pagination) => {
        const result = pagination ? await fetchData({ limit, page }) : await fetchRefreshData({ limit, page: 1 });
        if (result?.length < limit) {
            setHasMore(false);
        }
    };

    useEffect(() => {
        handlePagination(1);
    }, [page, limit]);

    useEffect(() => {
        return () => {
            dispatch(clearMyCreditData());
        };
    }, [dispatch]);

    const debounce = (func, delay) => {
        return (...args) => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
            debounceTimeout.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const loadMoreData = useCallback(debounce(() => {
        setPage(prevPage => prevPage + 1);
    }, 300), [page]);

    return (
        <ProfileHOC title="My Credit History">
            <div className="relative my-6 pb-10">
                {data.length === 0 && !hasMore && (
                    <h4 className="text-center mt-6">No credit history available.</h4>
                )}
                <InfiniteScroll
                    dataLength={data?.length || 0}
                    next={loadMoreData}
                    hasMore={hasMore}
                    loader={(fetchLoading || refreshLoading) ? <div className={`${hasMore ? 'flex' : 'hidden'} items-center justify-center h-10`}>
                         <FiLoader className={`animate-spin text-xl`} />
                    </div> : null}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                    refreshFunction={() => {
                        handlePagination(0);
                    }}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                        <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                    }
                    releaseToRefreshContent={
                        <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                    }
                >
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead className="text-xs cursor-grabbing select-none text-gray-700 uppercase bg-gray-50">
                            <tr className='bg-gray-100'>
                                <th scope="col" className="px-6 py-3">#</th>
                                <th scope="col" className="px-6 py-3">Reason</th>
                                <th scope="col" className="px-6 py-3">Date</th>
                                <th scope="col" className="px-6 py-3">Credits</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item, index) => (
                                <tr key={index} className={`bg-white border-b`}>
                                    <td className={`px-6 py-4`}>{index + 1}</td>
                                    <td className={`px-6 py-4`}>
                                        {item?.reportId ? `Report Request` : `Subscription`}
                                    </td>
                                    <td className={`px-6 py-4`}>{formatDate(item.date)}</td>
                                    <td className={`px-6 py-4 font-semibold ${item?.reportId ? "text-red-500" : "text-green-500"}`}>
                                        {item?.reportId ? `-${item?.credits}` : `+${item?.credits}`}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
            <CancelModal toggleModal={toggleModal} />
        </ProfileHOC>
    );
};

export default MyCredit;
