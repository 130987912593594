import React from 'react'
import ResultItem from './ResultItem'
import EmptyList from './EmptyList'

const ResultList = ({ data = [], slideOver = true }) => {

    return (
        <div className='w-full h-full'>
            {(data && data?.length > 0) ? <div className={`${slideOver ? 'pb-14' : 'grid grid-cols-2 gap-3'}`}>
                {data?.map((item, idx) => <ResultItem slideOver={slideOver} key={idx} item={item} />)}
            </div> : <EmptyList />}
        </div>
    )
}

export default ResultList