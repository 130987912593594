import { cva } from 'class-variance-authority'
import React from 'react'
import { cn } from '../../../../lib/cn'
import { MdClose } from 'react-icons/md'


const filterPillVariants = cva(
    "px-2 py-2 text-xs flex gap-1 items-center font-medium text-center rounded-lg outline-none",
    {
        variants: {
            variant: {
                default: "bg-transparent hover:bg-gray-200 border text-gray-700 border-gray-200",
                active: "bg-primary/80 hover:bg-primary text-white"
            },
        },
        defaultVariants: {
            variant: "default",
        },

    }
)

const FilterPill = ({
    className,
    variant = "default",
    text,
}) => {
    return (
        <button type="button" className={cn(filterPillVariants({ variant, className }))}><span>{text}</span>
            {variant === "active" && <MdClose className='text-md' />}
        </button>
    )
}

export default FilterPill