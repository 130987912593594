import React, { useEffect } from 'react'
import ReportTable from '../../../components/pages/inner/report/ReportTable'
import { fetchDashboardCard, selectDashboardCardData, setDashboardCardData } from '../../../store/slices/api/inner/dashboardCardSlice';
import { useSelector } from 'react-redux';
import useApiRequest from '../../../store/slices/hook/useApiRequest';

const CanvasReportList = () => {
  const tabs = useSelector(selectDashboardCardData);

  const { handleSubmit: fetchTabs } = useApiRequest({
    apiAction: fetchDashboardCard,
    setValueAction: setDashboardCardData,
    successMessage: false,
    isForm: false,
  });

  useEffect(() => {
    if (tabs?.length === 0) {
      fetchTabs()
    }
  }, [])

  return (
    <ReportTable selectedTab={tabs[0]?._id} tabs={tabs} />
  )
}

export default CanvasReportList