import React from 'react'
import { Controller } from 'react-hook-form'
import { cn } from '../../lib/cn'

const TextArea = ({
    name,
    control,
    rules,
    defaultValue,
    className = "w-full mt-8",
    label,
    variant = "default",
}) => {
    const baseClasses = {
        textarea: "peer h-full min-h-[100px] w-full resize-none rounded-[7px] border bg-transparent px-3 py-2.5 font-sans text-sm font-normal outline outline-0 transition-all placeholder-shown:border focus:border-2 border-t-0 focus:border-t-0 focus:outline-0 disabled:resize-none disabled:border-0",
        label: "before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent"
    }

    const variants = {
        default: {
            textarea: "border-[#1A1A1B50] placeholder-shown:border-[#1A1A1B50] placeholder-shown:border-t-[#1A1A1B50] disabled:bg-[#1A1A1B50] focus:border-[#1A1A1B50]",
            label: "text-[#1A1A1B50] before:border-[#1A1A1B50] peer-placeholder-shown:text-[#1A1A1B50] after:border-[#1A1A1B50] peer-focus:text-[#1A1A1B50] peer-focus:before:border-[#1A1A1B50] peer-focus:after:border-[#1A1A1B50] peer-disabled:peer-placeholder-shown:text-[#1A1A1B50]"
        },
        light: {
            textarea: "border-[#D1D5DB] text-[#6B7280] placeholder-shown:border-[#D1D5DB] placeholder-shown:border-t-[#D1D5DB] disabled:bg-[#D1D5DB] focus:border-[#D1D5DB]",
            label: "text-[#6B7280] before:border-[#D1D5DB] peer-placeholder-shown:text-[#6B7280] after:border-[#D1D5DB] peer-focus:text-[#6B7280] peer-focus:before:border-[#D1D5DB] peer-focus:after:border-[#D1D5DB] peer-disabled:peer-placeholder-shown:text-[#6B7280]"
        }
    }

    const errorClasses = {
        textarea: "border-red-500 text-red-500 placeholder-shown:border-red-500 placeholder-shown:border-t-red-500 disabled:bg-red-500 focus:border-red-500",
        label: "text-red-500 before:border-red-500 peer-placeholder-shown:text-red-500 after:border-red-500 peer-focus:text-red-500 peer-focus:before:border-red-500 peer-focus:after:border-red-500 peer-disabled:peer-placeholder-shown:text-red-500"
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error } }) => (
                <div className={className}>
                    <div className="relative w-full min-w-[200px]">
                        <textarea
                            {...field}
                            className={cn(
                                baseClasses.textarea,
                                error ? errorClasses.textarea : variants[variant].textarea
                            )}
                            placeholder=" "
                        />
                        {error && (
                            <span className="text-red-500 absolute left-0 -bottom-5 text-xs">
                                {error.message || 'Error'}
                            </span>
                        )}
                        <label
                            className={cn(
                                baseClasses.label,
                                error ? errorClasses.label : variants[variant].label
                            )}
                        >
                            {label || "Message"}
                        </label>
                    </div>
                </div>
            )}
        />
    )
}

export default TextArea
