import React from 'react'

const SectionHeadline = ({
    title,
    desc,
    light = false
}) => {
    return (
        <div>
            <p className={`${light ? 'text-white' : 'text-gray-600'} uppercase font-semibold`}>
                {title}
            </p>
            <h1 className={`${light ? 'text-white' : 'text-gray-900'} text-4xl md:text-5xl mt-3 font-semibold md:font-bold`}>
                {desc}
            </h1>
        </div>
    )
}

export default SectionHeadline