import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null,
}

export const selectedReportTypeSlice = createSlice({
  name: 'selectedReportType',
  initialState,
  reducers: {
    setSelectedReportType: (state, action) => {
      state.value = action.payload
    },
    clearSelectedReportType: (state) => {
      state.value = null
    },
  },
})

export const { setSelectedReportType, clearSelectedReportType } = selectedReportTypeSlice.actions

export default selectedReportTypeSlice.reducer