import React from 'react'
import InnerHeader from '../../../common/inner/InnerHeader'
import Breadcrumb from '../../../common/inner/Breadcrumb'
import SupportBanner from '../../../common/inner/SupportBanner'
import Headline from '../../../common/inner/Headline'
import LogoutModal from '../profile/LogoutModal'

const HelpHOC = ({ crumbs, title, desc, banner = true, children }) => {

    return (
        <div>
            <InnerHeader />
            <Breadcrumb crumbs={crumbs} />

            <div className='px-4 md:px-60 pt-8 md:pt-20 w-full max-w-screen-2xl mx-auto'>
                {(title || desc) && <Headline title={title} desc={desc} />}
                <div className='w-full mt-10 pb-10 md:pb-0 px-2 md:px-0'>
                    {children}
                </div>
                {banner && <SupportBanner />}
            </div>
            <LogoutModal/>
        </div>
    )
}

export default HelpHOC