import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    whiteStrap: false,
    canvasModal: false,
    explorerModal: false,
    loginModal: false,
    mapBreadcrumb: true,
    mapSlide: false,
    cancelModal: false,
    logoutModal: false,
    deleteProfileModal: false,
    singleBuildingModal: false,
   
}

export const toggleSlice = createSlice({
    name: 'toggle',
    initialState,
    reducers: {
        toggleWhiteStrap: (state, action) => {
            state.whiteStrap = action.payload
        },
        toggleCanvasModal: (state) => {
            state.canvasModal = !state.canvasModal
        },
        toggleExplorerModal: (state) => {
            state.explorerModal = !state.explorerModal
        },
        toggleLoginModal: (state) => {
            state.loginModal = !state.loginModal
        },
        toggleMapBreadcrumb: (state, action) => {
            state.mapBreadcrumb = action.payload
        },
        toggleMapSlide: (state) => {
            state.mapSlide = !state.mapSlide
        },
        toggleCancelModal: (state) => {
            state.cancelModal = !state.cancelModal
        },
        toggleLogoutModal: (state) => {
            state.logoutModal = !state.logoutModal
        },
        toggleDeleteProfileModal: (state) => {
            state.deleteProfileModal = !state.deleteProfileModal
        },
        toggleSingleBuildingModalModal: (state) => {
            state.singleBuildingModal = !state.singleBuildingModal
        },
        
    },
})

export const {
    toggleWhiteStrap,
    toggleCanvasModal,
    toggleExplorerModal,
    toggleLoginModal,
    toggleMapBreadcrumb,
    toggleMapSlide,
    toggleCancelModal,
    toggleLogoutModal,
    toggleDeleteProfileModal,
    toggleSingleBuildingModalModal,
} = toggleSlice.actions

export default toggleSlice.reducer