import React, { useEffect, useRef, useState } from "react";
import MapTopBar from "../../components/common/inner/MapTopBar";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedInData } from "../../store/slices/api/auth/loginSlice";
import { clearPlaceData, clearSolarData } from "../../store/slices/api/misc/autoCompleteSlice";
import { clearSelectedArea } from "../../store/slices/common/selectedAreaSlice";
import { useReactToPrint } from "react-to-print";
import ReportHOC, { ReportTitle } from "../../components/pages/inner/hoc/ReportHOC";
import { toggleCanvasModal } from "../../store/slices/common/toggleSlice";
import { reportImage, selectReportImageData, setReportImageData } from "../../store/slices/api/inner/reportSlice";
import AddressLink from "./AddressLink";
import ClientDetails from "./ClientDetails";
import { getPredominantPitch } from "../../lib/convertPitch";
import generateWasteTable from "../../lib/generateWasteTable";
import html2pdf from "html2pdf.js";
import { toast } from "react-toastify";
import useApiRequest from "../../store/slices/hook/useApiRequest";

export default function ExplorerReport() {
  const dispatch = useDispatch();
  const params = useParams();
  const reportLat = localStorage.getItem("reportLat");
  const reportLng = localStorage.getItem("reportLng");
  const { state } = useLocation();
  const loggedInData = useSelector(selectLoggedInData);
  const reportData = useSelector(selectReportImageData);
  const [isDownloadLoading, setDownloadLoading] = useState(false);

  const { isLoading: reportImageLoading, handleSubmit } = useApiRequest({
    apiAction: reportImage,
    setValueAction: setReportImageData,
    isForm: false,
    successMessage: false,
  });

  const handleImageDownload = async () => {
    await handleSubmit({
      reportId: params.reportId,
      lat: reportLat,
      lng: reportLng,
    });
  };

  useEffect(() => {
    if (reportData?.length === 0) {
      handleImageDownload();
    }
  }, []);

  const toggleModal = async () => await dispatch(toggleCanvasModal());

  const pdfRef = useRef(null);
  const handleGeneratePDF = () => {
    const element = pdfRef.current;
    setDownloadLoading(true);
    html2pdf()
      .from(element)
      .set({
        margin: [0, 0],
        filename: `${reportData?.buildingName || "report"}-explorer.pdf`,
        html2canvas: { scale: 2, useCORS: true, allowTaint: true },
        jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4' },
        compressPDF: true,
      })
      .save()
      .then(() => {
        dispatch(clearSelectedArea());
        dispatch(clearSolarData());
        dispatch(clearPlaceData());
        toast.success("Report downloaded successfully!");
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("Error during PDF generation:", error);
        toast.error("Sorry, something went wrong!");
        setDownloadLoading(false);
      });
  };

  const printPDF = useReactToPrint({
    content: () => pdfRef?.current,
  });

  const handlePrintPDF = async () => {
    await printPDF();
    dispatch(clearSelectedArea());
    dispatch(clearSolarData());
    dispatch(clearPlaceData());
  };

  // Only proceed if reportData is available
  if (reportImageLoading || !reportData) {
    return <div>Loading...</div>;
  }

  const result = getPredominantPitch(reportData?.roofSegmentStats || []);
  const totalAreaInFeet = Math.ceil((reportData?.wholeRoofStats?.areaMeters2 || 0) * 10.7639);
  const { percentages, areas, squares } = generateWasteTable(totalAreaInFeet);

  return (
    <section>
      <MapTopBar head="Explorer Report" designLink={state?.designLink} reportLink={state?.reportLink} isDownloadLoading={isDownloadLoading} title="Explorer Report" toggleModal={toggleModal} handleDownload={handleGeneratePDF} handlePrint={handlePrintPDF} />

      <div className="py-20 bg-neutral-50">
        <div ref={pdfRef} className="flex flex-col justify-center items-center">

          <ReportHOC head="Explorer Report" pageNumber={1}>
            <div className='w-full mx-auto mt-6 h-[500px] overflow-hidden relative'>
              <img
                crossOrigin="anonymous"

                loading="lazy"
                src={reportData?.file ?? "/assets/placeholder.jpg"}
                className="w-full h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover"
                alt="Description"
              />
            </div>
            <div className="flex justify-between gap-6 mt-6">
              <div className="w-1/2">
                <ReportTitle title="Prepared for" />
                <ClientDetails loggedInData={loggedInData} />
              </div>
              <div className="w-1/2">
                <ReportTitle title="Table of Contents" />
                <div className="mt-5">
                  <ul className="leaders">
                    <li><span>Roof details</span> <span>2</span></li>
                    <li><span>Online maps</span> <span>3</span></li>
                    <li><span>Important legal notice & disclaimer</span> <span>4</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </ReportHOC>

          <ReportHOC head="Explorer Report" pageNumber={2} title="Images">
            <div className="mt-6 text-sm text-zinc-900">
              The following aerial images show different angles of this structure
              for your reference.
            </div>
            <div className='w-full mx-auto mt-6 h-[500px] overflow-hidden relative'>
              <img

                crossOrigin="anonymous"

                loading="lazy"
                src={reportData?.file ?? "/assets/placeholder.jpg"}
                className="w-full h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover"
                alt="Description"
              />
            </div>
            <div className="font-semibold mt-3 uppercase">
              Top View
            </div>
          </ReportHOC>

          <ReportHOC head="Explorer Report" pageNumber={3} title="Images">
            {/* 
            North: 2,
            South: 0,
            East: 1,
            West: 3,
            */}
            <div className="grid grid-cols-2 gap-6 mt-6">
              <div className="flex flex-col grow font-semibold uppercase text-zinc-900">
                <img
                  crossOrigin="anonymous"

                  loading="lazy"
                  alt="north side"
                  src={reportData?.reportImages?.[2].imageUrl ?? "/assets/placeholder.jpg"}
                  className="w-full aspect-[1.40]"
                />
                <div className="w-full text-center px-3 pt-3 bg-white">
                  North Side
                </div>
              </div>
              <div className="flex flex-col grow font-semibold uppercase text-zinc-900">
                <img
                  crossOrigin="anonymous"

                  loading="lazy"
                  alt="south side"
                  src={reportData?.reportImages?.[0].imageUrl ?? "/assets/placeholder.jpg"}
                  className="w-full aspect-[1.40]"
                />
                <div className="w-full text-center px-3 pt-3 bg-white">
                  South Side
                </div>
              </div>
              <div className="flex flex-col grow font-semibold uppercase text-zinc-900">
                <img
                  crossOrigin="anonymous"

                  loading="lazy"
                  alt="east side"
                  src={reportData?.reportImages?.[1].imageUrl ?? "/assets/placeholder.jpg"}
                  className="w-full aspect-[1.40]"
                />
                <div className="w-full text-center px-3 pt-3 bg-white">
                  East Side
                </div>
              </div>
              <div className="flex flex-col grow font-semibold uppercase text-zinc-900">
                <img
                  crossOrigin="anonymous"

                  loading="lazy"
                  alt="west side"
                  src={reportData?.reportImages?.[3].imageUrl ?? "/assets/placeholder.jpg"}
                  className="w-full aspect-[1.40]"
                />
                <div className="w-full text-center px-3 pt-3 bg-white">
                  West Side
                </div>
              </div>
            </div>
          </ReportHOC>

          <ReportHOC head="Explorer Report" pageNumber={4} title="Length Diagram">
            <p className="mt-6 font-semibold">Total Line Lengths:</p>

            <div className="mt-3 flex gap-6">
              <div className="w-full flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <div>Ridges:</div>
                  <div className="font-semibold">{reportData?.ridgeLength ?? 0} ft</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Hips:</div>
                  <div className="font-semibold">{reportData?.hipLength ?? 0} ft</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Valleys:</div>
                  <div className="font-semibold">{reportData?.valleyLength ?? 0} ft</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Rakes:</div>
                  <div className="font-semibold">{reportData?.rakeLength ?? 0} ft</div>
                </div>
              </div>
              <div className="w-full flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <div>Eaves:</div>
                  <div className="font-semibold">{reportData?.eaveLength ?? 0} ft</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Parapets:</div>
                  <div className="font-semibold">{reportData?.parapetLength ?? 0} ft</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Flashing:</div>
                  <div className="font-semibold">{reportData?.flashingLength ?? 0} ft</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Step Flashing:</div>
                  <div className="font-semibold">{reportData?.stepFlashingLength ?? 0} ft</div>
                </div>
              </div>

            </div>
            <div className='w-full mx-auto mt-10 h-[500px] overflow-hidden relative'>
              <img
                crossOrigin="anonymous"

                loading="lazy"
                src={reportData?.lengthDiagram ?? "/assets/report/diagram1.png"}
                className="w-full h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover"
                alt="Description"
              />
            </div>
            {/* <img
              loading="lazy"
              src="/assets/report/diagram1.png"
              className="mt-10 w-full aspect-[1.59]"
            /> */}
            <div className="text-xs w-full absolute bottom-6">
              Note: This diagram contains segment lengths (rounded to the nearest
              whole number) over 5.0 Feet. In some cases, segment labels have been
              removed for readability. Plus signs preface some numbers to avoid
              confusion when rotated (e.g. +6 and +9).
            </div>
          </ReportHOC>

          <ReportHOC head="Explorer Report" pageNumber={5} title="Pitch Diagram">
            <p className="mt-6">
              Pitch values are shown in inches per foot, and arrows indicate slope
              direction.
              <br />
              The predominant pitch on this roof is {result?.predominantPitch}
            </p>
            <div className='w-full mx-auto mt-10 h-[500px] overflow-hidden relative'>
              <img

                crossOrigin="anonymous"

                loading="lazy"
                src={reportData?.pitchDiagram ?? "/assets/report/diagram2.png"}
                className="w-full h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover"
                alt="Description"
              />
            </div>
            {/* <img
              loading="lazy"
              src={reportData?.pitchDiagram ?? "/assets/report/diagram2.png"}
              className="mt-10 w-full aspect-[1.59]"
            /> */}
            <div className="text-xs w-full absolute bottom-6">
              Note: This diagram contains labeled pitches for facet areas larger
              than 20.0 square feet. In some cases, pitch labels have been removed
              for readability. Blue shading indicates a pitch of 3/12 and greater.
            </div>
          </ReportHOC>

          <ReportHOC head="Explorer Report" pageNumber={6} title="Area Diagram">
            <p className="mt-6">
              {/* Total Area = {(reportData?.wholeRoofStats?.areaMeters2 * 10.7639).toFixed(2)} sq ft, with {reportData?.roofSegmentStats?.length} facets. */}
              Total Area = {totalAreaInFeet} sq ft, with {reportData?.roofSegmentStats?.length} facets.
            </p>
            <div className='w-full mx-auto mt-10 h-[500px] overflow-hidden relative'>
              <img

                crossOrigin="anonymous"

                loading="lazy"
                src={reportData?.areaDiagram ?? "/assets/report/diagram3.png"}
                className="w-full h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover"
                alt="Description"
              />
            </div>
            {/* <img
              loading="lazy"
              
              className="mt-10 w-full aspect-[1.59]"
            /> */}
            <div className="text-xs w-full absolute bottom-6">
              Note: This diagram shows the square feet of each roof facet (rounded
              to the nearest Foot). The total area in square feet, at the top of
              this page, is based on the non-rounded values of each roof facet
              (rounded to the nearest square foot after being totaled).
            </div>
          </ReportHOC>

          <ReportHOC head="Explorer Report" pageNumber={7} title="Notes Diagram">
            <p className="mt-6">
              Roof facets are labeled from smallest to largest (A to Z) for easy
              reference.
            </p>
            <div className='w-full mx-auto mt-10 h-[500px] overflow-hidden relative'>
              <img
                crossOrigin="anonymous"

                loading="lazy"
                src={reportData?.notesDiagram ?? "/assets/report/diagram4.png"}
                className="w-full h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover"
                alt="Description"
              />
            </div>
            {/* <img
              loading="lazy"
              src={reportData?.notesDiagram ?? "/assets/report/diagram4.png"}
              className="mt-10 w-full aspect-[1.59]"
            /> */}
            <div className="text-xs w-full absolute bottom-6">
              Note: This diagram shows the square feet of each roof facet (rounded to the nearest Foot). The total area in square feet, at the top of this page, is based on the non-rounded values of each roof facet (rounded to the nearest square foot after being totaled).
            </div>
          </ReportHOC>

          <ReportHOC head="Explorer Report" pageNumber={8} title="Report Summary">
            <p className="mt-3 font-semibold text-xs">
              All Structures
            </p>
            <p className="mt-3 pb-2 font-semibold text-xs uppercase border-b border-zinc-900">
              Areas per Pitch
            </p>
            <table className="w-full mt-1">
              <tr className="text-xs">
                <td className="w-1/5 font-semibold">Roof Pitches</td>
                {result?.pitches?.slice(0, 10)?.map((pitch, index) => (
                  <td className="text-right" key={index}>{pitch}</td>
                ))}
              </tr>
              <tr className="text-xs">
                <td className="w-1/5 font-semibold">Area (sq ft)</td>
                {reportData?.roofSegmentStats?.slice(0, 10)?.map((segment, index) => (
                  <td className="text-right" key={index}>
                    {Math.ceil(segment?.stats?.areaMeters2 * 10.7639)}
                  </td>
                ))}
              </tr>
              <tr className="text-xs">
                <td className="w-1/5 font-semibold">% of Roof</td>
                {reportData?.roofSegmentStats?.slice(0, 10)?.map((segment, index) => (
                  <td className="text-right" key={index}>
                    {(
                      (segment?.stats?.areaMeters2 / reportData?.wholeRoofStats?.areaMeters2 * 100)
                    ).toFixed(1)}%
                  </td>
                ))}
              </tr>
            </table>

            <div className="text-xs mt-1">
              The table above lists each pitch on this roof and the total area and
              percent (both rounded) of the roof with that pitch
            </div>


            <p className="mt-3 pb-2 font-semibold text-xs uppercase border-b border-zinc-900">
              Structure Complexity
            </p>
            <table className="w-full mt-1">
              <tr className="text-xs">
                <td>Simple</td>
                <td className="text-center">Normal</td>
                <td className="text-right">Complex</td>
              </tr>
            </table>


            <div className="mt-3 pb-2 text-xs border-b border-zinc-900">
              <p className="font-semibold uppercase">Waste Calculation</p>
              <p>NOTE: This waste calculation table is for asphalt shingle roofing
                applications. All values in table below only include roof areas of
                3/12 pitch or greater. For total measurements of all pitches, please
                refer to the Lengths, Areas, and Pitches section below.</p>
            </div>
            <table className="w-full mt-1 text-xs text-center">
              <thead>
                <tr>
                  <td>Waste %</td>
                  {percentages?.map((perc, index) => (
                    <td key={index} className={(perc === 0 || perc === 10) ? 'font-semibold' : ''}>{perc}%</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Area (Sq ft)</td>
                  {areas?.map((area, index) => (
                    <td key={index} className={(percentages[index] === 0 || percentages[index] === 10) ? 'font-semibold' : ''}>{area}</td>
                  ))}
                </tr>
                <tr>
                  <td>Squares *</td>
                  {squares?.map((square, index) => (
                    <td key={index} className={(percentages[index] === 0 || percentages[index] === 10) ? 'font-semibold' : ''}>{square}</td>
                  ))}
                </tr>
                <tr>
                  <td></td>
                  <td className="font-semibold">Measured</td>
                  <td></td>
                  <td></td>
                  <td className="font-semibold">Suggested</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>


            <div className="mt-3 text-xs">
              * Squares are rounded up to the 1/3 of a square
              <br />
              Additional materials needed for ridge, hip, and starter lengths are
              not included in the above table. The provided suggested waste factor
              is intended to serve as a guide—actual waste percentages may differ
              based upon several variables that EagleView does not control. These
              waste factor variables include, but are not limited to, individual
              installation techniques, crew experiences, asphalt shingle material
              subtleties, and potential salvage from the site. Individual results
              may vary from the suggested waste factor that EagleView has provided.
              The suggested waste is not to replace or substitute for experience or
              judgment as to any given replacement or repair work.
            </div>

            <p className="mt-3 font-semibold text-xs">
              All Structures Totals
            </p>

            <div className="flex mt-3 gap-10">
              <div className="w-full max-w-[158px] text-xs">

                <div className='w-full mx-auto h-[200px] overflow-hidden relative'>
                  <img
                    crossOrigin="anonymous"
                    loading="lazy"
                    src={reportData?.planeDiagram ?? "/assets/report/diagram5.png"}
                    className="w-full h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover"
                    alt="Description"
                  />
                </div>

                <div className="mt-4">Total Roof Facets = {reportData?.roofSegmentStats?.length}</div>
              </div>

              <div className="w-full text-xs">
                <div className="font-semibold uppercase">
                  Lengths, Areas and Pitches
                </div>
                <div className="mt-3">
                  Ridges = {reportData?.ridgeLength ?? 0} ft ({reportData?.ridgeCount ?? 0} Ridges)
                  <br />
                  Hips = {reportData?.hipLength ?? 0} ft ({reportData?.hipCount} Hips).
                  <br />
                  Valleys = {reportData?.valleyLength ?? 0} ft ({reportData?.valleyCount} Valleys)
                  <br />
                  Rakes† = {reportData?.rakeLength ?? 0} ft ({reportData?.rakeCount} Rakes)
                  <br />
                  Eaves/Starter‡ = {reportData?.eaveLength ?? 0} ft ({reportData?.eaveCount} Eaves)
                  <br />
                  Drip Edge (Eaves + Rakes) = {+reportData?.eaveLength + +reportData?.rakeLength} ft (${+reportData?.eaveCount + +reportData?.rakeCount} Lengths)
                  <br />
                  Parapet Walls = {reportData?.parapetLength ?? 0} (0 Lengths).
                  <br />
                  Flashing = {reportData?.flashingLength ?? 0} ft ({reportData?.flashingCount ?? 0} Lengths)
                  <br />
                  Step flashing = {reportData?.stepFlashingLength ?? 0} ft ({reportData?.stepFlashingCount ?? 0} Lengths)
                  <br />
                  Predominant Pitch = {result.predominantPitch}
                  <br />
                  <span className="font-bold">
                    Total Area (All Pitches) = {Math.ceil(reportData?.wholeRoofStats?.areaMeters2 * 10.7639)} sq ft
                  </span>
                </div>
              </div>

              <div className="w-full text-xs uppercase">
                <div className="font-semibold leading-[135%] text-zinc-900">
                  Property Location
                </div>
                <div className="mt-2">
                  Longitude = {reportData?.coordinates?.[0]?.lng}
                  <br />
                  Latitude = {reportData?.coordinates?.[0]?.lat}
                  <br />
                  <span className="font-semibold uppercase">Notes</span>
                  <br />
                  This was ordered as a residential
                  <br />
                  property. There were no changes to
                  <br />
                  the structure in the past four years.
                  <br />
                  <br />
                  Rakes are defined as roof edges that are sloped (not level).
                  <br />
                  <br />
                  Eaves are defined as roof edges that are not sloped and level.
                </div>
              </div>
            </div>
          </ReportHOC>

          <ReportHOC head="Explorer Report" pageNumber={9} title="Online Maps">
            {/* <AddressLink from={loggedInData?.contactInfo?.address ?? "current location"} to={reportData?.buildingName} /> */}

            <AddressLink from={"Premier Choice Exteriors, 4580 Mack Ave Suite G, Frederick, MD 21703, United States"} to={reportData?.buildingName} />
          </ReportHOC>
          <ReportHOC head="Explorer Report" pageNumber={10} title="IMPORTANT LEGAL NOTICE & DISCLAIMER">
            <div className="mt-6 text-xs font-semibold leading-5 text-zinc-900 max-md:max-w-full">
              Notice and Disclaimer
            </div>
            <div className="mt-3 text-xs leading-5 text-zinc-900 max-md:max-w-full">
              No Warranty: The Copyrighted Materials are provided to you "as is," and you agree to use it at your own risk.
            </div>
            <div className="mt-3 text-xs leading-5 text-zinc-900 max-md:max-w-full">
              RoofRavel Technologies makes no guarantees, representations or warranties of any kind, express or implied, arising by law or otherwise, including but not limited to, content, quality, accuracy, completeness, effectiveness, reliability, fitness for a particular purpose, usefulness, use or results to be obtained from the Copyrighted Materials.
            </div>
            <div className="mt-3 text-xs leading-5 text-zinc-900 max-md:max-w-full">
              Contractors agree to always conduct a preliminary site survey to verify Roof Report ordered. In the event of an error in a Report, your sole remedy will be a refund of the fees paid by you to obtain this Report.
            </div>
          </ReportHOC>

        </div>
      </div>
    </section>

  );
}

