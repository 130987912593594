import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { NAVIGATION } from "../lib/navigation";

import { HomePage } from "../pages/public/HomePage";
import AboutPage from "../pages/public/AboutPage";
import FeaturesPage from "../pages/public/FeaturesPage";
import ContactPage from "../pages/public/ContactPage";
import FindRoofPage from "../pages/public/FindRoofPage";

import LoginPage from "../pages/auth/LoginPage";
import SignUpPage from "../pages/auth/SignUpPage";
import VerifyEmail from "../pages/auth/VerifyEmail";
import ForgetPassword from "../pages/auth/ForgetPassword";
import ResetPassword from "../pages/auth/ResetPassword";

import Dashboard from "../pages/inner/Dashboard";

import FindLocation from "../pages/inner/canvas/FindLocation";
import CanvasMap from "../pages/inner/canvas/CanvasMap";

import FindRoof from "../pages/inner/explorer/FindRoof";
import ExplorerMap from "../pages/inner/explorer/ExplorerMap";
import ExplorerInfo from "../pages/inner/explorer/ExplorerInfo";

import XactimateInfo from "../pages/inner/xactimate/XactimateInfo";

import CanvasReport from "../pages/inner/CanvasReport";
import CanvasReport2 from "../pages/inner/CanvasReport2";
import ExplorerReport from "../pages/inner/ExplorerReport";

import Plans from "../pages/inner/subscription/Plans";
import Checkout from "../pages/inner/subscription/Checkout";

import EditProfile from "../pages/inner/profile/EditProfile";
import ChangePassword from "../pages/inner/profile/ChangePassword";
import ContactInformation from "../pages/inner/profile/ContactInformation";
import BillingInformation from "../pages/inner/profile/BillingInformation";
import MyPurchase from "../pages/inner/profile/MyPurchase";

import HelpCenter from "../pages/inner/helpCenter/HelpCenter";
import SingleHelp from "../pages/inner/helpCenter/SingleHelp";
import HelpRequest from "../pages/inner/helpCenter/HelpRequest";

import PrivateRoutes from "./PrivateRoutes";
import { useDispatch, useSelector } from "react-redux";
import { profile, selectLoggedInData, setLoginData } from "../store/slices/api/auth/loginSlice";
import { useEffect } from "react";
import MyCredit from "../pages/inner/profile/MyCredit";
import CanvasDraw from "../pages/inner/canvas/CanvasDraw";
import CanvasReportList from "../pages/inner/canvas/CanvasReportList";
import ExplorerReportList from "../pages/inner/explorer/ExplorerReportList";
import XactimateReportList from "../pages/inner/xactimate/XactimateReportList";
import ThanksPage from "../pages/inner/ThanksPage";

const MainRoutes = () => {

    const dispatch = useDispatch();
    const authToken = localStorage.getItem("authToken");
    //todo: const isEmailVerify = localStorage.getItem("isEmailVerify")
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const loggedInData = useSelector(selectLoggedInData);

    const isLoggedIn = async () => {
        try {
            if (!loggedInData || !loggedInData === null) {
                const response = await dispatch(profile());
                await dispatch(setLoginData(response?.payload?.data));
                if (
                    pathname &&
                    pathname.includes('/auth/')
                ) {
                    navigate(NAVIGATION.INNER_NAVIGATION.DASHBOARD)
                }
            } else {
                await dispatch(setLoginData(null))
            }
        } catch (error) {
            await dispatch(setLoginData(null))
            console.log("Error in isLoggedIn : ", error);
        }
    };

    useEffect(() => {
        if (authToken && (!loggedInData || loggedInData === null)) {
            isLoggedIn();
        }
    }, [authToken, loggedInData]);

    return (
        <Routes>

            {/* public pages */}
            <Route path={NAVIGATION.HOME} element={<HomePage />} />
            <Route path={NAVIGATION.FIND_ROOF} element={<FindRoofPage />} />
            <Route path={NAVIGATION.ABOUT} element={<AboutPage />} />
            <Route path={NAVIGATION.FEATURES} element={<FeaturesPage />} />
            <Route path={NAVIGATION.CONTACT} element={<ContactPage />} />
            <Route path={NAVIGATION.THANKS} element={<ThanksPage />} />

            {/* auth pages */}
            <Route path={NAVIGATION.AUTH_NAVIGATION.LOGIN} element={<LoginPage />} />
            <Route path={NAVIGATION.AUTH_NAVIGATION.SIGNUP} element={<SignUpPage />} />
            <Route path={NAVIGATION.AUTH_NAVIGATION.VERIFY_EMAIL} element={<VerifyEmail />} />
            <Route path={NAVIGATION.AUTH_NAVIGATION.FORGET_PASSWORD} element={<ForgetPassword />} />
            <Route path={`${NAVIGATION.AUTH_NAVIGATION.RESET_PASSWORD}/:userId`} element={<ResetPassword />} />

            <Route element={<PrivateRoutes />}>

                {/* inner pages */}
                <Route path={NAVIGATION.INNER_NAVIGATION.DASHBOARD} element={<Dashboard />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT_LIST} element={<CanvasReportList />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.EXPLORER_REPORT_LIST} element={<ExplorerReportList />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.XACTIMATE_REPORT_LIST} element={<XactimateReportList />} />

                {/* canvas pages */}
                <Route path={`${NAVIGATION.INNER_NAVIGATION.CANVAS.FIND_LOCATION}/:reportId`} element={<FindLocation />} />
                <Route path={`${NAVIGATION.INNER_NAVIGATION.CANVAS.CANVAS_MAP}/:reportId`} element={<CanvasMap />} />
                <Route path={`${NAVIGATION.INNER_NAVIGATION.CANVAS.CANVAS_DRAW}`} element={<CanvasDraw />} />

                {/* explorer pages */}
                <Route path={`${NAVIGATION.INNER_NAVIGATION.EXPLORER.FIND_ROOF}/:reportId`} element={<FindRoof />} />
                <Route path={`${NAVIGATION.INNER_NAVIGATION.EXPLORER.EXPLORER_MAP}/:reportId`} element={<ExplorerMap />} />
                <Route path={`${NAVIGATION.INNER_NAVIGATION.EXPLORER.EXPLORER_INFO}/:reportId`} element={<ExplorerInfo />} />

                {/* xactimate pages */}
                <Route path={`${NAVIGATION.INNER_NAVIGATION.XACTIMATE.XACTIMATE_INFO}/:reportId`} element={<XactimateInfo />} />

                {/* misc */}
                <Route path={`${NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT}/:reportId`} element={<CanvasReport />} />
                <Route path={`${NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT2}/:reportId`} element={<CanvasReport2 />} />
                <Route path={`${NAVIGATION.INNER_NAVIGATION.EXPLORER_REPORT}/:reportId`} element={<ExplorerReport />} />

                {/* subscription pages */}
                <Route path={NAVIGATION.INNER_NAVIGATION.SUBSCRIPTION.PLANS} element={<Plans />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.SUBSCRIPTION.CHECKOUT} element={<Checkout />} />

                {/* profile pages */}
                <Route path={NAVIGATION.INNER_NAVIGATION.PROFILE.EDIT_PROFILE} element={<EditProfile />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.PROFILE.CHANGE_PASSWORD} element={<ChangePassword />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.PROFILE.CONTACT_INFORMATION} element={<ContactInformation />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.PROFILE.BILLING_INFORMATION} element={<BillingInformation />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.PROFILE.MY_PURCHASE} element={<MyPurchase />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.PROFILE.MY_CREDIT} element={<MyCredit />} />

                {/* help pages */}
                <Route path={NAVIGATION.INNER_NAVIGATION.HELP_CENTER.MAIN} element={<HelpCenter />} />
                <Route path={`${NAVIGATION.INNER_NAVIGATION.HELP_CENTER.SINGLE_HELP}/:helpId`} element={<SingleHelp />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.HELP_CENTER.REQUEST} element={<HelpRequest />} />

            </Route>

        </Routes>
    )
}

export default MainRoutes