import React from 'react'

const ClientDetails = ({
    loggedInData
}) => {
    return (
        <table className="mt-2 w-full text-zinc-900">
            <tr>
                <td className="py-2 w-[30%]">Name:</td>
                <td className="py-2 font-semibold">{loggedInData?.firstName} {loggedInData?.lastName ?? ""}</td>
            </tr>
            <tr>
                <td className="py-2">Email:</td>
                <td className="py-2 font-semibold">{loggedInData?.email}</td>
            </tr>
            <tr>
                <td className="py-2">Phone:</td>
                <td className="py-2 font-semibold">{loggedInData?.contactInfo?.phone ?? ""}</td>
            </tr>
            <tr>
                <td className="py-2">Company:</td>
                <td className="py-2 font-semibold">{loggedInData?.contactInfo?.company ?? ""}</td>
            </tr>
            <tr>
                <td className="py-2 flex">Address:</td>
                <td className="py-2 font-semibold">{loggedInData?.contactInfo?.address ?? ""}</td>
            </tr>
        </table>
    )
}

export default ClientDetails