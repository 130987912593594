import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: [],
};

export const fetchDashboardCard = createAsyncThunk(
    'user/dashboardCard',
    async (formData) => {
        try {
            const response = await myAxios.get(`user/dashboard`);
            return { success: true, message: "Report types fetched successfully!", data: response?.data?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);


const dashboardCardSlice = createSlice({
    name: 'dashboardCard',
    initialState,
    reducers: {
        clearDashboardCardData: state => {
            state.data = [];
        },
        setDashboardCardData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const dashboardCardReducer = dashboardCardSlice.reducer;
export const { clearDashboardCardData, setDashboardCardData } = dashboardCardSlice.actions;

export const selectDashboardCardData = (state) => state.dashboardCard.data;
