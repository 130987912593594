import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null,
}

export const selectedBuildingSlice = createSlice({
  name: 'selectedBuilding',
  initialState,
  reducers: {
    setSelectedBuilding: (state, action) => {
      state.value = action.payload
    },
    clearSelectedBuilding: (state) => {
      state.value = null
    },
  },
})

export const { setSelectedBuilding, clearSelectedBuilding } = selectedBuildingSlice.actions

export default selectedBuildingSlice.reducer