import React from 'react'
import InnerHeader from '../../../common/inner/InnerHeader'
import InnerFooter from '../../../common/inner/InnerFooter'
import LogoutModal from '../profile/LogoutModal'

const DashboardHOC = ({ children }) => {
    return (
        <div className='min-h-screen flex flex-col'>
            <InnerHeader />
            <div className='flex-1'>
                {children}
            </div>
            <LogoutModal/>
            <InnerFooter />
        </div>
    )
}

export default DashboardHOC