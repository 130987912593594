export function convertPitchDegreesToPitch12(pitchDegrees) {
    const radians = pitchDegrees * (Math.PI / 180);
    const pitch = Math.tan(radians) * 12;
    return Math.ceil(pitch) + "/12";
}

export function getPredominantPitch(roofSegmentStats) {
    const pitches = roofSegmentStats.map(segment => convertPitchDegreesToPitch12(segment.pitchDegrees));

    const pitchCounts = pitches.reduce((counts, pitch) => {
        counts[pitch] = (counts[pitch] || 0) + 1;
        return counts;
    }, {});

    let predominantPitch = null;
    let maxCount = 0;
    for (const [pitch, count] of Object.entries(pitchCounts)) {
        if (count > maxCount) {
            maxCount = count;
            predominantPitch = pitch;
        }
    }

    return {
        pitches,
        predominantPitch
    };
}
