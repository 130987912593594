import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../common/inner/Breadcrumb'
import DashboardHOC from '../hoc/DashboardHOC'
import Button from '../../../common/Button'
import { MdOutlineSearch } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux'
import useApiRequest from '../../../../store/slices/hook/useApiRequest'
import { fetchReportList, selectReportListData, setReportListData } from '../../../../store/slices/api/inner/reportListSlice'
import EmptyList from '../canvas/EmptyList';
import { FaCloudDownloadAlt } from "react-icons/fa";
import { reportImage, setReportImageData } from '../../../../store/slices/api/inner/reportSlice';
import { NAVIGATION } from '../../../../lib/navigation';
import { useNavigate } from 'react-router-dom';
import { clearSelectedCoordinates } from '../../../../store/slices/common/selectedCoordinatesSlice';

const ReportTable = ({
    selectedTab,
    tabs,
}) => {
    const reportList = useSelector(selectReportListData);
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    // const [filterOpen, setFilterOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [reportId, setReportId] = useState(selectedTab);
    const totalPages = reportList?.totalCount;
    const navigate = useNavigate();
    const [loadingReportId, setLoadingReportId] = useState(null);

    const fetchTabs = async () => {
        await handleTab({
            reportId: selectedTab,
            search,
            limit,
            page,
        })
    }

    useEffect(() => {
        setReportId(selectedTab)
        if(reportList.length === 0){
            fetchTabs();
        }
    }, [selectedTab])

    const handlePreviousPage = () => {
        setPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const { handleSubmit: handleTab } = useApiRequest({
        apiAction: fetchReportList,
        setValueAction: setReportListData,
        successMessage: false,
        isForm: false,
    });

    const handleTabClick = async () => {
        await handleTab({
            reportId,
            search,
            limit,
            page,
        })
    }

    // ! ==> Debouncing
    useEffect(() => {
        setIsLoading(true);
        const getData = setTimeout(async () => {
            await handleTabClick()
            setIsLoading(false);
        }, 300)

        return () => clearTimeout(getData)
    }, [reportId, search, limit, page])

    // useEffect(() => {
    //     handleTabClick()
    // }, [reportId, search, limit, page])


    const afterDownloadImageAPIcall = (response) => {
        dispatch(clearSelectedCoordinates([]))
        navigate(`${NAVIGATION.INNER_NAVIGATION.EXPLORER_REPORT}/${response?._id}`) //TODO change to CANVAS_REPORT
    }

    const { isLoading: reportImageLoading, handleSubmit: handleImageDownload } = useApiRequest({
        apiAction: reportImage,
        setValueAction: setReportImageData,
        isForm: false,
        successMessage: false,
        afterAPICall: afterDownloadImageAPIcall,
    });

    const handleDownloadImage = async (item) => {
        localStorage.setItem("reportLat", item?.coordinates[0].lat)
        localStorage.setItem("reportLng", item?.coordinates[0].lng)
        setLoadingReportId(item._id);
        if (item?.reportTypeNumber === 1) {
            //! for canvas report of multiple buildings
            dispatch(setReportImageData(item))
            navigate(`${NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT}/${item._id}`)
        } else if (item?.reportTypeNumber === 2) {
            //! for canvas report of single building 
            dispatch(setReportImageData(item))
            navigate(`${NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT2}/${item._id}`)
        } else if (item?.reportTypeNumber === 3) {
            //! for explorer report 
            await handleImageDownload({
                lat: item?.coordinates[0].lat,
                lng: item?.coordinates[0].lng,
                reportId: item._id,
            })
        } else {
            setLoadingReportId(null);
        }
        setLoadingReportId(null);
    }


    return (
        <DashboardHOC>
            <Breadcrumb crumbs={[{ title: "Report", href: "" }]} />
            <div className='px-4 pt-10 pb-20 w-full max-w-6xl mx-auto'>
                <div className="w-full">
                    {/* tabs */}
                    <div className='flex flex-col md:flex-row items-center justify-center gap-3'>
                        {/* {tabs.map((tab, index) => (
                            <Button key={index} isLoading={isLoading} loader={false} onClick={() => (setReportId(tab._id), setPage(1))} type="button" variant={reportId === tab?._id ? "default" : "outline"}>
                                {tab.title}
                            </Button>)
                        )} */}
                        {tabs.map((tab, index) => (
                            <Button key={index} isLoading={isLoading} loader={false} onClick={() => navigate(index === 0 ? NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT_LIST
                                : index === 1 ? NAVIGATION.INNER_NAVIGATION.EXPLORER_REPORT_LIST
                                    : index === 2 ? NAVIGATION.INNER_NAVIGATION.XACTIMATE_REPORT_LIST : null)
                            } type="button" variant={reportId === tab?._id ? "default" : "outline"}>
                                {tab.title}
                            </Button>)
                        )}
                    </div>

                    <div className='w-full overflow-x-auto mt-10'>

                        <div className='flex items-center justify-between mb-2'>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none text-gray-500 text-xl">
                                    <MdOutlineSearch />
                                </div>
                                <input type="search" value={search} onChange={(e) => setSearch(e.target.value)} className="block w-full p-3 ps-10 text-sm text-gray-900 border outline-none border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-400 focus:border-gray-400" placeholder="Search by address..." required />
                            </div>

                            {/* <div className='relative'>
                                <button onClick={() => setFilterOpen((prevFilter) => !prevFilter)} className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-2.5" type="button">
                                    <MdFilterList className='mr-2' />
                                    Filter
                                </button>

                                <div id="dropdownRadio" className={`absolute z-10 ${!filterOpen ? "hidden" : ""} w-48 bg-white divide-y divide-gray-100 rounded-lg shadow right-0`}>
                                    <ul className="p-3 space-y-1 text-sm text-gray-700 " aria-labelledby="dropdownRadioButton">
                                        <li>
                                            <label htmlFor='filter-radio-example-1' className="flex items-center p-2 rounded hover:bg-gray-100 ">
                                                <input id="filter-radio-example-1" type="radio" value="" name="filter-radio" className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary" />
                                                <span className="w-full ms-2 text-sm font-medium text-gray-900 rounded">Last day</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor='filter-radio-example-2' className="flex items-center p-2 rounded hover:bg-gray-100 ">
                                                <input id="filter-radio-example-2" type="radio" value="" name="filter-radio" className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary" />
                                                <span className="w-full ms-2 text-sm font-medium text-gray-900 rounded">Last day</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor='filter-radio-example-3' className="flex items-center p-2 rounded hover:bg-gray-100 ">
                                                <input id="filter-radio-example-3" type="radio" value="" name="filter-radio" className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary" />
                                                <span className="w-full ms-2 text-sm font-medium text-gray-900 rounded">Last day</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>

                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                <tr className='bg-gray-100'>
                                    <th scope="col" className="px-6 py-3">
                                        thumbnail
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        address
                                    </th>

                                    {/* <th scope="col" className="px-6 py-3">
                                        file
                                    </th> */}
                                    <th scope="col" className="px-6 py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(reportList?.report && reportList?.report?.length > 0) ? reportList?.report?.map((item, rowIndex) => {
                                    return (
                                        <tr key={rowIndex}>
                                            <td className={`px-6 py-4`}>
                                                <div className='w-40 h-24 rounded-lg overflow-hidden bg-gray-400'>
                                                    <img src={item?.file} alt={item?.buildingName ?? "building"} className="w-full h-full object-cover object-center" />
                                                </div>
                                            </td>
                                            {/* <td className={`px-6 py-4`}>
                                                {item?.reportid?.title}
                                            </td> */}
                                            <td className={`px-6 py-4`}>
                                                {item?.buildingName}
                                            </td>
                                            {/* <td className={`px-6 py-4`}>
                                                <span
                                                    className={`border text-xs px-2 py-1 ${item?.status === "Pending"
                                                        ? "text-yellow-500 bg-yellow-500/10 border-yellow-500 rounded hover:text-yellow-700"
                                                        : "text-green-500 bg-green-500/10 border-green-500 rounded hover:text-green-700"
                                                        }`}
                                                >
                                                    {item?.status}
                                                </span>
                                            </td> */}
                                            {/* <td className={`px-6 py-4`}>
                                                {item?.file ? <a className='text-primary text-2xl' href={item?.file} target='_blank'> <MdFilePresent /></a> : "N/A"}
                                            </td> */}
                                            <td className={`px-6 py-4`}>
                                                <Button type="button" disabled={reportImageLoading} isLoading={loadingReportId === item._id} onClick={() => handleDownloadImage(item)}>
                                                    <FaCloudDownloadAlt />
                                                    Go To Download
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan={5} className='pt-10'>
                                        <EmptyList />
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                    <div className='flex flex-col md:flex-row items-center justify-between mt-2'>
                        <div className='flex gap-4 items-center'>
                            <p className="text-sm text-gray-700">
                                Rows per page:
                            </p>
                            <select onChange={(e) => setLimit(e.target.value)} className="text-gray-900 bg-transparent outline-none text-sm p-2.5">
                                <option selected>10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                        <nav aria-label="Page navigation example">
                            <ul className="inline-flex -space-x-px text-xs md:text-base h-7 md:h-10">
                                <li>
                                    <button
                                        type="button"
                                        disabled={page === 1}
                                        onClick={handlePreviousPage}
                                        className={`flex items-center justify-center px-2 md:px-4 h-7 md:h-10 ${page === 1 ? 'opacity-50 pointer-events-none' : ''
                                            } leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700`}
                                    >
                                        Previous
                                    </button>
                                </li>
                                {/* {[...Array(totalPages)].map((_, index) => (
                                    <li key={index}>
                                        <button
                                            disabled={isLoading}
                                            onClick={() => setPage(index + 1)}
                                            className={`flex items-center justify-center px-2 md:px-4 h-7 md:h-10 disabled:opacity-50 leading-tight ${page === index + 1 ? 'text-primary bg-primary/10' : 'text-gray-500 bg-white'
                                                }  border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                                        >
                                            {index + 1}
                                        </button>
                                    </li>
                                ))} */}
                                <li>
                                    <button
                                        type="button"
                                        onClick={handleNextPage}
                                        disabled={totalPages < limit}
                                        className={`flex items-center justify-center px-2 md:px-4 h-7 md:h-10 ${totalPages < limit ? 'opacity-50 pointer-events-none' : ''
                                            } leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700`}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>

                        {/* <span className="text-sm text-gray-700 mt-2 md:mt-0">
                            Showing <span className="font-semibold text-gray-900">1</span> to <span className="font-semibold text-gray-900">10</span> of <span className="font-semibold text-gray-900">100</span>
                        </span> */}
                    </div>
                </div>
            </div>
        </DashboardHOC>
    )
}

export default ReportTable