import { configureStore } from '@reduxjs/toolkit'
import selectedPlanReducer from './slices/common/selectedPlanSlice'
import selectedAreaReducer from './slices/common/selectedAreaSlice'
import selectedBuildingReducer from './slices/common/selectedBuildingSlice'
import selectedCoordinatesReducer from './slices/common/selectedCoordinatesSlice'
import selectedReportTypeReducer from './slices/common/selectedReportTypeSlice'
import toggleReducer from './slices/common/toggleSlice'

import { loginReducer } from './slices/api/auth/loginSlice'
import { contactReducer } from './slices/api/misc/contactSlice'
import { autoCompleteReducer } from './slices/api/misc/autoCompleteSlice'
import { passwordReducer } from './slices/api/auth/passwordSlice'
import { dashboardCardReducer } from './slices/api/inner/dashboardCardSlice'
import { reportListReducer } from './slices/api/inner/reportListSlice'
import { planReducer } from './slices/api/inner/planSlice'
import { myPurchaseReducer } from './slices/api/inner/myPurchaseSlice'
import { reportReducer } from './slices/api/inner/reportSlice'

export const store = configureStore({
    reducer: {
        selectedPlan: selectedPlanReducer,
        selectedArea: selectedAreaReducer,
        selectedBuilding: selectedBuildingReducer,
        selectedCoordinates: selectedCoordinatesReducer,
        selectedReportType: selectedReportTypeReducer,
        toggle: toggleReducer,

        loginUser: loginReducer,
        password: passwordReducer,

        contactRequest: contactReducer,
        autoComplete: autoCompleteReducer,

        dashboardCard: dashboardCardReducer,
        reportList: reportListReducer,
        plan: planReducer,
        myPurchase: myPurchaseReducer,
        report: reportReducer
    },
})