import React from 'react'
import Section from './Section'
import SectionHeadline from './SectionHeadline'

const DescSection = ({
    title,
    desc,
    gray = false,
    paddingBottom = "pb-0",
    children
}) => {
    return (
        <Section gray={gray} paddingBottom={paddingBottom}>
            <div className='flex flex-col md:flex-row gap-10 md:gap-20'>
                <div className='w-full md:w-1/2'>
                    <SectionHeadline title={title} desc={desc} />
                </div>
                <div className='w-full md:w-1/2 ps-0 md:ps-10 text-gray-600'>
                    {children}
                </div>
            </div>
        </Section>
    )
}

export default DescSection