import React, { useCallback, useEffect, useRef, useState } from 'react';
import ProfileHOC from '../../../components/pages/inner/hoc/ProfileHOC';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyPurchase, selectMyPurchaseData, setMyPurchaseData, setMyPurchaseDataWithPrev, clearMyPurchaseData } from '../../../store/slices/api/inner/myPurchaseSlice';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { selectLoggedInData } from '../../../store/slices/api/auth/loginSlice';
import { MdCheck } from 'react-icons/md';
import { formatDate } from '../../../lib/formatDate';
import Button from '../../../components/common/Button';
import CancelModal from '../../../components/pages/inner/profile/CancelModal';
import { toggleCancelModal } from '../../../store/slices/common/toggleSlice';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION } from '../../../lib/navigation';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FiLoader } from 'react-icons/fi';

const CancelAction = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedInUser = useSelector(selectLoggedInData);

    return (
        <div>
            {loggedInUser?.subscriptionName ? (
                <div className='flex flex-col md:flex-row gap-3'>
                    <Button onClick={() => navigate(NAVIGATION.INNER_NAVIGATION.SUBSCRIPTION.PLANS)}>Update Plan</Button>
                    <Button onClick={() => dispatch(toggleCancelModal())}>Cancel Subscription</Button>
                </div>
            ) : (
                <Button onClick={() => navigate(NAVIGATION.INNER_NAVIGATION.SUBSCRIPTION.PLANS)}>Buy Subscription</Button>
            )}
        </div>
    );
};

const MyPurchase = () => {
    const loggedInUser = useSelector(selectLoggedInData);
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(null);
    const toggleAccordion = index => setActiveIndex(prevIndex => (prevIndex === index ? null : index));
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const data = useSelector(selectMyPurchaseData);
    const debounceTimeout = useRef(null);

    const { isLoading: fetchLoading, handleSubmit: fetchData } = useApiRequest({
        apiAction: fetchMyPurchase,
        setValueAction: setMyPurchaseDataWithPrev,
        successMessage: false,
        isForm: false,
    });

    const { isLoading: refreshLoading, handleSubmit: fetchRefreshData } = useApiRequest({
        apiAction: fetchMyPurchase,
        setValueAction: setMyPurchaseData,
        successMessage: false,
        isForm: false,
    });

    const handlePagination = async (pagination) => {
        const result = pagination ? await fetchData({ limit, page }) : await fetchRefreshData({ limit, page: 1 });
        if (result?.length < limit) {
            setHasMore(false);
        }
    };

    useEffect(() => {
        handlePagination(1);
    }, [page, limit]);

    useEffect(() => {
        return () => {
            dispatch(clearMyPurchaseData());
        };
    }, [dispatch]);

    const debounce = (func, delay) => {
        return (...args) => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
            debounceTimeout.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const loadMoreData = useCallback(debounce(() => {
        setPage(prevPage => prevPage + 1);
    }, 300), [page]);

    const toggleModal = () => dispatch(toggleCancelModal());

    return (
        <ProfileHOC title="My Purchase" action={<CancelAction />}>
            <div className="relative my-6 pb-10">
                <InfiniteScroll
                    dataLength={data?.length || 0}
                    next={loadMoreData}
                    hasMore={hasMore}
                    loader={(fetchLoading || refreshLoading) ? <div className={`${hasMore ? 'flex' : 'hidden'} items-center justify-center h-10`}>
                        <FiLoader className={`animate-spin text-xl`} />
                    </div> : null}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                    refreshFunction={() => {
                        handlePagination(0);
                    }}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>}
                    releaseToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>}
                >
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                        <thead className="text-xs cursor-grabbing select-none text-gray-700 uppercase bg-gray-50 ">
                            <tr className='bg-gray-100'>
                                <th scope="col" className="px-6 py-3">Subscription plan</th>
                                <th scope="col" className="px-6 py-3">Amount</th>
                                <th scope="col" className="px-6 py-3">Date</th>
                                <th scope="col" className="px-6 py-3">Recipient</th>
                                <th scope="col" className="px-6 py-3">Status</th>
                                <th scope="col" className="px-6 py-3">Payment method</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <React.Fragment key={index}>
                                    <tr className={`${activeIndex === index ? "bg-[#1A1A1B]" : "bg-white"} border-b`}>
                                        <td className={`px-6 py-4 font-medium ${activeIndex === index ? "text-white" : "text-gray-900"} whitespace-nowrap`}>{item?.subscriptionName}</td>
                                        <td className={`px-6 py-4 ${activeIndex === index ? "text-white" : ""}`}>${item?.subscriptionid?.subscriptionPrice}</td>
                                        <td className={`px-6 py-4 ${activeIndex === index ? "text-white" : ""}`}>{formatDate(item?.subscriptionStart)}</td>
                                        <td className={`px-6 py-4 ${activeIndex === index ? "text-white" : ""}`}>
                                            <div className="flex gap-3 items-center">
                                                <div className="h-8 w-8 flex items-center justify-center rounded-full bg-primary text-white font-bold text-lg">
                                                    {loggedInUser?.firstName?.charAt(0).toUpperCase()}
                                                </div>
                                                {loggedInUser?.firstName}
                                            </div>
                                        </td>
                                        <td className={`px-6 py-4 ${activeIndex === index ? "text-white" : ""}`}>
                                            <span className={`${activeIndex === index ? "bg-gray-500 text-white" : item.isActive ? "bg-green-500/10 text-green-500" : "bg-blue-500/10 text-blue-500"} inline-block px-3 py-1 rounded-sm text-xs`}>
                                                {item.isActive ? 'Active' : 'Expired'}
                                            </span>
                                        </td>
                                        <td className={`px-6 py-4 ${activeIndex === index ? "text-white" : ""}`}>card</td>
                                        <td>
                                            <button onClick={() => toggleAccordion(index)} className='h-8 w-8 bg-gray-200 rounded-md flex items-center justify-center'>
                                                {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr
                                        id={`accordion-collapse-body-${index}`}
                                        className={`${activeIndex === index ? '' : 'hidden'}`}
                                        aria-labelledby={`accordion-collapse-heading-${index}`}
                                    >
                                        <td colSpan="7">
                                            <div className="border border-b-0 border-gray-200 p-6 flex w-full justify-between">
                                                <div className='w-4/5'>
                                                    <div className='flex w-full justify-around'>
                                                        <div className="w-1/4">
                                                            <p>Subscription plan</p>
                                                            <h1 className='text-xl font-semibold text-black'>{item?.subscriptionName}</h1>
                                                        </div>
                                                        <div className="w-1/4">
                                                            <p>Billing plan</p>
                                                            <h1 className='text-xl font-semibold text-black'>{item?.subscriptionid?.type}</h1>
                                                        </div>
                                                        <div className="w-1/4">
                                                            <p>Status</p>
                                                            <h1 className='text-black'>{item?.isActive ? 'Active' : 'Expired'}</h1>
                                                        </div>
                                                        <div className="w-1/4">
                                                            <p>Invoice date</p>
                                                            <h1 className='text-black'>{formatDate(item?.subscriptionStart)}</h1>
                                                        </div>
                                                    </div>

                                                    {/* add if uncomment the Id number:- justify-around */}
                                                    <div className='flex w-full mt-4'>
                                                        <div className="w-2/4 p-2">
                                                            <div className='grid grid-cols-2 gap-2'>
                                                                {item?.subscriptionid?.description?.map((desc, idx) => (
                                                                    <p key={idx} className="flex items-center text-gray-600 mb-2">
                                                                        <span className={`mr-2 bg-primary/10 rounded-full p-1 text-primary`}>
                                                                            <MdCheck />
                                                                        </span>
                                                                        {desc}
                                                                    </p>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className="w-1/4">
                                                            <p>Date paid</p>
                                                            <h1 className='text-black'>{formatDate(item?.subscriptionStart)}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-1/5 text-center">
                                                    <p>Amount due</p>
                                                    <h1 className='text-xl font-semibold text-black'>${item?.subscriptionid?.subscriptionPrice}</h1>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
            <CancelModal toggleModal={toggleModal} />
        </ProfileHOC>
    );
};

export default MyPurchase;
