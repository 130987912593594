import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
  data: null,
};

export const contact = createAsyncThunk(
  'user/contact',
  async (formData) => {
    try {
      const response = await myAxios.post(`user/contact`, {
        username: formData?.firstName ? formData?.firstName + " " + formData?.lastName : formData?.username,
        email: formData?.email,
        message: formData?.message,
      });
      return { success: true, message: "Contact request send successfully!", data: response?.data?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong!' };
      }
    }
  },
);

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    clearContactData: state => {
      state.data = null;
    },
    setContactData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const contactReducer = contactSlice.reducer;
export const { clearContactData, setContactData } = contactSlice.actions;

export const selectContactedData = (state) => state.contactRequest.data;
