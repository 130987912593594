import React from 'react'

const InnerFooter = () => {
    return (
        <div className="flex flex-col relative z-10 justify-center px-16 py-6 w-full uppercase border-t border-solid bg-neutral-50 border-zinc-200 leading-[135%] text-zinc-900 max-md:px-5 max-md:max-w-full">
            
            <div className="flex gap-5 justify-between w-full max-w-screen-xl mx-auto max-md:flex-wrap max-md:mr-1">
                <div className="text-sm font-medium tracking-wider">
                    ©2024 Roof Reveal. ALL RIGHTS RESERVED.
                </div>
                <div className="flex flex-col md:flex-row gap-5 justify-between self-start text-xs font-semibold tracking-wide">
                    <div>PRIVACY POLICY</div>
                    <div>TERMS OF USE</div>
                </div>
            </div>
        </div>
    )
}

export default InnerFooter