import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { NAVIGATION } from '../../../lib/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedInData } from '../../../store/slices/api/auth/loginSlice';
import { BsBell, BsQuestionCircle } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import { toggleLogoutModal } from '../../../store/slices/common/toggleSlice';
import { FaBars } from 'react-icons/fa6';

const InnerHeader = () => {

    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const loggedInData = useSelector(selectLoggedInData);
    const { pathname } = useLocation();
    const [dropdown, setDropdown] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => setDropdown(prevState => !prevState);
    const handleNavigate = (path) => {
        navigate(path)
    }

    const toggleModal = () => dispatch(toggleLogoutModal())
    return (
        <nav className="bg-white relative z-[9999] border-gray-200 ">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <div className="flex gap-10 relative">
                    <div onClick={() => handleNavigate(NAVIGATION.INNER_NAVIGATION.DASHBOARD)} className="cursor-pointer flex items-center space-x-3 rtl:space-x-reverse">
                        <img src="/assets/logo.svg" className="h-8" alt="Roof Reveal Logo" />
                    </div>
                    <div className={`items-center justify-between ${open ? "flex w-[93vw] z-10 absolute top-12 left-0" : "hidden"} md:flex md:w-auto md:order-1`} id="navbar-cta">
                        <ul className="flex text-lg flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white w-full md:w-auto">
                            <li>
                                <p onClick={() => handleNavigate(NAVIGATION.INNER_NAVIGATION.DASHBOARD)} className={`cursor-pointer block py-2 px-3 md:p-0 ${pathname === NAVIGATION.INNER_NAVIGATION.DASHBOARD ? 'text-white bg-primary md:text-primary md:bg-transparent' : ' text-gray-900 md:hover:text-primary md:hover:bg-transparent hover:bg-gray-100'} rounded`} aria-current="page">Home</p>
                            </li>
                            <li>
                                <p onClick={() => handleNavigate(NAVIGATION.INNER_NAVIGATION.SUBSCRIPTION.PLANS)} className={`cursor-pointer block py-2 px-3 md:p-0 ${pathname.includes('/inner/subscription') ? 'text-white bg-primary md:text-primary md:bg-transparent' : ' text-gray-900 md:hover:text-primary md:hover:bg-transparent hover:bg-gray-100'} rounded`}>Subscription</p>
                            </li>
                            <li>
                                <p onClick={() => handleNavigate(NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT_LIST)} className={`cursor-pointer block py-2 px-3 md:p-0 ${(pathname === NAVIGATION.INNER_NAVIGATION.CANVAS_REPORT_LIST || pathname === NAVIGATION.INNER_NAVIGATION.EXPLORER_REPORT_LIST || pathname === NAVIGATION.INNER_NAVIGATION.XACTIMATE_REPORT_LIST) ? 'text-white bg-primary md:text-primary md:bg-transparent' : ' text-gray-900 md:hover:text-primary md:hover:bg-transparent hover:bg-gray-100'} rounded`}>Report</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                    <div className="flex gap-5 items-center text-base text-white">
                        {/* <BsSearch className="h-4 w-4 md:h-6 md:w-6 text-zinc-700" /> */}
                        <div onClick={() => handleNavigate(NAVIGATION.INNER_NAVIGATION.HELP_CENTER.MAIN)} className='cursor-pointer'>
                            <BsQuestionCircle className={`h-4 w-4 md:h-6 md:w-6 ${pathname.includes('/inner/help') ? "text-primary" : "text-zinc-700"}`} />
                        </div>
                        <BsBell className="h-4 w-4 md:h-6 md:w-6 text-zinc-700" />

                        <div className="flex gap-4 self-stretch items-center">
                            <div className="flex justify-center items-center text-xs md:text-base w-8 h-8 md:w-10 md:h-10 font-semibold bg-green-600 rounded-[50%]">
                                {loggedInData?.subscriptionCredits ?? '00'}
                            </div>

                            <div className="relative inline-block text-left">
                                <div>
                                    <button type='button' onClick={toggleDropdown} className="flex justify-center items-center text-xs md:text-base w-8 h-8 md:w-10 md:h-10 font-bold bg-primary rounded-[50%]">
                                        {loggedInData?.firstName?.charAt(0).toUpperCase()}
                                    </button>
                                </div>


                                <div className={`${dropdown ? 'block' : 'hidden'} absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                    <div className="py-1" role="none">
                                        <div onClick={() => handleNavigate(NAVIGATION.INNER_NAVIGATION.PROFILE.EDIT_PROFILE)} className={`${pathname.includes('/inner/profile') ? 'cursor-pointer text-white bg-primary md:text-primary md:bg-transparent' : ' text-gray-900 md:hover:text-primary md:hover:bg-transparent hover:bg-gray-100'} flex w-full items-center gap-2 px-4 py-2`} role="menuitem" tabindex="-1" id="menu-item-0"><FaRegUser /> Profile</div>
                                        <button type='button' onClick={toggleModal} className="text-red-600 flex w-full items-center gap-2 px-4 py-2 text-left" role="menuitem" tabindex="-1" id="menu-item-3"><MdOutlineLogout /> Logout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button data-collapse-toggle="navbar-cta" onClick={() => setOpen(!open)} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-cta" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <FaBars />
                    </button>
                </div>

            </div>
        </nav>
    )
}

export default InnerHeader