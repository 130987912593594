import React, { useEffect, useState } from 'react'
import { MdOutlineLocationOn } from 'react-icons/md'
import { useSelector } from 'react-redux';
import { autoComplete, fetchPlaceData, selectAutoCompletedData, setAutoCompleteData, setPlaceData } from '../../../store/slices/api/misc/autoCompleteSlice';
import { useNavigate } from 'react-router-dom';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { FiLoader } from 'react-icons/fi';

const AutoComplete = (
    {
        pathToRedirect
    }
) => {

    const authCompleteData = useSelector(selectAutoCompletedData);
    const [query, setQuery] = useState("")
    const [pendingRequests, _] = useState([])
    const [suggestions, setSuggestions] = useState([])

    const navigate = useNavigate();

    const { isLoading: searchLoading, handleSubmit: handleSearch } = useApiRequest({
        apiAction: autoComplete,
        setValueAction: setAutoCompleteData,
        isForm: false,
        successMessage: false,
    });

    const afterAPICall = (response) => {
        localStorage.setItem("selectedLat", response?.geometry?.location?.lat)
        localStorage.setItem("selectedLng", response?.geometry?.location?.lng)
    }

    const { isLoading, handleSubmit: handleItem } = useApiRequest({
        apiAction: fetchPlaceData,
        setValueAction: setPlaceData,
        isForm: false,
        afterAPICall,
        successMessage: false,
    });

    function fetchSuggestions() {
        try {
            if (pendingRequests?.length > 0) {
                clearTimeout(pendingRequests?.pop())
            }
            const id = setTimeout(async () => {
                await handleSearch({
                    input: query
                });
                setSuggestions(authCompleteData ?? [])
            }, 300)
            pendingRequests?.push(id)
        } catch (e) {
        }
    }

    const onChange = async (e) => {
        const { value } = e?.target;
        setQuery(value);
    }

    const handleItemClick = async (address) => {
        await handleItem({
            placeId: address?.id
        });
        navigate(pathToRedirect, { state: address })
    }

    useEffect(() => {
        fetchSuggestions()
    }, [query])

    return (
        <div className="relative my-10">
            <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <MdOutlineLocationOn />
                </div>
                <input type="text" onChange={onChange} placeholder='Type your address here' className="py-3 bg-gray-50 border-2 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full px-10 p-2.5" />
                <div className="absolute inset-y-0 end-0 flex items-center pe-3.5 pointer-events-none">
                    {searchLoading === true && <FiLoader className={`animate-spin text-zinc-700`} />}
                </div>
            </div>
            {suggestions?.length !== 0 && <ul className="w-full absolute top-12 left-0 bg-white z-10 max-h-48 overflow-y-auto">
                {suggestions?.map((address, idx) => <li key={idx}><button onClick={() => handleItemClick(address)} disabled={isLoading} className={`w-full border text-start border-gray-300 p-2 text-sm rounded-sm ${isLoading && "opacity-50"}`}>
                    <p>{address?.text1}</p>
                    <p className='text-xs text-gray-500'>{address?.text2}</p>
                </button></li>)}
            </ul>}
        </div>
    )
}

export default AutoComplete