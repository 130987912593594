import React, { useState } from 'react'
import DashboardHOC from '../../../components/pages/inner/hoc/DashboardHOC'
import MapTopBar from '../../../components/common/inner/MapTopBar'
import { useDispatch, useSelector } from 'react-redux'
import ExplorerCreditsModal from '../../../components/pages/inner/explorer/ExplorerCreditsModal'
import InfoForm from '../../../components/pages/inner/explorer/InfoForm'
import { NAVIGATION } from '../../../lib/navigation'
import { useNavigate, useParams } from 'react-router-dom'
import { clearMapSeg, selectMapSegData, selectPlaceData } from '../../../store/slices/api/misc/autoCompleteSlice'
import { toggleExplorerModal } from '../../../store/slices/common/toggleSlice'
import { selectLoggedInData, setLoginData } from '../../../store/slices/api/auth/loginSlice'
import useApiRequest from '../../../store/slices/hook/useApiRequest'
import { addReport, setReportData } from '../../../store/slices/api/inner/reportSlice'
import { clearSelectedReportType } from '../../../store/slices/common/selectedReportTypeSlice'
import LowCreditWarning from '../../../components/common/inner/LowCreditWarning'
import Button from '../../../components/common/Button'

const ExplorerInfo = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const toggleModal = async () => await dispatch(toggleExplorerModal())
    const selectedPlace = useSelector(selectPlaceData);
    const loggedInUser = useSelector(selectLoggedInData);
    const selectedReportType = useSelector(state => state?.selectedReportType?.value)
    const navigate = useNavigate();
    const selectedCoordinates = useSelector(state => state?.selectedCoordinates?.value)
    const selectedMapSeg = useSelector(selectMapSegData);

    const [activeIndices, setActiveIndices] = useState(["CustomerContacts", "Address", "ReportSummary"]);
    const toggleAccordion = (index) => {
        if (activeIndices.includes(index)) {
            setActiveIndices(activeIndices.filter(item => item !== index));
        } else {
            setActiveIndices([...activeIndices, index]);
        }
    };

    // const handleGeneratePDF = () => {
    //     navigate(NAVIGATION.INNER_NAVIGATION.EXPLORER_REPORT, {
    //         state: {
    //             items: selectedPlace ? selectedPlace : [],
    //             designLink: `${NAVIGATION.INNER_NAVIGATION.EXPLORER.EXPLORER_MAP}/${params?.reportId}`,
    //             reportLink: NAVIGATION.INNER_NAVIGATION.EXPLORER_REPORT,
    //         }
    //     });
    // };

    const defaultValues = {
        email: loggedInUser?.contactInfo?.email ?? "",
        phone: loggedInUser?.contactInfo?.phone ?? "",
        fullName: loggedInUser?.contactInfo?.fullName ?? "",
        firstName: loggedInUser?.contactInfo?.firstName ?? "",
        lastName: loggedInUser?.contactInfo?.lastName ?? "",
        userName: loggedInUser?.contactInfo?.userName ?? "",
        countryCode: loggedInUser?.contactInfo?.countryCode ?? "",
        address: loggedInUser?.contactInfo?.address ?? "",
        zipCode: loggedInUser?.contactInfo?.zipCode ?? "",
        city: loggedInUser?.contactInfo?.city ?? "",
        company: loggedInUser?.contactInfo?.company ?? "",
        state: loggedInUser?.contactInfo?.state ?? "",
        country: loggedInUser?.contactInfo?.country ?? "",
        latitude: selectedCoordinates?.lat ?? selectedPlace?.geometry?.location?.lat,
        longitude: selectedCoordinates?.lng ?? selectedPlace?.geometry?.location?.lng,
    }

    // handling save button
    const beforeAPICall = (data) => {
        return {
            success: true,
            refactoredData: {
                reportId: params?.reportId,
                email: data?.email ?? "",
                phone: data?.phone ?? "",
                fullName: data?.fullName ?? "",
                firstName: data?.firstName ?? "",
                lastName: data?.lastName ?? "",
                userName: data?.userName ?? "",
                countryCode: data?.countryCode ?? "",
                address: data?.address ?? "",
                zipCode: data?.zipCode ?? "",
                city: data?.city ?? "",
                company: data?.company ?? "",
                state: data?.state ?? "",
                country: data?.country ?? "",
                summary: data?.summary ?? "",
                notes: data?.notes ?? "",
                latitude: selectedCoordinates?.lat ?? selectedPlace?.geometry?.location?.lat,
                longitude: selectedCoordinates?.lng ?? selectedPlace?.geometry?.location?.lng,
                Thumbnail: "",
                roofType: "",
                approxAge: "",
                buildingName: selectedMapSeg?.result?.formatted_address ?? selectedPlace?.name,
                buildingAddress: selectedMapSeg?.result?.formatted_address ?? selectedPlace?.address,
                buildingBounds: selectedMapSeg?.boundingBox,
                coordinates: [
                    {
                        lat: selectedCoordinates?.lat ?? selectedPlace?.geometry?.location?.lat,
                        lng: selectedCoordinates?.lng ?? selectedPlace?.geometry?.location?.lng
                    }
                ],
                reportTypeNumber: 3, //! for explorer report 
            }
        }
    }

    

    const afterAPICall = (response) => {
        dispatch(clearSelectedReportType());
        dispatch(clearMapSeg());

        dispatch(setLoginData({
            ...loggedInUser,
            subscriptionCredits: loggedInUser?.subscriptionCredits - response?.decrementValue,
        }))
        navigate(NAVIGATION.INNER_NAVIGATION.EXPLORER_REPORT_LIST);
    }

    const { control, isLoading, handleSubmit } = useApiRequest({
        apiAction: addReport,
        setValueAction: setReportData,
        beforeAPICall,
        afterAPICall,
        defaultValues,
    });

    return (
        <DashboardHOC>
            <form onSubmit={handleSubmit}>
                <MapTopBar isLoading={isLoading} head="Explorer Report" designLink={`${NAVIGATION.INNER_NAVIGATION.EXPLORER.EXPLORER_MAP}/${params?.reportId}`} reportLink={`${NAVIGATION.INNER_NAVIGATION.EXPLORER_REPORT}/${params?.reportId}`} title="Explorer Report" toggleModal={toggleModal} />
                <div className='w-full max-w-2xl px-5 mb-16 mx-auto mt-20'>
                    {loggedInUser?.subscriptionCredits < selectedReportType?.credits && <LowCreditWarning />}

                    <InfoForm control={control} activeIndices={activeIndices} toggleAccordion={toggleAccordion} />
                    <Button isLoading={isLoading} className="w-full my-6">
                        Submit details
                    </Button>
                </div>
            </form>

            <ExplorerCreditsModal toggleModal={toggleModal} />
        </DashboardHOC>
    )
}

export default ExplorerInfo